import i18next from "i18next";
import dataFetcher from "modules/dataFetcher";
import { CloudAccountSchema, OverlordSchema } from "utils/schemas";

import ModalService from "services/modal";
import api, { nonProjectApi } from "services/api";
import notifications from "services/notifications";

export const CLOUD_ACCOUNTS_MODULE = "cloudAccounts";

export const cloudAccountFormModal = new ModalService("cloudAccountForm");
export const cloudAccountTypeModal = new ModalService("cloudAccountType");

export const cloudAccountFetcher = dataFetcher({
  selectors: [
    "cloudaccounts",
    () => cloudAccountFormModal.data?.cloudType || "",
    () => cloudAccountFormModal.data?.uid || "",
  ],
  schema: CloudAccountSchema,
  async fetchData([_, cloudType, uid]) {
    return await api.get(`v1/cloudaccounts/${cloudType}/${uid}`);
  },
});

function filterOverlordsByCloud(cloudType) {
  return (item) => {
    const { kind, status, spec } = item;
    if (spec?.isSystem && status?.state === "Active") {
      return true;
    }

    if (cloudType === "vsphere" && !kind) {
      return true;
    }

    return kind === cloudType && status?.state === "Running";
  };
}

export const vmWareOverlordsFetcher = dataFetcher({
  selectors: ["vmWare", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await nonProjectApi.get("v1/overlords");
    return response?.items?.filter(filterOverlordsByCloud("vsphere")) || [];
  },
});

export const maasOverlordsFetcher = dataFetcher({
  selectors: ["maas", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await nonProjectApi.get("v1/overlords");
    return response?.items?.filter(filterOverlordsByCloud("maas")) || [];
  },
});

export const openstackOverlordsFetcher = dataFetcher({
  selectors: ["openstack", "overlords"],
  schema: [OverlordSchema],
  async fetchData() {
    const response = await nonProjectApi.get("v1/overlords");
    return response?.items?.filter(filterOverlordsByCloud("openstack")) || [];
  },
});

export const awsPolicesFetcher = dataFetcher({
  selectors: ["aws", "policies"],
  async fetchData(_, { payload, cloudAccountUid }) {
    let policies = [];

    const rootApiEndpoint = "v1/clouds/aws/policies";
    const promise = api.post(
      `${rootApiEndpoint}?${
        cloudAccountUid ? `cloudAccountUid=${cloudAccountUid}` : ""
      }`,
      payload
    );

    try {
      const awsPolices = await promise;
      policies = [...(awsPolices?.policies || [])];
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
    }

    return policies;
  },
});

export const selfHostedOverlordsFetcher = dataFetcher({
  selectors: [
    "self-hosted",
    "overlords",
    () => cloudAccountFormModal?.data?.cloudType,
  ],
  schema: [OverlordSchema],
  async fetchData([_1, _2, cloudType]) {
    const response = await nonProjectApi.get("v1/overlords");
    return (
      response?.items?.filter(
        (overlord) =>
          overlord.kind === cloudType &&
          overlord.spec?.isSelfHosted &&
          overlord.status?.isActive
      ) || []
    );
  },
});
