import * as YAML from "yaml";
import merge from "lodash/merge";
import { v4 as uuid } from "uuid";

import { QUOTAS_FIELDS } from "utils/constants/nested-clusters-config";
import { parseYAMLValues } from "utils/parsers";

function extractComments(doc) {
  const comments = {};
  function commentsFromItems(items = [], prefixKey = "") {
    items.forEach((item, index) => {
      if (!item?.key && !prefixKey) {
        return;
      }

      // needed for an item that has an array as value
      if (!item?.key && prefixKey) {
        commentsFromItems(item?.items || [], `${prefixKey}.${index}`);
        return;
      }

      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value}`
        : item.key.value;

      comments[key] = {
        keyCommentBefore: item.key.commentBefore,
        valueCommentBefore: item.value?.commentBefore,
        comment: item.value?.comment,
        key: item.key.value,
        valueType: item?.value?.type,
      };

      commentsFromItems(item.value?.items || [], key);
    });

    return comments;
  }

  return commentsFromItems(doc?.contents?.items || []);
}

function addComments(doc, comments) {
  function addCommentsToItems(items, prefixKey) {
    items.forEach((item, index) => {
      if (!item?.key && !prefixKey) {
        return;
      }

      // needed for an item that has an array as value
      if (!item?.key && prefixKey) {
        addCommentsToItems(item?.items || [], `${prefixKey}.${index}`);
        return;
      }

      const key = prefixKey
        ? `${prefixKey}.${item?.key?.value}`
        : item.key.value;

      if (item.value?.items) {
        addCommentsToItems(item.value?.items || [], key);
      }

      item.key.commentBefore = comments[key]?.keyCommentBefore;
      item.value.comment = comments[key]?.comment;

      if (item.value) {
        item.value.commentBefore = comments[key]?.valueCommentBefore;
      }

      if (
        comments[key] &&
        item.value?.type &&
        comments[key].valueType &&
        comments[key].valueType !== item.value.type
      ) {
        item.value.type = comments[key].valueType;
      }
    });
  }

  addCommentsToItems(doc.contents.items);
}

// FIXME: this is a hack to prevent the
// yaml library from transforming an object full of null values
// into ? [key]
// this generates an UID that will be removed from the content of the
// toString() function of the parsedYaml document
const uid = uuid();
export function removeNullHax(content) {
  const regex = new RegExp(`\\s+${uid}: ${uid}`, "g");

  return content.replace(regex, "");
}

function fixMultilineString(content) {
  return content.replace(/:(\s+)>\n/g, ":$1|\n").replace(/\n$/, "");
}

function checkForNull(obj) {
  const keys = Object.keys(obj || {});
  if (keys.length === 0) {
    return;
  }

  let allNull = true;

  keys.forEach((key) => {
    const value = obj[key];
    if (value !== null) {
      allNull = false;
    }
    if (value !== null && typeof value === "object") {
      checkForNull(value);
    }
  });

  if (allNull) {
    obj[uid] = uid;
  }
}

export function mergeYaml(base, ...overwrites) {
  if (overwrites.length === 0) {
    return base;
  }

  const baseDoc = YAML.parseDocument(base);
  const parsedBase = baseDoc.toJS();
  let comments = extractComments(baseDoc);

  const mergedObject = overwrites.reduce((accumulator, overwrite) => {
    const overwriteDoc = YAML.parseDocument(overwrite);
    const parsedOverwrite = overwriteDoc.toJS();
    const overwriteDocComments = extractComments(overwriteDoc);

    comments = merge(comments, overwriteDocComments);

    return merge(accumulator, parsedOverwrite);
  }, parsedBase);

  checkForNull(mergedObject);

  const outputDoc = YAML.parseDocument(YAML.stringify(mergedObject));

  addComments(outputDoc, comments);

  const originalToString = outputDoc.toString.bind(outputDoc);
  outputDoc.toString = () => {
    // replace nulls with empty strings not working
    // https://github.com/eemeli/yaml/blob/950cb5b36e56338d623de22abacf90038590c90b/src/schema/common/null.ts#L9
    const contents = originalToString({
      nullStr: "",
      lineWidth: 0,
    }).replace(/\b(~|[Nn]ull|NULL)\b/gm, "");

    const cleanContent = fixMultilineString(removeNullHax(contents));

    // handle overwrites that have multiple documents
    return overwrites.reduce((accumulator, overwrite) => {
      const parts = overwrite.split(`\n---`);
      parts.shift();

      return [accumulator.replace(/\n$/, ""), ...parts].join(`\n---`);
    }, cleanContent);
  };

  outputDoc.commentBefore = baseDoc.comment || baseDoc.commentBefore;
  return outputDoc;
}

function addPackValueToYaml(values, overwrites) {
  const mergedDoc = mergeYaml(values, overwrites.trim());
  const packValuesIndex = mergedDoc.contents.items.findIndex(
    (item) => item.key.value === "pack" || item.key === "pack"
  );

  if (packValuesIndex !== -1 && packValuesIndex !== 0) {
    const packValues = mergedDoc.contents.items[packValuesIndex];
    mergedDoc.contents.items.splice(packValuesIndex, 1);
    mergedDoc.contents.items.splice(0, 0, packValues);
  }

  return mergedDoc;
}

export function extractInstallOrder(
  config = {},
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  if (!config) {
    return undefined;
  }

  if (config?.installOrder !== undefined) {
    return config.installOrder;
  }

  try {
    const yamlDoc =
      YAML.parseDocument(config?.values || "") || new YAML.Document();
    return yamlDoc.getIn(path);
  } catch (e) {
    return null;
  }
}

// TODO: try use parseYAMLValues function
export function updateInstallOrder(
  { values = "", installOrder },
  path = ["pack", "spectrocloud.com/install-priority"]
) {
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();
    const hasInstallOrderValue = !!yamlDoc.getIn(path);

    if (
      hasInstallOrderValue &&
      (!installOrder || typeof installOrder === "undefined")
    ) {
      if (yamlDoc.get("pack")?.items?.length > 1) {
        yamlDoc.deleteIn(path);
      } else {
        if (yamlDoc.contents?.items?.length > 1) {
          yamlDoc.delete("pack");
        } else {
          return "";
        }
      }

      const yamlString = yamlDoc.toString();
      return fixMultilineString(yamlString);
    }

    if (!hasInstallOrderValue && !installOrder) {
      return values;
    }

    if (yamlDoc.getIn(path) !== installOrder) {
      const overwrite = `
       pack:
         spectrocloud.com/install-priority: "${installOrder}"
     `;

      const mergedDoc = addPackValueToYaml(values, overwrite);

      const yamlString = mergedDoc.toString();
      return fixMultilineString(yamlString);
    }

    return values;
  } catch (err) {
    return values;
  }
}

export function extractUbuntuAdvantagePresetOptions(values) {
  const paths = {
    token: `ua attach`,
    cis: `ua enable cis --assume-yes`,
    "esm-infra": `ua enable esm-infra --assume-yes`,
    fips: `ua enable fips --assume-yes`,
    livepatch: `ua enable livepatch --assume-yes`,
    "fips-updates": `ua enable fips-updates --assume-yes`,
    "cc-eal": `ua enable cc-eal --assume-yes`,
  };
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();
    const commands = yamlDoc
      .getIn(["kubeadmconfig", "postKubeadmCommands"])
      .items.map((item) => item.value);

    const presets = Object.keys(paths).reduce(
      (acc, key) => ({
        ...acc,
        [key]: commands.find((command) => command.includes(paths[key])),
      }),
      {}
    );
    if (presets.token) {
      presets.token = presets.token.split("attach")?.[1]?.trim();
    }
    return {
      ...presets,
      token: presets.token || "",
      enabled: !!yamlDoc.getIn(["kubeadmconfig", "postKubeadmCommands"]),
    };
  } catch (err) {
    return {};
  }
}

function getUbuntuAdvantageYAMLContent(formData) {
  const commands = [
    formData.token && `ua attach ${formData.token}`,
    formData["esm-infra"] && `ua enable esm-infra --assume-yes`,
    formData.livepatch && `ua enable livepatch --assume-yes`,
    formData.fips && `ua enable fips --assume-yes`,
    formData["fips-updates"] && `ua enable fips-updates --assume-yes`,
    formData["cc-eal"] && `ua enable cc-eal --assume-yes`,
    formData.cis && `ua enable cis --assume-yes`,
  ].filter(Boolean);

  if (commands.length) {
    return YAML.stringify({
      kubeadmconfig: {
        postKubeadmCommands: commands,
      },
    });
  }

  return "";
}

export function getUbuntuAdvantagePresets({ value, field }, formData) {
  const updatedYaml = getUbuntuAdvantageYAMLContent(formData);

  if (field === "enabled") {
    return {
      group: "ubuntuAdvantage",
      add: value ? updatedYaml : "",
      remove: value ? [] : ["kubeadmconfig"],
    };
  }

  return {
    add: updatedYaml,
    remove: ["kubeadmconfig"],
    name: field,
    group: "ubuntuAdvantage",
  };
}

export function extractManifestType(
  config = {},
  path = ["pack", "spectrocloud.com/manifest-type"]
) {
  if (!config?.values) {
    return undefined;
  }

  try {
    const yamlDoc =
      YAML.parseDocument(config?.values || "") || new YAML.Document();
    return yamlDoc.getIn(path);
  } catch (e) {
    return undefined;
  }
}

// TODO: try use parseYAMLValues function
export function updateManifestTypeValues({
  values = "",
  showType = true,
} = {}) {
  const path = ["pack", "spectrocloud.com/manifest-type"];
  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();

    if (!showType) {
      if (yamlDoc.get("pack")?.items?.length > 1) {
        yamlDoc.deleteIn(path);
      } else {
        if (yamlDoc.contents.items?.length > 1) {
          yamlDoc.delete("pack");
        } else {
          return "";
        }
      }

      const yamlString = yamlDoc.toString();
      return fixMultilineString(yamlString);
    }

    if (yamlDoc.getIn(path) !== "vm") {
      const overwrite = `
      pack:
        spectrocloud.com/manifest-type: "vm"
    `;
      const mergedDoc = addPackValueToYaml(values, overwrite);
      const yamlString = mergedDoc.toString();
      return fixMultilineString(yamlString);
    }
  } catch (e) {
    return values;
  }
}

export function extractPackDisplayName(
  config = {},
  path = ["pack", "spectrocloud.com/display-name"]
) {
  if (config?.displayName !== undefined) {
    return config.displayName;
  }
  const yamlDoc =
    YAML.parseDocument(config?.values || "") || new YAML.Document();

  return yamlDoc.getIn(path);
}

export function extractQuotasFormUpdates({ path, values }) {
  const getKey = (keyPath) => (path ? `${path}.${keyPath}` : keyPath);

  try {
    const yamlDoc = YAML.parseDocument(values) || new YAML.Document();

    return Object.keys(QUOTAS_FIELDS).reduce((acc, key) => {
      QUOTAS_FIELDS[key].forEach((field) => {
        const formKey = getKey(field.value);
        const formValue = yamlDoc.getIn([
          "isolation",
          "resourceQuota",
          "quota",
          field.yamlKey,
        ]);

        if (formValue && parseFloat(formValue) >= 0) {
          Object.assign(acc, {
            [formKey]: parseFloat(formValue),
          });
        }
      });

      return acc;
    }, {});
  } catch (err) {
    return {};
  }
}

export function getQuotasYamlOverrides(values, formData = {}) {
  const commands = Object.keys(QUOTAS_FIELDS).reduce((acc, key) => {
    QUOTAS_FIELDS[key].forEach((field) => {
      const formValue = formData[field.value];

      if (parseFloat(formValue) >= 0) {
        const yamlValue =
          field.suffix === "Gi" ? `${formValue}Gi` : parseFloat(formValue);

        Object.assign(acc, {
          [field.yamlKey]: yamlValue,
        });
      }
    });

    return acc;
  }, {});

  return parseYAMLValues(values, {
    add: YAML.stringify({
      isolation: {
        resourceQuota: {
          quota: commands,
        },
      },
    }),
  });
}
