import querystring from "query-string";

import api from "services/api";
import history from "services/history";
import ListActions from "modules/list/actions";

import { ClusterSchema } from "utils/schemas";
import { getBoolean } from "utils/parsers";

export const NESTED_CLUSTERS_LIST_MODULE = "clusters/nestedClusters";

function mapClusterQuery({
  search,
  sortField,
  sortOrder,
  environments,
  deleted,
} = {}) {
  return {
    filter: {
      clusterName: {
        contains: search || "",
      },
      isDeleted: getBoolean(deleted),
      environments,
    },
    sort: [
      {
        field: sortField || "lastModifiedTimestamp",
        order: sortOrder || "desc",
      },
    ],
  };
}

export const nestedClustersListActions = new ListActions({
  hasPagination: true,
  schema: [ClusterSchema],
  initialQuery: () => {
    const query = history.getQuery();
    return {
      search: query?.search || "",
      deleted: getBoolean(query?.deleted),
      sortField: query?.sortField || "",
      sortOrder: query?.sortOrder || "",
      environments: ["nested"],
      limit: 20,
    };
  },
  fetchData(query) {
    const { offset, limit, continue: continueToken, ...rest } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";
    const payload = mapClusterQuery(rest);
    const queryString = querystring.stringify(rest);
    history.replace(`?${queryString}`);

    return api.post(
      `v1/dashboard/spectroclusters?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );
  },
});
