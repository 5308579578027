import querystring from "query-string";
import { ClusterSchema } from "utils/schemas";

import api from "services/api";
import ModalService from "services/modal";
import history from "services/history";

import ListActions from "modules/list/actions";
import dataFetcher from "modules/dataFetcher";

import { getBoolean } from "utils/parsers";
import { ENVIRONMENTS } from "utils/constants";

export const clusterDependency = new ModalService("clusterDependency");
export const FILTERS_MODULE = "clusterFilters";

function mapClusterQuery({ search, deleted, sortField, sortOrder } = {}) {
  const envs = ENVIRONMENTS.map((env) => env.apiKey).filter(
    (apiKey) => !["eksa", "openshift", "gke"].includes(apiKey)
  );
  return {
    filter: {
      clusterName: {
        contains: search || "",
      },
      isDeleted: getBoolean(deleted),
      environments: [...envs, "generic"],
    },
    sort: [
      {
        field: sortField || "lastModifiedTimestamp",
        order: sortOrder || "desc",
      },
    ],
  };
}

export function refreshClustersList() {
  return (dispatch) => {
    const promise = api.post("v1/dashboard/spectroclusters");
    dispatch({
      type: "REFRESH_CLUSTERS",
      promise,
      schema: {
        items: [ClusterSchema],
      },
    });
  };
}

export const clusterListActions = new ListActions({
  hasPagination: true,
  schema: [ClusterSchema],
  initialQuery: () => {
    const query = history.getQuery();
    return {
      search: query?.search || "",
      deleted: getBoolean(query?.deleted),
      sortField: query?.sortField || "",
      sortOrder: query?.sortOrder || "",
      limit: 20,
    };
  },
  fetchData(query) {
    const { offset, limit, continue: continueToken, ...rest } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";
    const payload = mapClusterQuery(rest);
    const queryString = querystring.stringify(rest);
    history.replace(`?${queryString}`);

    return api.post(
      `v1/dashboard/spectroclusters?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );
  },
});

export const cloudAccountsSummaryFetcher = dataFetcher({
  selectors: ["cloud-accounts-list"],
  async fetchData([_]) {
    return await api.get("v1/cloudaccounts/summary").then((res) => res.items);
  },
});

export function openClusterConfiguration(resourceType) {
  const resourceLinks = {
    clusterProfile: "/profiles/cluster",
  };

  return () => {
    clusterDependency.open().then(() => {
      history.push(resourceLinks[resourceType]);
    });
  };
}
