import api from "services/api";
import { ClusterWorkspaceSchema } from "utils/schemas";
import { getClusterWorkspaceEntity } from "../selectors/details";

import { WorkloadNamespaceSchema } from "utils/schemas";

import moment from "moment";
import { GENERAL_DATE_FORMAT } from "utils/constants";
import { namespacesFetcher } from "../services/workloads/namespaces";

export function fetchClusterWorkspace(uid) {
  return async (dispatch) => {
    //TODO update API
    const promise = api.get(`v1/workspaces/${uid}`).then((res) => ({
      ...res,
      spec: {
        ...(res.spec || {}),
        clusterRefs: (res.spec?.clusterRefs || []).map((ref) => ({
          uid: ref.clusterUid,
          name: ref.clusterName,
        })),
      },
    }));
    dispatch({
      type: "FETCH_CLUSTER_WORKSPACE",
      promise,
      schema: ClusterWorkspaceSchema,
    });

    return promise;
  };
}

export function refreshClusterWorkspace() {
  return async (dispatch, getState) => {
    const workspace = getClusterWorkspaceEntity(getState());
    const promise = api
      .get(`v1/workspaces/${workspace.metadata.uid}`)
      .then((res) => ({
        ...res,
        spec: {
          ...(res.spec || {}),
          clusterRefs: (res.spec?.clusterRefs || []).map((ref) => ({
            uid: ref.clusterUid,
            name: ref.clusterName,
          })),
        },
      }));
    dispatch({
      type: "REFRESH_CLUSTER_WORKSPACE",
      promise,
      schema: ClusterWorkspaceSchema,
    });

    return promise;
  };
}

export function getClusterWorkspaceByUid(uid) {
  return (dispatch, getState) => {
    const currentWorkspace = getClusterWorkspaceEntity(getState());

    if (currentWorkspace?.metadata?.uid === uid) {
      return;
    }
    return dispatch(fetchClusterWorkspace(uid));
  };
}

export function fetchNamespacesConsumption() {
  return async function thunk(dispatch, getState) {
    const uid = getState().location.params?.id;
    const promise = async function loadData() {
      const startTime = moment()
        .subtract(1, "hour")
        .utc()
        .format(GENERAL_DATE_FORMAT);
      const endTime = moment().utc().format(GENERAL_DATE_FORMAT);

      const filters = {
        filter: {
          workspaces: [uid],
          endTime,
          startTime,
          includeMasterMachines: true,
        },
        options: {
          groupBy: "namespace",
          period: 60,
        },
      };
      const rawData = await api.post(
        "v1/dashboard/spectroclusters/resources/consumption",
        filters
      );

      const { result: namespaces } = namespacesFetcher.selector(getState());

      return rawData.resources.map((resource) => {
        const namespace = (namespaces?.items || []).find((ns) => {
          return ns.metadata.entity.name === resource.entity.name;
        });

        return {
          metadata: {
            entity: {
              ...resource.entity,
              uid: namespace?.metadata.entity.uid,
            },
          },
          status: {
            usage: {
              total: resource.total,
            },
          },
        };
      });
    };

    dispatch({
      type: "CLUSTER_WORKSPACE_WORKLOAD_NAMESPACES_CONSUMPTION",
      promise: promise(),
      schema: [WorkloadNamespaceSchema],
    });
  };
}
