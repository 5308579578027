import { createSelector } from "reselect";
import i18n from "i18next";
import { getEntity } from "utils/entities";
import { generatePath, matchPath } from "react-router";
import {
  UserSchema,
  ProjectPermissionsSchema,
  ProjectSchema,
} from "utils/schemas";
import { round } from "utils/number";
import * as PATHS from "utils/constants/routes";
import { FLAGS } from "utils/constants/flags";
import { getClustersLink } from "state/cluster/selectors/list";
import { getClusterProfilesLink } from "state/clusterprofile/selectors/list";
import { getWorkspacesLink } from "state/workspaces/selectors/listing";

import {
  freemiumFetcher,
  cloudCreditFetcher,
  projectsMetadataFetcher,
} from "./actions";

import { faUserLock, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faTools } from "@fortawesome/pro-light-svg-icons";
import ClusterProfileIcon from "assets/icons/clusterprofiles.svg?component";
import ClustersIcon from "assets/icons/clusters.svg?component";
import AuditLogsIcon from "assets/icons/audit_logs.svg?component";
import SettingsIcon from "assets/icons/project_settings.svg?component";
import AdminProjectsIcon from "assets/icons/admin_projects.svg?component";
import OverviewIcon from "assets/icons/overview.svg?component";
import WorkspaceIcon from "assets/icons/workspaces.svg?component";

import permissionsService from "services/permissions";
import flags from "services/flags";
import {
  ADMIN_PREFIX_WITH_PROJECT,
  PROJECT_PREFIX,
} from "components/common/History/Route";

export const ADMIN_GUID = "ADMIN_VIEW";

const TENANT_BLACKLIST = [
  "dashboard",
  "projectoverview",
  "workspaces",
  "tenantsettings",
];
const PROJECT_BLACKLIST = [
  "overview",
  "projects",
  "management",
  "roles",
  "overlords",
];

export const getUserProjects = getEntity(
  (state) => state.auth.projects,
  [ProjectPermissionsSchema]
);

export const getUserProjectsSorted = createSelector(
  projectsMetadataFetcher.selector,
  ({ result }) => {
    return [...(result || [])].sort((a, b) =>
      a?.metadata?.name?.localeCompare(b?.metadata?.name)
    );
  }
);

export const getCurrentUser = getEntity((state) => state.auth.me, UserSchema);

export const getUserContexts = createSelector(
  (state) => state.auth.permissions,
  getUserProjects,
  (permissions, projects) => {
    let updatedProjects = [...projects];
    if (permissions.length) {
      updatedProjects = [
        { guid: ADMIN_GUID, projectUid: ADMIN_GUID },
        ...updatedProjects,
      ];
    }

    return updatedProjects;
  }
);

export const getCurrentProject = getEntity((state) => {
  return state.auth.currentProjectId;
}, ProjectPermissionsSchema);

export const getCurrentProjectUidFromUrl = createSelector(
  (state) => state.router.location.pathname,
  (pathname) => {
    let match = null;

    match = matchPath(pathname, {
      path: `${PROJECT_PREFIX}/*`,
      exact: true,
    });

    if (match) {
      return match.params.projectUid;
    }

    match = matchPath(pathname, {
      path: `${ADMIN_PREFIX_WITH_PROJECT}/*`,
      exact: true,
    });

    if (match) {
      return match.params.projectUid;
    }

    return null;
  }
);

export const getBackToProject = getEntity(
  (state) =>
    state.auth.projects.includes(state.auth.backToProjectUid)
      ? state.auth.backToProjectUid
      : null,
  ProjectPermissionsSchema
);

export const getCurrentContext = createSelector(
  getCurrentProject,
  (state) => state.auth.currentProjectId,
  (currentProject, currentProjectId) => {
    if (currentProjectId === ADMIN_GUID) {
      return { guid: ADMIN_GUID, isAdmin: true };
    }

    if (currentProject) {
      return { ...currentProject, isAdmin: false };
    }

    return currentProject;
  }
);

export const getAllPermissions = createSelector(
  (state) => state.auth.permissions,
  getCurrentContext,
  (permissions, currentContext) => {
    let contextPermissions = currentContext?.permissions || [];
    if (currentContext?.isAdmin) {
      contextPermissions = permissions;
    }
    if (permissions.length) {
      return [...contextPermissions, "ADMIN"];
    }

    return contextPermissions;
  }
);

export const getAllProjects = getEntity(
  (state) => state.auth.allProjects,
  [ProjectSchema]
);

export const getProjectsDropdownData = createSelector(
  getAllProjects,
  (projects) => {
    const items = projects?.map((project) => ({
      label: project.metadata.name,
      value: project.metadata.uid,
    }));

    return [{ label: "All", value: "all" }, ...items];
  }
);

// TODO: add permissions for audit
export const getUserMenu = createSelector(
  getCurrentContext,
  () => permissionsService.perms,
  getClustersLink,
  getClusterProfilesLink,
  getWorkspacesLink,
  getCurrentUser,
  (
    currentContext,
    permissions,
    clustersLink,
    clusterProfilesLink,
    workspacesLink,
    user
  ) => {
    const menuItems = [
      {
        key: "overview",
        path: PATHS.ORGANIZATION_OVERVIEW.ROOT,
        component: OverviewIcon,
        label: () => i18n.t("Overview"),
        permissions: [
          "project.get",
          "project.list",
          "project.edit_info",
          "project.delete",
          "project.roles.get",
          "project.roles.list",
          "project.roles.create",
        ],
        flags: [],
      },
      {
        key: "projectoverview",
        path: PATHS.PROJECT_OVERVIEW.ROOT,
        permissions: ["cluster.list", "cluster.get"],
        component: OverviewIcon,
        flags: [],
        label: () => i18n.t("Project Overview"),
      },
      {
        key: "projects",
        path: PATHS.PROJECTS.ROOT,
        permissions: [
          "project.get",
          "project.list",
          "project.edit_info",
          "project.delete",
          "project.roles.get",
          "project.roles.list",
          "project.roles.create",
        ],
        component: AdminProjectsIcon,
        flags: [],
        label: () => i18n.t("Projects"),
      },
      {
        key: "profiles",
        path: clusterProfilesLink,
        permissions: [
          "clusterProfile.create",
          "clusterProfile.edit",
          "clusterProfile.list",
          "clusterProfile.get",
          "clusterProfile.publish",
        ],
        component: ClusterProfileIcon,
        flags: [],
        label: () => i18n.t("Profiles"),
      },
      {
        key: "clusters",
        path: clustersLink,
        permissions: ["cluster.create", "cluster.list", "cluster.get"],
        component: ClustersIcon,
        flags: [],
        label: () => i18n.t("Clusters"),
      },
      {
        key: "workspaces",
        path: workspacesLink,
        permissions: ["workspace.list", "workspace.get"],
        component: WorkspaceIcon,
        flags: [FLAGS.WORKSPACES],
        label: () => i18n.t("Workspaces"),
      },
      {
        key: "roles",
        path: PATHS.ROLES.ROOT,
        permissions: ["role.list"],
        awesome: faUserLock,
        flags: [FLAGS.BETA],
        label: () => i18n.t("Roles"),
      },
      {
        key: "management",
        path: generatePath(PATHS.MANAGEMENT.ROOT, { tab: "users" }),
        permissions: ["user.list"],
        awesome: faUserFriends,
        flags: [FLAGS.BETA],
        label: () => i18n.t("Users & Teams"),
      },
      {
        key: "auditlogs",
        path: PATHS.AUDIT.ROOT,
        permissions: ["audit.get", "audit.list"],
        component: AuditLogsIcon,
        flags: [FLAGS.AUDIT],
        label: () => i18n.t("Audit Logs"),
      },
      {
        key: "settings",
        path: PATHS.SETTINGS.CLOUD_ACCOUNTS,
        permissions: ["cloudaccount.list"],
        component: SettingsIcon,
        theme: "filled",
        flags: [],
        label: () =>
          currentContext?.isAdmin
            ? i18n.t("Tenant Settings")
            : i18n.t("Project Settings"),
      },
      {
        key: "tenantsettings",
        path: `/admin/settings/cloudaccounts`,
        permissions: ["ADMIN"],
        awesome: faTools,
        placement: "bottom",
        flags: [],
        label: () => i18n.t("Tenant Settings"),
      },
    ];

    return menuItems.filter((item) => {
      const projectBlacklisted =
        !currentContext?.isAdmin && PROJECT_BLACKLIST.includes(item.key);

      if (
        item.key === "clusters" &&
        user?.spec?.emailId === "saad+a1@spectrocloud.com"
      ) {
        return false;
      }

      if (projectBlacklisted) {
        return false;
      }

      const tenantBlacklisted =
        currentContext?.isAdmin && TENANT_BLACKLIST.includes(item.key);

      if (tenantBlacklisted) {
        return false;
      }

      if (!permissionsService.has(item.permissions)) {
        return false;
      }

      return true;
    });
  }
);

export const getTenantPlanType = createSelector(
  (state) => state.auth?.plan,
  (plan) => {
    return plan?.spec?.type;
  }
);

export const getTenantPlanName = createSelector(
  (state) => state.auth.plan,
  (plan) => {
    const planTypes = {
      Trial: () => i18n.t("Trial"),
      MonthlyOnDemand: () => i18n.t("Monthly on demand"),
      AnnualSubscription: () => i18n.t("Annual subscription"),
    };

    return plan?.spec?.type ? planTypes[plan?.spec?.type] : "";
  }
);

export const getCurrentTenantUid = createSelector(
  getCurrentUser,
  (currentUser) => {
    return currentUser?.metadata?.annotations?.tenantUid || "";
  }
);

export const canUpgradePlan = createSelector(
  (state) => state.auth.plan,
  (plan) => {
    return permissionsService.isAdmin && plan?.spec?.type === "Trial";
  }
);

export const getFreemiumUsage = createSelector(
  freemiumFetcher.selector,
  ({ result }) => {
    if (!result || !result.isFreemium || result.isUnlimited) {
      return null;
    }

    const customerUsage = round(result.usage?.usage, 2) || 0;
    const usageLimit = result?.limit.usage;
    const usagePercent = (customerUsage / usageLimit) * 100;
    return {
      isFreemium: result.isFreemium,
      customerUsage,
      usageLimit,
      usagePercent,
    };
  }
);

export const getCloudCreditUsage = createSelector(
  cloudCreditFetcher.selector,
  ({ result }) => {
    if (!result) {
      return null;
    }
    const cloudCreditUsed = round(result.creditUsedInDollars, 2);
    const cloudCreditLimit = result.creditLimitInDollars;
    const cloudCreditPercent = (cloudCreditUsed / cloudCreditLimit) * 100;
    return {
      cloudCreditUsed,
      cloudCreditLimit,
      cloudCreditPercent,
    };
  }
);

export const getApplicationEnv = (state) =>
  state.auth?.currentOrganization?.appEnv;
export const isOnPremApplication = createSelector(getApplicationEnv, (appEnv) =>
  ["quick-start", "enterprise", "airgap"].includes(appEnv)
);

export const restrictOnPremPublicClouds = createSelector(
  isOnPremApplication,
  () => flags.has(FLAGS.ENABLE_ONPREM_PUBLIC_CLOUDS),
  (isOnPrem, hasFlag) => isOnPrem && !hasFlag
);
