import Color from "color";
import moment from "moment";
import i18n from "i18next";
import {
  LAYERS,
  OCI_CHART_LAYER,
  SPECTRO_FOLDER_PREFIX,
  SPECTRO_TAG,
  BEGINNING_OF_TIME,
  WORKLOADS_TIME_PERIODS,
  VM_MANIFEST_LAYER,
} from "./constants";
import {
  formatToKilocoreHours,
  parseMicroCoresToCores,
  parseKiBToGB,
} from "./number";
import _ from "lodash";
import { white, lightMidGray, COST_CHART_COLORS } from "utils/constants/colors";
import { updateInstallOrder, extractManifestType } from "utils/yaml";
import {
  LAYERS_PRECEDENCE,
  LAYER_DEFAULT_PRECEDENCE,
} from "utils/constants/clusterprofile";

export function presentClusterProfileParams(packs = []) {
  if (!packs?.length) {
    return [];
  }

  return packs.map((packVersion) => {
    return {
      ...packVersion,
      ...presentLayer({
        ...packVersion,
        type:
          packVersion?.spec?.addonType ||
          packVersion?.spec?.layer ||
          packVersion.name,
      }),
    };
  });
}

export function presentLayersForAPI(pack) {
  return {
    ...pack,
    values: updateInstallOrder(pack),
    ...(pack?.type === "manifest" && {
      uid: pack?.uid || "spectro-manifest-pack",
    }),
  };
}

export function presentUniquePackName(pack) {
  const { displayName, name } = pack || {};
  if (displayName && !name?.includes(`${displayName}--`)) {
    return `${displayName}--${name}`;
  }
  return name || "";
}

export function getPackNameComponents(name) {
  if (name?.includes("--")) {
    return name.split("--");
  }
  return [];
}

export function presentLayer(pack) {
  let metadata = [...LAYERS, OCI_CHART_LAYER].find(
    (layer) => layer.type === pack.type
  ) || {
    title: () => null,
    color: lightMidGray,
  };

  if (pack.type === "manifest") {
    const isVmManifest = extractManifestType(pack) === "vm";

    if (isVmManifest) {
      metadata = VM_MANIFEST_LAYER;
    }
  }

  const solidColor = metadata.color;

  const shade = Color(white)
    .mix(Color(solidColor).mix(Color(white), 0.3), 0.25)
    .hex();

  const disabledColor = Color(shade)
    .mix(Color(white), 0.78)
    .mix(Color(lightMidGray), 0.68)
    .hex();

  const disabledInner = Color(disabledColor).mix(Color(solidColor), 0.1).hex();

  const colors = {
    inner: solidColor,
    shade: solidColor,
    outline: Color(solidColor).mix(Color(shade), 0.9).hex(),
  };

  function getDescription() {
    if (!pack.name) {
      return null;
    }

    if (pack.type === "manifest") {
      return pack.name;
    }

    return pack.name && (pack.tag || pack.version)
      ? `${pack.name} ${pack.tag || pack.version}`
      : "";
  }

  return {
    ...metadata,
    ...colors,
    ...pack,
    description: getDescription(),
    packName: presentUniquePackName(pack),
    disabledColor,
    disabledInner,
    title: metadata.title(),
    logo:
      pack?.logo ||
      pack?.spec?.logoUrl ||
      pack?.pack?.spec?.logoUrl ||
      metadata.icon ||
      "",
  };
}

export function presentClusterProfileLayers(clusterprofile) {
  if (!clusterprofile) {
    return [];
  }

  const packs = clusterprofile?.spec?.published?.packs || [];
  return packs.map((packVersion) => {
    return presentLayer({
      ...packVersion,
      type:
        packVersion?.spec?.addonType ||
        packVersion?.spec?.layer ||
        packVersion.name,
    });
  });
}

export function formatTags(tags = []) {
  return tags.reduce((acc, item) => {
    if (item.includes(":")) {
      const [key, value] = item.split(":");

      acc[key] = (value || "").trim();

      return acc;
    }
    acc[item] = SPECTRO_TAG;
    return acc;
  }, {});
}

export function getFullName(user) {
  if (user?.metadata?.name) {
    return user.metadata?.name;
  }

  const { firstName = "", lastName = "", emailId = "" } = user?.spec || {};

  if (!(firstName || lastName)) {
    return emailId?.split("@")?.[0] || "";
  }

  return `${firstName} ${lastName}`;
}

export function getFullNameAndEmail(user) {
  const { firstName = "", lastName = "", emailId = "" } = user?.spec || {};
  let fullName = `${firstName} ${lastName}`;

  if (user?.metadata?.name) {
    fullName = user.metadata?.name;
  }

  return `${fullName} (${emailId})`;
}

export function getContractAcceptance(user) {
  return user?.status?.isContractAccepted;
}

// could use some refactoring
export function presentProjectCoresBarChart({ data, projects, filter, query }) {
  const threshold = 5;
  let barData;
  let displayedProjects = [];
  let otherProjects = [];
  let keys = [];
  const orgProjects = projects.map(({ project }) => ({
    name: project.metadata.name,
    uid: project.metadata.uid,
  }));
  const timestampFormats = {
    hour: "HH:mm",
    day: "DD MMM",
  };

  const isSameTimestamp =
    (date, unit) =>
    ({ timestamp }) =>
      moment(timestamp).utc().isSame(date, unit);

  const getBarChartData = (xValues, data, unit) => {
    const summedProjectCores = data.reduce(
      (acc, dataPoint) => {
        let currentAcc = { ...acc };

        dataPoint.projects.forEach(
          ({ project, pureCpuCoreHours = 0, alloyCpuCoreHours = 0 } = {}) => {
            const currentProjectCoresSum = alloyCpuCoreHours + pureCpuCoreHours;

            currentAcc[project.name] += currentProjectCoresSum;
          }
        );

        return currentAcc;
      },
      orgProjects.reduce(
        (acc, project) => ({
          ...acc,
          [project.name]: 0,
        }),
        {}
      )
    );

    const sortedProjects = Object.keys(summedProjectCores).sort(
      (proj1, proj2) => summedProjectCores[proj2] - summedProjectCores[proj1]
    );

    displayedProjects = sortedProjects.slice(0, threshold);
    otherProjects = sortedProjects.slice(threshold, sortedProjects.length);

    return xValues.map((date) => {
      const projectsValues = orgProjects.reduce((acc, currentProject) => {
        const projectData = data
          ?.find(isSameTimestamp(date, unit))
          ?.projects.find(
            ({ project }) => project.name === currentProject.name
          );

        const alloyHours = projectData?.alloyCpuCoreHours || 0;
        const pureHours = projectData?.pureCpuCoreHours || 0;

        if (displayedProjects.includes(currentProject.name)) {
          return {
            ...acc,
            [currentProject.name]: {
              totalCoreHours: alloyHours + pureHours,
              alloyHours,
              pureHours,
            },
          };
        } else {
          return {
            ...acc,
            others: {
              totalCoreHours:
                (acc?.others?.totalCoreHours || 0) + alloyHours + pureHours,
              alloyHours: (acc?.others?.alloyHours || 0) + alloyHours,
              pureHours: (acc?.others?.pureHours || 0) + pureHours,
            },
          };
        }
      }, {});

      return {
        timestamp: moment(date).format(timestampFormats[unit]),
        ...projectsValues,
      };
    });
  };

  if (!filter?.value) {
    return {
      data: [],
      keys,
      indexBy: "timestamp",
    };
  }

  if (filter.unit === "hours") {
    const xValues = Array.from({ length: 24 }).map((_, index) =>
      moment()
        .subtract(24 - index - 1, "hours")
        .startOf("hour")
    );

    barData = getBarChartData(xValues, data?.hourlyUsages, "hour");
  }

  if (filter.unit === "days") {
    const length = parseInt(filter.value);

    const xValues = Array.from({ length }).map((_, index) =>
      moment(query.endTime)
        .subtract(length - (index + 1), filter.unit)
        .startOf("hour")
    );

    const dailyUsages =
      data.items?.map((item) => item.dailyUsages).flat() || [];

    barData = getBarChartData(xValues, dailyUsages, "day");
  }

  keys = orgProjects.filter((project) =>
    displayedProjects.includes(project.name)
  );

  if (threshold < orgProjects.length) {
    keys.push({
      name: `others (${otherProjects.length} ${
        otherProjects.length > 1 ? "projects" : "project"
      })`,
      id: "others",
      extendedDetails: otherProjects,
    });
  }

  return {
    data: barData,
    keys,
    indexBy: "timestamp",
  };
}

function presentLineChartData(data, filter, query, clusterType) {
  const isCurrentMonth = moment(query.startTime).isSame(moment(), "month");

  if (data.length === 0) {
    return Array.from({ length: filter.value }).map((_, index) => {
      const currentXValue = moment(query.startTime).add(index, "days");

      return {
        x: currentXValue.format("YYYY-MM-DD"),
        y: null,
      };
    });
  }

  return Array.from({ length: filter.value }).reduce((acc, _, index) => {
    const currentXValue = moment(query.startTime).add(index, "days");

    const isPastCurrentDay = currentXValue.date() > moment().date();

    let currentYValue = 0;

    if (isCurrentMonth && isPastCurrentDay) {
      return [
        ...acc,
        {
          x: currentXValue.format("YYYY-MM-DD"),
          y: null,
        },
      ];
    }

    const dayUsage = data.find((usage) =>
      moment(usage.timestamp).isSame(currentXValue, "day")
    );

    if (dayUsage) {
      currentYValue =
        (acc[index - 1]?.y || 0) +
        dayUsage[
          clusterType === "alloy"
            ? "totalAlloyCpuCoreHours"
            : "totalPureCpuCoreHours"
        ];
    } else {
      currentYValue = acc[index - 1]?.y || 0;
    }

    return [
      ...acc,
      {
        x: currentXValue.format("YYYY-MM-DD"),
        y: currentYValue,
      },
    ];
  }, []);
}

// TODO: might refactor this a bit
export function presentMonthlyProjectCoreHoursMetrics({ data, filter, query }) {
  if (!data?.items) {
    return [];
  }

  const dailyUsages = data?.items?.[0]?.dailyUsages || [];

  return [
    {
      id: i18n.t("Imported kilocore-hours"),
      data: presentLineChartData(dailyUsages, filter, query, "alloy"),
    },
    {
      id: i18n.t("Palette-Provisioned kilocore-hours"),
      data: presentLineChartData(dailyUsages, filter, query),
    },
  ];
}

export function presentProjectsCreditsUsedMetrics(data) {
  const threshold = 5;

  if ((data?.items || []).length === 0) {
    return {
      chartData: { projects: [] },
    };
  }

  const { totalMonthlyUsage } = data.items[0];

  const filteredProjects = totalMonthlyUsage.projects.filter(
    ({ alloyCpuCoreHours, pureCpuCoreHours }) =>
      alloyCpuCoreHours + pureCpuCoreHours > 0
  );

  const sortedProjects = filteredProjects.sort((proj1, proj2) => {
    const totalUsageProj1 =
      (proj1?.alloyCpuCoreHours || 0) + (proj1?.pureCpuCoreHours || 0);
    const totalUsageProj2 =
      (proj2?.alloyCpuCoreHours || 0) + (proj2?.pureCpuCoreHours || 0);

    return totalUsageProj2 - totalUsageProj1;
  });

  const displayedProjects = sortedProjects.slice(0, threshold);
  const otherProjects = sortedProjects.slice(threshold, sortedProjects.length);

  const otherProjectsUsages = otherProjects.reduce(
    (acc, { alloyCpuCoreHours, pureCpuCoreHours }) => ({
      project: {
        name: `others (${otherProjects.length} ${
          otherProjects.length > 1 ? "projects" : "project"
        })`,
      },
      alloyCpuCoreHours: (acc?.alloyCpuCoreHours || 0) + alloyCpuCoreHours,
      pureCpuCoreHours: (acc?.pureCpuCoreHours || 0) + pureCpuCoreHours,
    }),
    {}
  );

  const chartData =
    filteredProjects.length > threshold
      ? [...displayedProjects, otherProjectsUsages]
      : displayedProjects;

  const parsedProjectsData = {
    projects: chartData.map(
      ({ project, alloyCpuCoreHours, pureCpuCoreHours }, index) => {
        // label is also used to determine who's parent is this project
        return {
          label: project.name || i18n.t("[deleted project]"),
          usage: formatToKilocoreHours(alloyCpuCoreHours + pureCpuCoreHours),
          color: COST_CHART_COLORS[index],
          index,
        };
      }
    ),
    projectsChildren: chartData
      .map(({ project, alloyCpuCoreHours, pureCpuCoreHours }, index) => {
        const color = COST_CHART_COLORS[index];

        return [
          {
            parent: project.name || i18n.t("[deleted project]"),
            type: "pure",
            label: i18n.t("PALETTE-PROVISIONED"),
            usage: formatToKilocoreHours(pureCpuCoreHours),
            color: Color(color).alpha(0.6).rgb().string(),
            index: index * 2,
          },
          {
            parent: project.name || i18n.t("[deleted project]"),
            type: "alloy",
            label: i18n.t("IMPORTED"),
            usage: formatToKilocoreHours(alloyCpuCoreHours),
            color: Color(color).alpha(0.3).rgb().string(),
            index: index * 2 + 1,
          },
        ];
      })
      .flat(),
  };

  return {
    chartData: parsedProjectsData,
    totalAlloyCpuCoreHours: formatToKilocoreHours(
      totalMonthlyUsage.totalAlloyCpuCoreHours
    ),
    totalPureCpuCoreHours: formatToKilocoreHours(
      totalMonthlyUsage.totalPureCpuCoreHours
    ),
    nrOfProjects: filteredProjects?.length || 0,
  };
}

export function groupPresetsOptions(presets = []) {
  const result = presets.reduce((acc, item) => {
    acc[item.group] = acc[item.group] || [];
    acc[item.group].push({
      ...item,
      label: item.displayName,
      value: item.name,
    });
    return acc;
  }, {});

  return result;
}

function extractSchemaField(field, path) {
  const accumulator = {
    type: field.type,
    description: field.hints.join(". "),
    readonly: field.readonly,
  };

  if (field?.listOptions?.length) {
    return {
      ...accumulator,
      enum: field.listOptions,
    };
  }

  let regex = field.regex;
  if (field.type === "boolean") {
    regex = null;
  }

  if (field.type === "string" && field.required) {
    accumulator.minLength = 1;
  }

  // eslint-disable-next-line no-template-curly-in-string
  if (field.format.startsWith("${password}")) {
    accumulator.passwordRegex = regex;
    regex = null;
    accumulator.passwordTypeField = true;
  }

  return {
    ...accumulator,
    ...(regex ? { pattern: regex } : {}),
    "~schemaField": true,
    path,
  };
}

export function generateEditorYamlSchema(
  schema = [],
  { ignorePasswords = true } = {}
) {
  if (!schema.length) {
    return null;
  }

  return schema.reduce(
    (accumulator, item) => {
      let delimiter = ".";
      let name = item.name;

      if (name.includes("spectrocloud.com")) {
        delimiter = ".property.";
      }

      if (name.includes("\\.")) {
        name = name.replace(/\\./g, "~");
      }

      const path = name
        .split(delimiter)
        .reduce((nameAccumulator, name) => {
          if (name === "$[]") {
            return [...nameAccumulator, "items"];
          }
          const separator = "properties";
          return [...nameAccumulator, separator, name];
        }, [])
        .map((subPath) => subPath.replaceAll("~", "\\\\."));

      const joinedPath = path.join(".");
      const fieldMeta = extractSchemaField(item, joinedPath);

      if (fieldMeta.passwordTypeField) {
        accumulator.passwordFields[joinedPath] = fieldMeta;
        if (!ignorePasswords) {
          fieldMeta.regex = fieldMeta.passwordRegex;
        }
      }
      _.set(accumulator, path, fieldMeta);

      if (item.required) {
        const parts = [...path];
        const fieldName = parts.pop();
        parts.pop();
        const descriptor = _.get(accumulator, parts.join(delimiter));

        if (!descriptor) {
          return accumulator;
        }
        descriptor.required = descriptor.required || [];
        descriptor.required.push(fieldName);
      }

      return accumulator;
    },
    {
      $id: "http://json-schema.org/draft/2019-09/schema#",
      $schema: "http://json-schema.org/draft/2019-09/schema#",
      type: "object",
      properties: {},
      passwordFields: {},
    }
  );
}

export function presentDatacenterFolders(datacenter) {
  return (
    datacenter?.folders
      ?.filter((folder) => {
        const paths = folder.split("/");

        return paths.every((path) => !path.startsWith(SPECTRO_FOLDER_PREFIX));
      })
      .map((folder) => ({
        label: folder,
        value: folder,
      })) || []
  );
}

const units = {
  week: 24 * 60 * 7,
  day: 24 * 60,
  hour: 60,
};

export function presentWorkloadAge(creationTimestamp) {
  if (moment(creationTimestamp).isSame(moment(BEGINNING_OF_TIME))) {
    return "-";
  }

  const minutesToNow = moment().diff(moment(creationTimestamp), "minutes");

  if (minutesToNow < 1) {
    return "<1m";
  }

  if (minutesToNow >= units.week) {
    const w = Math.floor(minutesToNow / units.week);
    const d = Math.floor((minutesToNow - w * units.week) / units.day);
    return d > 0 ? `${w}w ${d}d` : `${w}w`;
  }

  if (minutesToNow >= units.day) {
    const d = Math.floor(minutesToNow / units.day);
    const h = Math.floor((minutesToNow - d * units.day) / units.hour);
    return h > 0 ? `${d}d ${h}h` : `${d}d`;
  }

  if (minutesToNow >= units.hour) {
    const h = Math.floor(minutesToNow / units.hour);
    const min = Math.floor(minutesToNow % units.hour);
    return min > 0 ? `${h}h ${min}m` : `${h}h`;
  }

  return `${Math.floor(minutesToNow)}m`;
}

export function presentWorkloadChartData({ data, timeRange }) {
  const unitType = timeRange.split(" ")?.[1];
  const period = WORKLOADS_TIME_PERIODS[timeRange].period;

  const remainder = moment().minute() % period;

  const xValues = Array.from({
    length: WORKLOADS_TIME_PERIODS[timeRange].length,
  }).map((_, index) => {
    if (unitType === "hours") {
      return moment()
        .subtract(remainder, "minutes")
        .subtract(index * period, "minutes");
    }

    return moment().startOf("day").subtract(index, "days");
  });

  return xValues
    .map((xPeriod) => {
      const periodUsage = (data.resources?.[0]?.data || []).find((cdata) => {
        const timestamp = cdata.timestamp / 1000000;
        if (unitType === "hours") {
          return moment(timestamp).format("HH:mm") === xPeriod.format("HH:mm");
        }
        return moment(timestamp).isSame(xPeriod, "day");
      });

      return {
        date: xPeriod.format(unitType === "hours" ? "HH:mm" : "DD MMM"),
        cpu: periodUsage ? parseMicroCoresToCores(periodUsage.cpu) : 0,
        memory: periodUsage ? parseKiBToGB(periodUsage.memory) : 0,
      };
    })
    .reverse();
}

export function getLayerPrecedence(layer) {
  return LAYERS_PRECEDENCE?.[layer?.type] || LAYER_DEFAULT_PRECEDENCE;
}
