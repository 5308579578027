import { v4 as uuid } from "uuid";
import store from "services/store";
import createProfileFormActions from "./createProfileForm";
import importedPacksActions from "./importedPacks";
import manifestsActions from "./manifests";
import editorActions from "./editor";
import {
  FORM_TYPES,
  helmRegistryFetcher,
  MODULES,
  configValidator,
} from "../services";
import { BASE_LAYERS } from "utils/constants/clusterprofile";
import {
  extractPayload,
  mapFormDataToLayers,
  generateUbuntuAdvantagePresets,
} from "../utils";
import {
  getPackValuesWithoutPresetsComment,
  getDefaultPresetsFromValues,
  getPreviousPresetDelta,
  parseYAMLValues,
} from "utils/parsers";
import {
  extractInstallOrder,
  getUbuntuAdvantagePresets,
  extractPackDisplayName,
} from "utils/yaml";
import fetchers, { packListActions } from "../services";
import api from "services/api";
import notifications from "services/notifications";
import i18next from "i18next";
import { getAvailableRepositories } from "../selectors";
import { getRawClusterProfile } from "state/clusterprofile/selectors/details";
import { macrosSuggestionsFetcher } from "state/macros/actions";

export default class ProfileBuilderActions {
  constructor({
    guid,
    revertPackValuesConfirm,
    removeLayerConfirm,
    isLayerFormInitialState,
    cancelLayerChanges,
    addNewRepositoryModal,
    areCoreLayersConfigured,
    getState,
  }) {
    this.guid = guid;
    this.revertPackValuesConfirm = revertPackValuesConfirm;
    this.removeLayerConfirm = removeLayerConfirm;
    this.cancelLayerChanges = cancelLayerChanges;
    this.addNewRepositoryModal = addNewRepositoryModal;
    this.isLayerFormInitialState = isLayerFormInitialState;
    this.areCoreLayersConfigured = areCoreLayersConfigured;
    this.getState = getState;

    this.forms = createProfileFormActions(guid, {
      toggleNewManifestEditor: this.toggleNewManifestEditor,
      togglePackValues: this.togglePackValues,
      getFormUpdatesOnPresetsChange: this.getFormUpdatesOnPresetsChange,
      setNewHelmRegistry: this.setNewHelmRegistry,
    });

    this.importedPacks = importedPacksActions(guid, {
      forms: this.forms,
      addNewRepositoryModal: this.addNewRepositoryModal,
    });

    this.packManifests = manifestsActions(guid, {
      forms: this.forms,
      toggleNewManifestEditor: this.toggleNewManifestEditor,
      onEditorSelectLayer: this.onEditorSelectLayer,
      validateLayer: this.validateLayer,
    });

    this.editor = editorActions(guid, {
      forms: this.forms,
      packManifests: this.packManifests,
      getFormUpdatesOnPresetsChange: this.getFormUpdatesOnPresetsChange,
      revertPackValuesConfirm: this.revertPackValuesConfirm,
      onEditorSelectLayer: this.onEditorSelectLayer,
      setCurrentEditorName: this.setCurrentEditorName,
    });
  }

  get state() {
    return this.getState();
  }

  dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid: this.guid,
    });
  };

  initialize = ({
    layers = [],
    isEditMode = false,
    isClusterProfileUsed = false,
    cloudType = "",
  } = {}) => {
    this.dispatch({
      type: "PROFILE_BUILDER_INIT",
      isEditMode,
      layers: layers.map((layer) => ({
        ...layer,
        isDraft: false,
        formType: layer.formType || FORM_TYPES.PACK,
      })),
      isClusterProfileUsed,
      cloudType,
    });

    this.dispatch({
      type: "PROFILE_BUILDER_SET_BASE_IS_CONFIGURED",
      isProfileBaseConfigured: this.areCoreLayersConfigured(store.getState()),
    });

    store.dispatch(fetchers.allRepositoriesFetcher.fetch());
    store.dispatch(macrosSuggestionsFetcher.fetch());
  };

  destroy = () => {
    this.dispatch({
      type: "PROFILE_BUILDER_DESTROY",
    });
  };

  updateResolvedValues = (resolvedValues) => {
    this.dispatch({
      type: "PROFILE_BUILDER_UPDATE_RESOLVED_VALUES",
      resolvedValues,
    });
  };

  setNewHelmRegistry = async ({ uid }) => {
    await store.dispatch(helmRegistryFetcher.fetch());
    const allHelmRegistries = helmRegistryFetcher.selector(
      store.getState()
    ).result;
    const newRegistry = allHelmRegistries.find(
      (reg) => reg.metadata.uid === uid
    );
    this.dispatch({
      type: "PROFILE_BUILDER_UPDATE_EXPANDED_PACK_REPOSITORY",
      newRegistry,
    });

    store.dispatch(
      this.forms.importActions.onChange({
        module: MODULES.IMPORT_MODULE,
        name: "packRepoId",
        value: newRegistry?.uid,
      })
    );
  };

  startConfiguration = () => {
    const unconfiguredLayer = this.state.layers.find(
      (layer) => BASE_LAYERS.includes(layer.type) && !layer.config?.tag
    );
    unconfiguredLayer &&
      this.openForm({
        formType: FORM_TYPES.PACK,
        guid: unconfiguredLayer?.guid,
      });
  };

  initPackForm = async (guid) => {
    await store.dispatch(
      this.forms.packActions.init({
        module: MODULES.PACK_MODULE,
      })
    );

    const formData = store.getState().forms[MODULES.PACK_MODULE].data;
    const repos = getAvailableRepositories(store.getState());
    if (!formData.repository && repos?.length === 1) {
      this.forms.packFieldChange({
        name: "repository",
        value: repos[0].value,
      });
    }

    if (store.getState().forms.layerPack?.data?.repository) {
      store.dispatch(packListActions.initialize(MODULES.PACK_LIST_MODULE));
    }

    if (formData.packType && formData.repository && formData.pack) {
      store.dispatch(
        fetchers.packVersionsFetcher.fetch({
          repositoryUid: formData.repository,
          packName: formData.pack,
          layerType: formData.packType,
        })
      );
    }

    store.dispatch(
      this.forms.packActions.onChange({
        module: MODULES.PACK_MODULE,
        name: "activeSelectField",
        value: !guid ? "packType" : "",
      })
    );
  };

  initSystemForm = async () => {
    const module = MODULES.SYSTEM_MODULE;
    await store.dispatch(this.forms.packActions.init({ module }));
  };

  toggleNewManifestEditor = (manifestGuid) => {
    let isEditorExpanded = !this.state.isEditorExpanded;
    if (
      manifestGuid &&
      manifestGuid !== this.state.currentAttachedManifestGuid
    ) {
      isEditorExpanded = true;
    }

    this.dispatch({
      type: "TOGGLE_NEW_MANIFEST_EDITOR",
      isEditorExpanded: isEditorExpanded,
      currentAttachedManifestGuid: manifestGuid,
      openedEditor: "attach-manifest",
    });
  };

  togglePackValues = (sectionName = "") => {
    const manifests = [
      ...(store.getState().forms[this.state.formType]?.data?.manifests || []),
    ];
    this.forms.packFieldChange({
      name: "manifests",
      value: manifests,
    });

    this.dispatch({
      type: "PROFILE_BUILDER_PACK_VALUES_TOGGLE",
      sectionName: sectionName,
      isEditorExpanded: !!sectionName,
    });
  };

  onEditorSelectLayer = (layerGuid) => {
    this.dispatch({
      type: "PROFILE_BUILDER_EDITOR_SELECT_LAYER",
      layerGuid,
    });
  };

  setCurrentEditorName = (name) => {
    this.dispatch({
      type: "PROFILE_BUILDER_EDITOR_SET_NAME",
      name,
    });
  };

  openNextLayer = async () => {
    const layers = this.state.orderedLayers;
    const currentLayer = layers.find(
      (layer) => layer.guid === this.state.selectedLayer
    );
    const currentIndex = layers.indexOf(currentLayer);
    const previousGuid = layers[currentIndex + 1]?.guid;

    const hasErrors = await this.onFormSubmit({
      formType: layers[currentIndex]?.formType,
    });
    if (hasErrors) {
      return;
    }

    this.openForm({
      formType: layers[currentIndex + 1]?.formType,
      guid: previousGuid,
    });
  };

  validateSystemPackForm = () => {
    return store.dispatch(
      this.forms.packActions.validateForm({ module: MODULES.SYSTEM_MODULE })
    );
  };

  onFormSubmit = async ({ formType }) => {
    let selectedLayer = this.state.selectedLayer;
    if (!selectedLayer) {
      selectedLayer = this.state.draftLayers?.[0];
    }

    if (formType === FORM_TYPES.IMPORT) {
      await this.importedPacks.updateImportedPackLayers();
    }

    if (formType === FORM_TYPES.SYSTEM) {
      await this.validateSystemPackForm();
    }

    const layer = this.state.layers.find(
      (layer) => layer.guid === selectedLayer
    );

    await this.validateLayer(layer);
    const errors = this.getPackErrors();
    const formErrors = store.getState().forms?.[formType]?.errors || [];

    const hasErrors =
      [...errors, ...formErrors].filter((err) => err.result)?.length > 0;

    if (hasErrors && formType !== FORM_TYPES.IMPORT) {
      return hasErrors;
    }

    if (formType === FORM_TYPES.MANIFEST) {
      await store.dispatch(
        this.forms.manifestFormActions.submit({
          module: MODULES.MANIFEST_MODULE,
        })
      );
    }

    this.dispatch({
      type: "PROFILE_BUILDER_SUBMIT_DRAFT_LAYERS",
    });

    if (!this.state.isProfileBaseConfigured) {
      const isProfileBaseConfigured = this.areCoreLayersConfigured(
        store.getState()
      );

      if (isProfileBaseConfigured) {
        this.dispatch({
          type: "PROFILE_BUILDER_SET_BASE_IS_CONFIGURED",
          isProfileBaseConfigured: true,
        });
      }
    }
  };

  onFormCancel = () => {
    const { formType, selectedLayer, layerClone } = this.state;

    if (selectedLayer) {
      const initialData = store.getState().forms[formType]?.initialData;
      const form =
        formType === "layerPack"
          ? this.forms.packActions
          : this.forms.manifestFormActions;

      store.dispatch(
        form.batchChange({
          module: formType,
          updates: {
            ...initialData,
          },
        })
      );

      this.dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER",
        layer: layerClone,
      });
    }

    this.dispatch({
      type: "PROFILE_BUILDER_TOGGLE_ADD_FORM",
      formType: null,
    });

    this.dispatch({
      type: "PROFILE_BUILDER_CLEAR_IMPORT_CLUSTER",
    });

    this.dispatch({
      type: "PROFILE_BUILDER_UNSELECT_LAYER",
    });
  };

  validateLayer = async (layer) => {
    if (layer) {
      if (!layer.config) {
        return;
      }
      const validations = configValidator.run(layer.config);
      for await (const error of validations) {
        this.dispatch({
          type: "PROFILE_BUILDER_UPDATE_PACK_ERROR",
          error,
          packGuid: layer.guid,
        });
      }
    }
  };

  onCancelFormChanges = () => {
    const isInitialState = this.isLayerFormInitialState(store.getState());
    if (!isInitialState) {
      this.cancelLayerChanges.open().then(() => this.onFormCancel());
      return;
    }
    this.onFormCancel();
  };

  onStackLayerClick = async (guid) => {
    let selectedLayer = this.state.selectedLayer;
    if (!selectedLayer) {
      selectedLayer = this.state.draftLayers?.[0];
    }

    const isInitialState = this.isLayerFormInitialState(store.getState());

    if (selectedLayer && !isInitialState) {
      this.cancelLayerChanges.open().then(() => {
        this.onFormCancel();
        this.openForm({ guid });
      });
      return;
    }

    this.onFormCancel();

    const previousSelectedLayer = this.state.layers.find(
      (layer) => layer.guid === selectedLayer
    );

    await this.validateLayer(previousSelectedLayer);
    const errors = this.getPackErrors();
    const hasErrors = errors.filter((err) => err.result)?.length > 0;

    if (hasErrors) {
      return;
    }

    this.openForm({ guid });
  };

  openForm = async ({ formType, guid }) => {
    const layerGuid = guid || uuid();

    const selectedLayer =
      guid && this.state.layers.find((layer) => layer.guid === guid);

    const layerFormType = formType || selectedLayer?.formType;

    const newLayer = {
      guid: layerGuid,
      type: "new",
      isDraft: true,
      formType: layerFormType,
      persisted: false,
      config: null,
    };

    const formTypeDispatcher = {
      [FORM_TYPES.PACK]: () => this.initPackForm(guid),
      [FORM_TYPES.IMPORT]: () => this.importedPacks.initImportForm(guid),
      [FORM_TYPES.MANIFEST]: () => this.initManifestForm(layerGuid),
      [FORM_TYPES.SYSTEM]: () => this.initSystemForm(guid),
    };

    this.dispatch({
      type: "PROFILE_BUILDER_TOGGLE_ADD_FORM",
      formType: layerFormType,
    });

    if (layerFormType === FORM_TYPES.IMPORT) {
      formTypeDispatcher[layerFormType]();
      return;
    }

    const expandedEditor = {
      [FORM_TYPES.PACK]: "packs",
      [FORM_TYPES.MANIFEST]: "attach-manifest",
      [FORM_TYPES.SYSTEM]: "attach-manifest",
    };

    if (guid) {
      this.dispatch({
        type: "PROFILE_BUILDER_SELECT_LAYER",
        layer: selectedLayer,
        openedEditor: this.state.isEditMode
          ? expandedEditor[layerFormType]
          : "",
        isEditorExpanded: this.state.isEditMode && selectedLayer.config?.tag,
      });
    } else {
      this.dispatch({
        type: "PROFILE_BUILDER_ADD_DRAFT_LAYER",
        layer: newLayer,
        isEditorExpanded: false,
      });
    }

    formTypeDispatcher[layerFormType]();

    this.validateLayer(selectedLayer);
  };

  initManifestForm = () => {
    store.dispatch(
      this.forms.manifestFormActions.init({
        module: MODULES.MANIFEST_MODULE,
      })
    );
  };

  acceptVersionChanges = () => {
    this.dispatch({
      type: "PROFILE_BUILDER_RESOLVE_NEW_VERSION_CHANGES",
    });
  };

  discardVersionChanges = () => {
    const { previousVersionData } = this.state;
    const module = MODULES.PACK_MODULE;

    store.dispatch(
      this.forms.packActions.batchChange({
        module,
        updates: {
          ...previousVersionData,
        },
      })
    );

    this.dispatch({
      type: "PROFILE_BUILDER_UPDATE_LAYER",
      layer: mapFormDataToLayers(store.getState().forms[module]?.data),
    });
    this.dispatch({
      type: "PROFILE_BUILDER_RESOLVE_NEW_VERSION_CHANGES",
    });
  };

  onPackRevert = (module = MODULES.PACK_MODULE) => {
    this.revertPackValuesConfirm.open().then(async () => {
      const defaultValues = await this.editor.getSelectedPackDefaultValues();
      const defaultPresets = getDefaultPresetsFromValues(
        defaultValues,
        this.forms.isUbuntuAdvantageDisplayed()
      );
      const valuesWithoutComment =
        getPackValuesWithoutPresetsComment(defaultValues);

      store.dispatch(
        this.forms.packActions.batchChange({
          module,
          updates: {
            presets: defaultPresets,
            values: valuesWithoutComment,
            installOrder: extractInstallOrder({
              values: valuesWithoutComment,
            }),
            displayName: extractPackDisplayName({
              values: valuesWithoutComment,
            }),
          },
        })
      );
      this.dispatch({
        type: "PROFILE_BUILDER_UPDATE_LAYER",
        layer: mapFormDataToLayers(store.getState().forms[module]?.data),
      });
    });
  };

  getPackErrors = () => {
    const { errors, selectedLayer, draftLayers } = this.state;
    const expandedLayerGuid = selectedLayer || draftLayers?.[0];
    return errors[expandedLayerGuid] || [];
  };

  getFormErrors = () => {
    return store.getState().forms?.[this.state.formType]?.errors || [];
  };

  getFormUpdatesOnPresetsChange = async (newData, currentData) => {
    const { value, group, presets } = newData;
    const { currentPresets, currentValues } = currentData;

    if (group === "ubuntuAdvantage") {
      return this.getFormUpdatesOnUbuntuAdvantageChange(newData, currentData);
    }

    const defaultValues = getPackValuesWithoutPresetsComment(
      await this.editor.getSelectedPackDefaultValues()
    );

    const selectedPresets = presets.find(
      (preset) => preset.group === group && preset.value === value
    );

    let base =
      getPreviousPresetDelta({
        presetValues: currentPresets,
        values: currentValues,
        presets,
        group,
        defaultValues,
      }) || currentValues;

    const updatedValues =
      parseYAMLValues(base, selectedPresets) || currentValues;
    const updatedPresets = {
      ...(currentPresets || {}),
      [group]: value,
    };

    return {
      values: updatedValues,
      presets: updatedPresets,
    };
  };

  getFormUpdatesOnUbuntuAdvantageChange = async (
    { value, name, group },
    { currentPresets, currentValues }
  ) => {
    const shouldUpdateCurrentValues =
      currentPresets?.ubuntuAdvantage?.enabled || name === "enabled";
    const updatedPresets = {
      ...(currentPresets || {}),
      ubuntuAdvantage: {
        ...currentPresets.ubuntuAdvantage,
        [name]: value,
      },
    };

    if (!shouldUpdateCurrentValues) {
      return {
        values: currentValues,
        presets: updatedPresets,
      };
    }

    const defaultValues = getPackValuesWithoutPresetsComment(
      await this.editor.getSelectedPackDefaultValues()
    );

    const presets = generateUbuntuAdvantagePresets(
      updatedPresets.ubuntuAdvantage
    );
    const presetValues = getUbuntuAdvantagePresets(
      { field: name, value },
      updatedPresets.ubuntuAdvantage
    );
    let base =
      getPreviousPresetDelta({
        presetValues: currentPresets,
        values: currentValues,
        presets,
        group,
        defaultValues,
      }) || currentValues;

    const updatedValues = parseYAMLValues(base, presetValues);

    return {
      values: typeof updatedValues === "string" ? updatedValues : currentValues,
      presets: updatedPresets,
    };
  };

  removeSelectedLayer = () => {
    this.removeLayerConfirm.open().then(() => {
      this.dispatch({
        type: "PROFILE_BUILDER_REMOVE_LAYER",
      });
      this.onFormSubmit({ formType: FORM_TYPES.PACK });
    });
  };

  async validateProfilePacks(
    { cloudType = "all", profileType = "cluster" } = {},
    isEdit = false
  ) {
    const packs = extractPayload(this.state.layers);

    const payload = {
      cloudType,
      packs,
      type: profileType,
    };

    let endpoint = "v1/clusterprofiles/validate/packs";

    if (isEdit) {
      const clusterProfile = getRawClusterProfile(store.getState());
      endpoint = `v1/clusterprofiles/${clusterProfile.metadata.uid}/validate/packs`;
    }

    const promise = api.post(endpoint, payload);

    this.dispatch({
      type: "VALIDATE_PROFILE_PACKS",
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong while validating the layers"),
        description: err.message,
      });
      return Promise.reject();
    }
    const validationErrors = this.state.backendErrors || [];

    if (validationErrors.length > 0) {
      notifications.warning({
        message: i18next.t(
          "There are pack validation errors. Please fix them in order to continue"
        ),
      });
      return Promise.reject();
    }

    return Promise.resolve();
  }
}
