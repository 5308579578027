import React from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import isSemver from "semver/functions/valid";

import language from "i18next";
import isUrl from "validator/lib/isURL";
import isIP from "validator/lib/isIP";
import isJSON from "validator/lib/isJSON";
import isIPRange from "validator/lib/isIPRange";
import isFQDN from "validator/lib/isFQDN";
import isEmail from "validator/lib/isEmail";
import isFloat from "validator/lib/isFloat";
import debouncePromise from "utils/debouncePromise";
import { isValidRegex } from "utils/strings";

const KUBERNETES_TAGS_REGEX = /^[a-zA-Z0-9]([-a-zA-Z0-9._]*[a-zA-Z0-9])?$/;
const GCP_KEY_TAG_REGEX = /^[a-z]([-a-z0-9_]*[a-z0-9])?$/;
const TAG_VALUE_REGEX = /(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])?/;

const KUBERNETES_TAG_RULES = [
  () => language.t("Value and key must be 63 characters or less"),
  () =>
    language.t(
      "Value and key must start and end with an alphanumeric character"
    ),
  () =>
    language.t(
      "Value and key can contain only alphanumeric characters, dots, dashes or underscores"
    ),
];

const GCP_TAG_RULES = [
  () => language.t("Value and key must be 63 characters or less"),
  () => language.t("Value must start and end with an alphanumeric character"),
  () =>
    language.t(
      "Value can contain only alphanumeric characters, dots, dashes or underscores"
    ),
  () => language.t("Key must start with a lowercase character"),
  () =>
    language.t(
      "Key can contain only lowercase letters, numeric characters, dashes or underscores"
    ),
];

const KEY_VALUE_TAG_RULES = [
  () => language.t("Value can be left empty"),
  () =>
    language.t(
      "Value and key must start and end with an alphanumeric character"
    ),
  () =>
    language.t(
      "Value and key can contain only alphanumeric characters, dots, dashes or underscores"
    ),
];

const K8sGuidelinesTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 400px;
  }
`;

export function Missing({ message = () => language.t("Missing field") } = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.length === 0 ? message() : false;
    }
    return ["", undefined, null].includes(value) ? message() : false;
  };
}

export function isZeroOrGreater({
  message = () => language.t("Value must be 0 or greater than 0"),
} = {}) {
  return (value) => (value >= 0 ? false : message());
}

export function ApplyIf(conditionFn, continueFn) {
  return function validationFn(...args) {
    const isConditionMet = conditionFn(...args);
    if (!isConditionMet) {
      return false;
    }

    return continueFn(...args);
  };
}

const InvalidKubernetesTagsMessage = ({
  text,
  tooltipPlacement = "right",
  rules = KUBERNETES_TAG_RULES,
}) => {
  const { t } = useTranslation();

  return (
    <K8sGuidelinesTooltip
      getPopupContainer={(triggerNode) => triggerNode}
      defaultVisible={true}
      placement={tooltipPlacement}
      overlayStyle={{ width: 400 }}
      title={
        <div>
          {rules.map((ruleMessage, index) => (
            <div key={index}>- {ruleMessage()}</div>
          ))}
        </div>
      }
    >
      {(text && text()) ||
        t(
          "One or more tags are invalid. Tags must respect kubernetes guidelines"
        )}{" "}
      <FontAwesomeIcon icon={faInfoCircle} />
    </K8sGuidelinesTooltip>
  );
};

export function areValidDomainTags({
  genericMessage = () => language.t("One or more domains are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((domain) => !isFQDN(domain));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function areValidIPTags({
  genericMessage = () => language.t("One or more ips are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((ip) => !isIP(ip));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function areValidEmailTags({
  genericMessage = () => language.t("One or more emails are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((email) => !isEmail(email));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function areValidKubernetesTags({
  errorMessageText,
  tooltipPlacement,
  genericMessage = ({ text, tooltipPlacement } = {}) => (
    <InvalidKubernetesTagsMessage
      text={text}
      tooltipPlacement={tooltipPlacement}
    />
  ),
  tagValuesRegex = KUBERNETES_TAGS_REGEX,
  tagKeysRegex = KUBERNETES_TAGS_REGEX,
  onlySpectroTags = false,
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    if (onlySpectroTags) {
      const invalidTags = value.filter((tag) => {
        return (
          !tag.includes(":") && (!tag.match(tagValuesRegex) || tag.length > 63)
        );
      });
      return invalidTags.length === 0
        ? false
        : {
            result: genericMessage(),
            invalidTags,
          };
    }

    const keys = value
      .map((tag) => {
        if (tag.includes(":")) {
          return tag.split(":")?.[0];
        }
        return tag;
      })
      .filter(Boolean);

    const values = value
      .map((tag) => {
        if (tag.includes(":")) {
          return (tag.split(":")?.[1] || "").trim();
        }
        return false;
      })
      .filter(Boolean);

    const invalidTagValues = [
      ...new Set(
        values
          .map((tag) => {
            if (!tag.match(tagValuesRegex) || tag.length > 63) {
              return value.find((originalTag) => {
                if (originalTag.includes(":")) {
                  const originalValue = originalTag.split(":")?.[1];
                  return originalValue.includes(tag);
                }
                return false;
              });
            }
            return undefined;
          })
          .filter(Boolean)
      ),
    ];

    const invalidTagKeys = [
      ...new Set(
        keys
          .map((key) => {
            if (!key.match(tagKeysRegex) || key.length > 63) {
              return value.find((originalTag) => {
                if (originalTag.includes(":")) {
                  const originalKey = originalTag.split(":")?.[0];
                  return originalKey.trim() === key.trim();
                }

                return undefined;
              });
            }
            return undefined;
          })
          .filter(Boolean)
      ),
    ];

    const invalidTags = [...invalidTagValues, ...invalidTagKeys];

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage({ text: errorMessageText, tooltipPlacement }),
          invalidTags,
        };
  };
}

export function areValidGcpTags() {
  return areValidKubernetesTags({
    genericMessage: () => (
      <InvalidKubernetesTagsMessage rules={GCP_TAG_RULES} />
    ),
    tagKeysRegex: GCP_KEY_TAG_REGEX,
  });
}

export function areValidKeyValueTags() {
  return areValidKubernetesTags({
    genericMessage: () => (
      <InvalidKubernetesTagsMessage rules={KEY_VALUE_TAG_RULES} />
    ),
    tagValuesRegex: TAG_VALUE_REGEX,
  });
}

export function isKubernetesName({
  allowDashStartEnd = false,
  dashRuleMessage = () => language.t("Field can't start or end with a dash"),
  genericMessage = () =>
    language.t("Field must contain only lowercase letters, dashes and numbers"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    if (
      !allowDashStartEnd &&
      [value[0], value[value.length - 1]].includes("-")
    ) {
      return dashRuleMessage();
    }

    return value.match(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/)
      ? false
      : genericMessage();
  };
}

export function MaxLength(
  maxNumber,
  {
    message = () =>
      language.t("Only a maximum of {{maxNumber}} characters are allowed", {
        maxNumber,
      }),
  } = {}
) {
  return (value) => (value && value.length > maxNumber ? message() : false);
}

export function MinLength(
  minNumber,
  {
    message = () =>
      language.t("Field must contain at least {{minNumber}} characters", {
        minNumber,
      }),
  } = {}
) {
  return (value) => (value && value.length < minNumber ? message() : false);
}

export function isValidUrl(
  options,
  { message = () => language.t("Field must contain a valid url") } = {}
) {
  const defaultOptions = {
    protocols: ["http", "https"],
    require_protocol: true,
    require_tld: true,
    require_valid_protocol: true,
  };
  const urlOptions = { ...defaultOptions, ...options };

  return (value) => (value && !isUrl(value, urlOptions) ? message() : false);
}

export function areValidIPRangesTags({
  genericMessage = () => language.t("One or more cidrs are invalid"),
} = {}) {
  return (value) => {
    if (!value) {
      return false;
    }

    const invalidTags = value.filter((cidr) => !isIPRange(cidr));

    return invalidTags.length === 0
      ? false
      : {
          result: genericMessage(),
          invalidTags,
        };
  };
}

export function isValidIPRange({
  message = () => language.t("Invalid IP Range"),
} = {}) {
  return (value) => (value && !isIPRange(value) ? message() : false);
}

export function isValidIP({ message = () => language.t("Invalid IP") } = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.some((ip) => !isIP(ip)) ? message() : false;
    }
    return value && !isIP(value) ? message() : false;
  };
}

export function isValidDomain({
  message = () => language.t("Invalid domain"),
} = {}) {
  return (value) => {
    if (Array.isArray(value)) {
      return value.some((domain) => !isFQDN(domain)) ? message() : false;
    }
    return value && !isFQDN(value) ? message() : false;
  };
}

export function isValidPrefix({
  message = () => language.t("Field must contain a value between 1 and 32"),
} = {}) {
  return (value) =>
    isNaN(value) || value < 1 || value > 32 ? message() : false;
}

export function isValidGateway({
  message = () => language.t("Invalid gateway"),
} = {}) {
  return (value) =>
    value && !isIPRange(value) && !isIP(value) ? message() : false;
}

export function isValidTagSelection({
  reservedTags = [],
  message = () =>
    language.t(
      "Invalid tag name. Following tag name prefixes are reserved: '{{tags}}'",
      {
        tags: reservedTags.join(","),
      }
    ),
} = {}) {
  const isReservedTagIncluded = (tag) =>
    reservedTags.some((reservedTag) =>
      tag.toLowerCase().startsWith(reservedTag.toLowerCase())
    );
  return (tags) =>
    tags?.some((tag) => isReservedTagIncluded(tag)) ? message() : false;
}

export function EmailType({
  message = () => language.t("Field must contain a valid email"),
} = {}) {
  return (value) => (value && !isEmail(value) ? message() : false);
}

export function FloatType({
  message = () => language.t("Field must be a number"),
} = {}) {
  return (value) => (value && !isFloat(`${value}`) ? message() : false);
}

export function EmptyString({
  message = () => language.t("Invalid field"),
} = {}) {
  return (value) => (value && !value.trim() ? message() : false);
}

export function DebouncedRule({ delay = 400 } = {}) {
  return (fn) => debouncePromise(fn, delay);
}

export function isJSONFormat({
  message = () => language.t("Field must contain a valid JSON object"),
} = {}) {
  return (value) => (value && !isJSON(value.trim()) ? message() : false);
}

export function isWorkspaceQuotaExceeded() {
  return (value, key, data) => {
    const summedNsCPUCores = data.namespaces.reduce(
      (acc, ns) =>
        ns.children.reduce(
          (clustersAcc, cluster) =>
            parseFloat(cluster?.alocCpu || 0) + clustersAcc,
          0
        ) + acc,
      0
    );

    const summedNsMemory = data.namespaces.reduce(
      (acc, ns) =>
        ns.children.reduce(
          (clustersAcc, cluster) =>
            parseFloat(cluster?.alocMemory || 0) + clustersAcc,
          0
        ) + acc,
      0
    );

    const parsedCpuQuota = parseFloat(data?.cpuQuota || 0);
    if (parsedCpuQuota > 0 && summedNsCPUCores > parsedCpuQuota) {
      return language.t(
        "CPU resources exceed workspace quota. Total {{cores}} cores out of maximum {{quota}} cores",
        {
          cores: summedNsCPUCores,
          quota: data.cpuQuota,
        }
      );
    }

    const parsedMemoryQuota = parseFloat(data?.memoryQuota || 0);
    if (parsedMemoryQuota && summedNsMemory > parsedMemoryQuota) {
      return language.t(
        "Memory resources exceed workspace quota. Total {{memory}}GB out of maximum {{quota}}GB",
        {
          memory: summedNsMemory,
          quota: data.memoryQuota,
        }
      );
    }
  };
}

const emptyAllocationValues = ["", undefined];

export function checkAllocationValues(data, type) {
  const { alocCpu, alocMemory } = data;
  const alocValues = [alocCpu, alocMemory];
  const areAlocEqual = alocCpu === alocMemory;

  const isValid = alocValues.every((value) => {
    const isEmpty = emptyAllocationValues.includes(value) && areAlocEqual;
    let isZero = parseFloat(value) === 0 && areAlocEqual;
    let isPositive = parseFloat(value) > 0;
    let isUnlimited = false;

    if (type === "clusters") {
      isUnlimited = parseFloat(value) === -1 && areAlocEqual;
    }

    return isZero || isUnlimited || isEmpty || isPositive;
  });

  return !isValid;
}

export function isValidNamespaceAllocation({
  message = () =>
    language.t(
      "Either both namespace cpu and memory should be 0 or must be greater than 0"
    ),
} = {}) {
  return (value, key, data) => {
    const errors = (data?.namespaces || []).find((ns) => {
      if (checkAllocationValues(ns, "namespaces")) {
        return ns;
      }
      return false;
    });

    if (!!errors) {
      return message();
    }
  };
}

export function isValidClusterAllocation({
  message = () =>
    language.t(
      "Either both cluster cpu and memory should be 0/-1 or must be greater than 0"
    ),
} = {}) {
  return (value, key, data) => {
    const errors = (data?.namespaces || []).find((ns) => {
      if (
        (ns?.children || []).some((cluster) =>
          checkAllocationValues(cluster, "clusters")
        )
      ) {
        return ns;
      }
      return false;
    });

    if (!!errors) {
      return message();
    }
  };
}

export function* isUnlimitedAloc(value, key, data) {
  for (const index in data.namespaces) {
    for (const childIndex in data.namespaces[index].children) {
      const hasQuota =
        parseFloat(data.cpuQuota) > 0 || parseFloat(data.memoryQuota) > 0;
      const isAlocCpuNegative =
        data.namespaces[index]?.children[childIndex]?.alocCpu < 0;

      const isAlocMemoryNegative =
        data.namespaces[index]?.children[childIndex]?.alocMemory < 0;

      const message = language.t(
        "Cluster can't have unlimited allocation due to workspace {{aloc}} quota",
        {
          aloc: parseFloat(data.cpuQuota) > 0 ? "CPU" : "Memory",
        }
      );

      yield {
        field: `namespaces.${index}.children.${childIndex}.alocCpu`,
        result: hasQuota && isAlocCpuNegative ? message : false,
      };
      yield {
        field: `namespaces.${index}.children.${childIndex}.alocMemory`,
        result: hasQuota && isAlocMemoryNegative ? message : false,
      };
    }
  }
}

export function SemanticVersion({
  message = () =>
    language.t(
      "Must be a valid sematic version format. Example: 1.0.0, 1.0.0-alpha.1, 1.0.0-beta.2"
    ),
} = {}) {
  return (value) => {
    return value && isSemver(value) === null ? message() : false;
  };
}

export function isValidRegexNamespace({
  message = () => language.t("Invalid regex expression"),
} = {}) {
  return (value, key, data) => {
    if (["/", "~/"].some((start) => value.startsWith(start))) {
      if (!isValidRegex(value)) {
        return message();
      }
      return false;
    }

    return isKubernetesName()(value, key, data);
  };
}
