import i18next from "i18next";
import isEqual from "fast-deep-equal";
import _ from "lodash";

import store from "services/store";
import api from "services/api";
import Validator from "services/validator";
import {
  Missing,
  isKubernetesName,
  isValidNamespaceAllocation,
  isWorkspaceQuotaExceeded,
  isZeroOrGreater,
  isValidClusterAllocation,
  isUnlimitedAloc,
  isValidRegexNamespace,
} from "services/validator/rules";
import notifications from "services/notifications";
import { v4 as uuid } from "uuid";

import { parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";
import { isValidRegex } from "utils/strings";

import createFormActions from "modules/form/actions";
import { createRoleBindingsFormFactory } from "modules/rolebindings";
import { createContainerImagesFormFactory } from "modules/containerImages";

import { getRawWorkspace } from "state/workspaces/selectors/details";
import {
  clusterRoleBindingsModal,
  EDIT_CLUSTERS_MODULE,
  EDIT_NAMESPACES_MODULE,
  namespaceRoleBindingsModal,
  EDIT_CONTAINER_IMAGES_MODULE,
} from "state/workspaces/services/edit";
import {
  getNewSubjectObject,
  clusterRoleBindingsValidator,
  nsRoleBindingsValidator,
} from "state/workspaces/actions/create/common";
import { getSelectedClustersList } from "state/workspaces/selectors/edit";
import {
  getParsedClustersResources,
  parseNamespacesFormData,
  parseContainerImages,
} from "./create/form";
import { refreshWorkspace } from "./details";
import { workspacesClustersMetadataFetcher } from "../services/create";
import { ClusterSchema } from "utils/schemas";
import { getStoreEntity } from "services/store";

export const basicInfoValidator = new Validator();
export const clustersValidator = new Validator();
export const namespaceValidator = new Validator();
export const namespacesValidator = new Validator();

clustersValidator.addRule(["clusters"], Missing());
basicInfoValidator.addRule(["name"], Missing());
basicInfoValidator.addRule(["name"], isKubernetesName());
namespaceValidator.addRule(["namespaces"], (value, key, data) => {
  if (!value.length) {
    return i18next.t(
      "Please add one namespace, or consider deleting the workspace"
    );
  }
  return isValidNamespaceAllocation()(value, key, data);
});

namespaceValidator.addRule("namespace", isValidRegexNamespace());
namespaceValidator.addRule(["namespaces"], isValidClusterAllocation());
namespaceValidator.addRule("namespace", (value, key, data) => {
  if (data?.namespaces?.find((ns) => ns.namespaceName === value)) {
    return i18next.t("Namespaces must be unique");
  }
  return false;
});

namespaceValidator.addRule(["namespaces"], isWorkspaceQuotaExceeded());

namespaceValidator.addRule("namespaces", function* (value, key, data) {
  const parsedClustersResourcesData = getParsedClustersResources(data);
  const parsedCpuQuota = parseFloat(data?.cpuQuota || 0);

  const higherCpuThanQuotaClusters = parsedClustersResourcesData.filter(
    (cluster) => parsedCpuQuota > 0 && cluster.totalAlocCpu > parsedCpuQuota
  );

  for (const index in data.namespaces) {
    for (const childIndex in data.namespaces[index].children) {
      yield {
        field: `namespaces.${index}.children.${childIndex}.alocCpu`,
        result:
          parseInt(childIndex) === higherCpuThanQuotaClusters?.[0]?.index
            ? i18next.t(
                "The CPU allocated for this cluster has exceeded the workspace quota"
              )
            : false,
      };
    }
  }
});

namespaceValidator.addRule("namespaces", function* (value, key, data) {
  const parsedClustersResourcesData = getParsedClustersResources(data);
  const parsedMemoryQuota = parseFloat(data?.memoryQuota || 0);

  const higherMemoryThanQuotaClusters = parsedClustersResourcesData.filter(
    (cluster) =>
      parsedMemoryQuota > 0 && cluster.totalAlocMemory > parsedMemoryQuota
  );

  for (const index in data.namespaces) {
    for (const childIndex in data.namespaces[index].children) {
      yield {
        field: `namespaces.${index}.children.${childIndex}.alocMemory`,
        result:
          parseInt(childIndex) === higherMemoryThanQuotaClusters?.[0]?.index
            ? i18next.t(
                "The memory allocated for this cluster has exceeded the workspace quota"
              )
            : false,
      };
    }
  }
});

namespaceValidator.addRule("namespaces", isUnlimitedAloc);

namespaceValidator.addRule(["cpuQuota", "memoryQuota"], isZeroOrGreater());

export const editBasicInfoFormActions = createFormActions({
  init: () => {
    const workspace = getRawWorkspace(store.getState());
    const { name, annotations, labels } = workspace?.metadata || {};

    return Promise.resolve({
      name: name || "",
      description: annotations?.description || "",
      tags: parseTagsForInput(labels),
    });
  },
  validator: basicInfoValidator,
  submit: async ({ name, description, tags }) => {
    const uid = getRawWorkspace(store.getState())?.metadata?.uid;
    const payload = {
      name,
      annotations: {
        description,
      },
      labels: formatTags(tags),
    };
    const promise = api.put(`v1/workspaces/${uid}/meta`, payload);

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the workspace info"
        ),
        description: error?.message,
      });
      return promise;
    }
  },
});

const containerImagesValidator = new Validator();
const containerImagesFieldsValidator = new Validator();
containerImagesFieldsValidator.addRule(
  ["namespace", "restrictedImages"],
  Missing()
);
containerImagesValidator.addRule(
  "containerImages",
  containerImagesFieldsValidator
);

async function updateClusterRefs(workspace) {
  const uid = workspace.metadata.uid;
  const workspaceData = getRawWorkspace(store.getState());
  const clusters = getSelectedClustersList(store.getState());
  const promise = api.put(`v1/workspaces/${uid}/resourceAllocations`, {
    quota: {
      ...workspaceData.spec.quota,
    },
    clusterNamespaces: workspace.spec?.clusterNamespaces,
    clusterRefs: clusters.map((cluster) => ({
      clusterName: cluster.metadata.name,
      clusterUid: cluster.metadata.uid,
    })),
  });

  try {
    await promise;
    notifications.success({
      message: i18next.t("Clusters were updated successfully"),
    });
    store.dispatch(refreshWorkspace());
  } catch (error) {
    notifications.error({
      message: i18next.t(
        "Something went wrong when trying to update the clusters"
      ),
      description: error?.message,
    });
  }
  return promise;
}

function parseRoleBindingsFormData(roleBindings, type) {
  return (roleBindings || []).map((rbac) => ({
    role: {
      kind: type === "ClusterRoleBinding" ? "ClusterRole" : rbac.roleType,
      name: rbac.roleName,
    },
    namespace: rbac.namespace,
    subjects: rbac.subjects || [],
    type,
  }));
}

export function fetchClustersByUids(clusterUids) {
  clusterUids.forEach((uid) => {
    store.dispatch({
      type: "FETCH_WORKSPACE_SELECTED_CLUSTER",
      promise: api.get(`v1/dashboard/spectroclusters/${uid}`),
      schema: ClusterSchema,
    });
  });
}

export const editClustersFormActions = createFormActions({
  async init() {
    const workspace = getRawWorkspace(store.getState());
    const clusterUids = (workspace?.spec?.clusterRefs || []).map(
      (cluster) => cluster.uid
    );
    const clusterRoleBindings =
      workspace?.spec?.clusterRbacs?.find(
        (rbac) => rbac.spec?.bindings?.[0]?.type === "ClusterRoleBinding"
      )?.spec?.bindings || [];

    await store.dispatch(workspacesClustersMetadataFetcher.fetch());
    const clusters =
      workspacesClustersMetadataFetcher.selector(store.getState())?.result ||
      [];
    const selectedClustersGuids = clusters
      .filter((cluster) => clusterUids?.includes(cluster.metadata.uid))
      .map((cluster) => cluster?.guid);

    fetchClustersByUids(clusterUids);

    return Promise.resolve({
      clusters: clusterUids,
      clusterRoleBindings: (clusterRoleBindings || []).map((rbac) => ({
        roleName: rbac.role.name,
        subjects: rbac.subjects || [],
      })),
      selectedClustersGuids: selectedClustersGuids || [],
    });
  },
  validator: clustersValidator,
  submit: async ({ clusters, clusterRoleBindings }) => {
    const workspace = getRawWorkspace(store.getState());
    const uid = workspace.metadata.uid;
    const currentClusterRbacs = workspace?.spec?.clusterRbacs?.find(
      (rbac) => rbac.spec?.bindings?.[0]?.type === "ClusterRoleBinding"
    );
    const currentRoleBindings = currentClusterRbacs?.spec?.bindings || [];
    const currentClusters = workspace?.spec?.clusterRefs?.map(
      (cluster) => cluster.uid
    );

    let clustersUpdate = Promise.resolve();

    if (!isEqual(currentClusters, clusters)) {
      clustersUpdate = updateClusterRefs(workspace);
    }

    let promise;
    const payload = {
      spec: {
        bindings: parseRoleBindingsFormData(
          clusterRoleBindings,
          "ClusterRoleBinding"
        ),
      },
    };

    const rbacUid = currentClusterRbacs?.metadata.uid;

    if (clusterRoleBindings?.length) {
      if (!currentRoleBindings?.length) {
        promise = api.post(`v1/workspaces/${uid}/clusterRbacs`, payload);
      }
      if (rbacUid) {
        promise = api.put(
          `v1/workspaces/${uid}/clusterRbacs/${rbacUid}`,
          payload
        );
      }
    } else {
      promise =
        rbacUid && api.delete(`v1/workspaces/${uid}/clusterRbacs/${rbacUid}`);
    }

    if (!promise) {
      return;
    }

    try {
      await Promise.all([promise, clustersUpdate]);
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the clusters"
        ),
        description: error?.message,
      });
      return promise;
    }
  },
});

export const clusterRoleBindingsFormActions = createFormActions({
  init: () => {
    const selectedIndex = clusterRoleBindingsModal.data?.index;
    let data;
    if (selectedIndex >= 0) {
      data =
        store.getState().forms[EDIT_CLUSTERS_MODULE]?.data
          ?.clusterRoleBindings?.[selectedIndex];
    }

    return Promise.resolve({
      subjects: data?.subjects || [getNewSubjectObject()],
      roleName: data?.roleName || "",
      roleType: data?.roleType || "Role",
    });
  },
  validator: clusterRoleBindingsValidator,
  submit: (data) => {
    const index = clusterRoleBindingsModal.data?.index;

    store.dispatch(
      editClustersRoleBindingsForm.actions.onChangeRoleBindings({
        index,
        name: "clusterRoleBindings",
        data,
      })
    );
  },
});

export const editNamespacesFormActions = createFormActions({
  init() {
    const workspace = getRawWorkspace(store.getState());
    const clusterRefs = workspace?.spec?.clusterRefs || [];
    const nsRoleBindings =
      workspace?.spec?.clusterRbacs?.find(
        (rbac) => rbac.spec?.bindings?.[0]?.type === "RoleBinding"
      )?.spec?.bindings || [];

    const namespaces =
      workspace?.spec?.clusterNamespaces?.reduce((acc, ns) => {
        const { defaultResourceAllocation, clusterResourceAllocations } =
          ns.namespaceResourceAllocation;
        const guid = uuid();

        const children = clusterRefs.reduce((accumulator, cluster) => {
          const childData = clusterResourceAllocations?.find(
            (res) => res.clusterUid === cluster.uid
          );
          let alocMemory =
            childData?.resourceAllocation?.memoryMiB / 1024 || "";

          if (childData?.resourceAllocation?.memoryMiB === -1) {
            alocMemory = -1;
          }
          return [
            ...accumulator,
            {
              alocMemory,
              alocCpu: childData?.resourceAllocation?.cpuCores || "",
              clusterName: cluster.name,
              clusterUid: cluster.uid,
            },
          ];
        }, []);

        const mainNamespace = {
          guid,
          alocMemory: defaultResourceAllocation.memoryMiB / 1024 || 0,
          alocCpu: defaultResourceAllocation.cpuCores || 0,
          namespaceName: ns.name,
          isRegex: ns.isRegex,
          children,
        };
        return [...acc, mainNamespace];
      }, []) || [];

    const clusters = namespaces[0].children.map(
      (cluster) => cluster.clusterUid
    );

    return Promise.resolve({
      namespace: "",
      cpuQuota: workspace?.spec?.quota?.resourceAllocation?.cpuCores || 0,
      memoryQuota:
        (workspace?.spec?.quota?.resourceAllocation?.memoryMiB || 0) / 1024,
      namespaces,
      clusters,
      namespaceRoleBindings: (nsRoleBindings || []).map((rbac) => ({
        roleType: rbac.role.kind,
        roleName: rbac.role.name,
        namespace: rbac.namespace,
        subjects: rbac.subjects || [],
      })),
    });
  },
  validator: namespaceValidator,
  submit: async ({
    namespaces,
    namespaceRoleBindings,
    cpuQuota,
    memoryQuota,
  }) => {
    const workspace = getRawWorkspace(store.getState());
    const uid = workspace?.metadata?.uid;

    const errors = await store.dispatch(
      editNamespacesFormActions.validateForm({ module: EDIT_NAMESPACES_MODULE })
    );

    if (errors.length) {
      return Promise.reject(errors);
    }

    const parsedContainerImages = workspace.spec?.clusterNamespaces?.reduce(
      (accumulator, namespace) => {
        if (!accumulator[namespace?.name]) {
          accumulator[namespace?.name] =
            namespace?.image?.blackListedImages || [];
        }
        return accumulator;
      },
      {}
    );
    const promise = api.put(`v1/workspaces/${uid}/clusterNamespaces `, {
      clusterNamespaces: parseNamespacesFormData(
        namespaces,
        parsedContainerImages
      ),
      quota: {
        resourceAllocation: {
          cpuCores: parseFloat(cpuQuota) || 0,
          memoryMiB: (parseFloat(memoryQuota) || 0) * 1024,
        },
      },
      clusterRefs: (workspace.spec?.clusterRefs || []).map((cluster) => ({
        clusterName: cluster?.name,
        clusterUid: cluster?.uid,
      })),
    });

    let rbacPromise;
    const currentNamespaceRbacs = workspace?.spec?.clusterRbacs?.find(
      (rbac) => rbac.spec?.bindings?.[0]?.type === "RoleBinding"
    );
    const currentRoleBindings = currentNamespaceRbacs?.spec?.bindings || [];
    const payload = {
      spec: {
        bindings: parseRoleBindingsFormData(
          namespaceRoleBindings,
          "RoleBinding"
        ),
      },
    };

    const rbacUid = currentNamespaceRbacs?.metadata.uid;

    if (namespaceRoleBindings?.length) {
      if (!currentRoleBindings?.length) {
        rbacPromise = api.post(`v1/workspaces/${uid}/clusterRbacs`, payload);
      }
      if (rbacUid) {
        rbacPromise = api.put(
          `v1/workspaces/${uid}/clusterRbacs/${rbacUid}`,
          payload
        );
      }
    } else {
      rbacPromise =
        rbacUid && api.delete(`v1/workspaces/${uid}/clusterRbacs/${rbacUid}`);
    }

    const promises = [promise, rbacPromise].filter(Boolean);

    try {
      await Promise.all(promises);
      store.dispatch(refreshWorkspace());
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update namespaces"
        ),
        description: error?.message,
      });
      return promise;
    }
  },
});

export const namespaceRoleBindingsFormActions = createFormActions({
  init: () => {
    const selectedIndex = namespaceRoleBindingsModal.data?.index;
    let data;
    if (selectedIndex >= 0) {
      data =
        store.getState().forms[EDIT_NAMESPACES_MODULE]?.data
          ?.namespaceRoleBindings?.[selectedIndex];
    }

    return Promise.resolve({
      namespace: data?.namespace || null,
      subjects: data?.subjects || [getNewSubjectObject()],
      roleType: data?.roleType || "Role",
      roleName: data?.roleName || "",
    });
  },
  validator: nsRoleBindingsValidator,
  submit: (data) => {
    const index = namespaceRoleBindingsModal.data?.index;

    store.dispatch(
      editNamespacesRoleBindingsForm.actions.onChangeRoleBindings({
        index,
        name: "namespaceRoleBindings",
        data,
      })
    );
  },
});

export function onClusterChange(value) {
  return function thunk(dispatch) {
    const formClusters =
      store.getState().forms[EDIT_CLUSTERS_MODULE]?.data?.clusters || [];
    const clusters =
      workspacesClustersMetadataFetcher.selector(store.getState())?.result ||
      [];
    const addedClusters = _.difference(value, formClusters);

    fetchClustersByUids(addedClusters);

    const filteredGuids = clusters
      .filter((cluster) => value.includes(cluster.metadata.uid))
      .map((cluster) => cluster?.guid);

    dispatch(
      editClustersFormActions.batchChange({
        module: EDIT_CLUSTERS_MODULE,
        updates: {
          selectedClustersGuids: filteredGuids,
          clusters: value,
        },
      })
    );
  };
}

export function onClusterRemove(clusterUid) {
  return (dispatch) => {
    const clusterUids =
      store.getState().forms[EDIT_CLUSTERS_MODULE]?.data?.clusters || [];
    const value = clusterUids.filter((uid) => uid !== clusterUid);
    const clusters =
      workspacesClustersMetadataFetcher.selector(store.getState())?.result ||
      [];

    const filteredGuids = clusters
      .filter((cluster) => value.includes(cluster.metadata.uid))
      .map((cluster) => cluster?.guid);

    dispatch(
      editClustersFormActions.batchChange({
        module: EDIT_CLUSTERS_MODULE,
        updates: {
          selectedClustersGuids: filteredGuids,
          clusters: value,
        },
      })
    );
  };
}

export function deleteNamespace(guid) {
  return (dispatch, getState) => {
    const { namespaces, namespaceRoleBindings } =
      getState().forms[EDIT_NAMESPACES_MODULE].data;
    const errors = getState().forms[EDIT_NAMESPACES_MODULE]?.errors;
    const updatedErrors = errors.map((error) => {
      if (error.field.includes("namespaces")) {
        return { ...error, result: false };
      }
      return error;
    });

    const namespaceName = namespaces.find(
      (ns) => ns.guid === guid
    )?.namespaceName;
    store.dispatch(
      editNamespacesFormActions.onChange({
        module: EDIT_NAMESPACES_MODULE,
        name: "namespaceRoleBindings",
        value: namespaceRoleBindings.filter(
          (roleBinding) => roleBinding.namespace !== namespaceName
        ),
      })
    );

    dispatch(
      editNamespacesFormActions.onChange({
        module: EDIT_NAMESPACES_MODULE,
        name: "namespaces",
        value: namespaces.filter((ns) => ns.guid !== guid),
      })
    );
    dispatch(
      editNamespacesFormActions.updateErrors({
        module: EDIT_NAMESPACES_MODULE,
        errors: updatedErrors,
      })
    );
  };
}

export function addNamescapeToList() {
  return async (dispatch, getState) => {
    const errors = await dispatch(
      editNamespacesFormActions.validateField({
        name: "namespace",
        module: EDIT_NAMESPACES_MODULE,
      })
    );

    if (errors?.find((error) => error.field === "namespace")) {
      return;
    }

    const { namespace, namespaces } =
      getState().forms[EDIT_NAMESPACES_MODULE].data;
    const workspace = getRawWorkspace(store.getState());
    const selectedClusters = workspace?.spec?.clusterRefs || [];

    const guid = uuid();
    const children = selectedClusters.reduce((acc, cluster) => {
      return [
        ...acc,
        {
          alocMemory: "",
          alocCpu: "",
          clusterName: cluster.name,
          clusterUid: cluster.uid,
        },
      ];
    }, []);

    dispatch(
      editNamespacesFormActions.batchChange({
        module: EDIT_NAMESPACES_MODULE,
        updates: {
          namespace: "",
          namespaces: [
            ...namespaces,
            {
              guid,
              namespaceName: namespace,
              isRegex: isValidRegex(namespace),
              alocMemory: "",
              alocCpu: "",
              children: isValidRegex(namespace) ? [] : children,
            },
          ],
        },
      })
    );

    dispatch(
      editNamespacesFormActions.validateField({
        name: "namespaces",
        module: EDIT_NAMESPACES_MODULE,
      })
    );
  };
}

export const editContainerImagesFormActions = createFormActions({
  async init() {
    const workspace = getRawWorkspace(store.getState());
    const namespaces = workspace.spec?.clusterNamespaces || [];
    const containerImages = namespaces.reduce((accumulator, namespace) => {
      if (namespace?.image?.blackListedImages?.length) {
        accumulator.push({
          namespace: namespace?.name,
          restrictedImages: namespace?.image?.blackListedImages,
        });
      }
      return accumulator;
    }, []);

    return Promise.resolve({
      containerImages,
    });
  },
  validator: containerImagesValidator,
  submit: async ({ containerImages }) => {
    const workspace = getRawWorkspace(store.getState());
    const uid = workspace?.metadata?.uid;

    const errors = await store.dispatch(
      editNamespacesFormActions.validateForm({
        module: EDIT_CONTAINER_IMAGES_MODULE,
      })
    );

    if (errors.length) {
      return Promise.reject(errors);
    }

    const parsedContainerImages = parseContainerImages(containerImages);

    const promise = api.put(`v1/workspaces/${uid}/resourceAllocations`, {
      clusterNamespaces: (workspace.spec?.clusterNamespaces || []).map(
        (ns) => ({
          ...ns,
          image: { blackListedImages: parsedContainerImages[ns?.name] || [] },
        })
      ),
      clusterRefs: (workspace.spec?.clusterRefs || []).map((cluster) => ({
        clusterName: cluster?.name,
        clusterUid: cluster?.uid,
      })),
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update container images"
        ),
        description: error?.message,
      });
      return promise;
    }
  },
});

export const editClustersRoleBindingsForm = createRoleBindingsFormFactory({
  formActions: editClustersFormActions,
  formModuleName: EDIT_CLUSTERS_MODULE,
});

export const editNamespacesRoleBindingsForm = createRoleBindingsFormFactory({
  formActions: editNamespacesFormActions,
  formModuleName: EDIT_NAMESPACES_MODULE,
});

export const editContainerImagesForm = createContainerImagesFormFactory({
  formActions: editContainerImagesFormActions,
  formModuleName: EDIT_CONTAINER_IMAGES_MODULE,
});
