import React, { useMemo } from "react";
import { connect } from "react-redux";
import i18n from "i18next";
import { generatePath } from "react-router";

import PageHeader from "components/common/layouts/App/PageHeader";

import { NavLink, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  winterMist,
  white,
  nearBlack,
  blue,
  greenGray,
  platinum,
} from "utils/constants/colors";
import { SETTINGS } from "utils/constants/routes";
import { FLAGS } from "utils/constants/flags";

import { getCurrentContext, isOnPremApplication } from "state/auth/selectors";

import flags from "services/flags";
import {
  calculatedContentHeight,
  MAX_CONTENT_WIDTH,
} from "components/styled/mixins";
import Icon from "components/ui/Icon";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

const SIDEBAR_WIDTH = 260;

const MainWrap = styled.div`
  ${calculatedContentHeight}
  border-radius: 8px;
  display: flex;
  margin: -16px -32px;
  overflow: hidden;
`;

const SidebarMenu = styled.div`
  border-right: 1px solid ${winterMist};
  border-top: 1px solid ${platinum};
  max-width: ${SIDEBAR_WIDTH}px;
  background: #f7f8f9;
  width: 100%;
  margin-bottom: -16px;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Title = styled.div`
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${nearBlack};
    padding: 4.5px 24px;
    margin: 0 34px;
    background-color: ${white};
    color: ${nearBlack};
    border-left: 1px solid ${platinum};

    ${(props) =>
      props.isAdmin &&
      css`
        margin: 0 38px;
      `}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: ${parseInt(MAX_CONTENT_WIDTH.replace("px", "")) -
  SIDEBAR_WIDTH +
  26}px;
  background-color: white;
  padding: 32px;
`;

const ContentWrap = styled.div`
  flex-grow: 1;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  border-top: 1px solid ${platinum};
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 13px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${greenGray};

  :hover {
    color: ${blue};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${blue};
      background-color: ${white};
      border-left: 2px solid ${blue};
    `};
`;

function Settings({ history, children, currentContext, isOnPremApp }) {
  const PROJECT_ROUTES = [
    {
      key: "cloudaccounts",
      path: SETTINGS.CLOUD_ACCOUNTS,
      label: i18n.t("Cloud Accounts"),
    },
    flags.has(FLAGS.BACKUP) && {
      key: "backuplocations",
      path: SETTINGS.BACKUP_LOCATIONS,
      label: i18n.t("Backup Locations"),
    },
    {
      key: "sshkeys",
      path: SETTINGS.SSH_KEYS,
      label: i18n.t("SSH Keys"),
    },
    flags.has(FLAGS.ALERTS) && {
      key: "alerts",
      path: generatePath(SETTINGS.ALERTS, { tab: "email" }),
      label: i18n.t("Alerts"),
    },
    {
      key: "macros",
      path: SETTINGS.MACROS,
      label: i18n.t("Macros"),
    },
    {
      key: "platform-updates",
      path: SETTINGS.PLATFORM_UPDATES,
      label: i18n.t("Platform Updates"),
    },
  ].filter(Boolean);

  const TENANT_ROUTES = [
    {
      key: "cloudaccounts",
      path: SETTINGS.CLOUD_ACCOUNTS,
      label: i18n.t("Cloud Accounts"),
    },
    {
      key: "cloudrates",
      path: SETTINGS.CLOUD_RATES,
      label: i18n.t("Cloud Rates"),
    },
    {
      key: "registries",
      path: generatePath(SETTINGS.REGISTRIES, { tab: "helm" }),
      label: i18n.t("Registries"),
    },
    {
      key: "privatecloudgateways",
      path: SETTINGS.PRIVATE_CLOUD_GATEWAYS,
      label: i18n.t("Private Cloud Gateways"),
    },
    {
      key: "backuplocations",
      path: SETTINGS.BACKUP_LOCATIONS,
      label: i18n.t("Backup Locations"),
    },
    {
      key: "sso",
      path: generatePath(SETTINGS.SSO, { tab: "configure" }),
      label: i18n.t("SSO"),
    },
    {
      key: "password-policy",
      path: SETTINGS.PASSWORD_POLICY,
      label: i18n.t("Password Policy"),
    },
    !isOnPremApp && {
      key: "plandetails",
      path: SETTINGS.PLAN_DETAILS,
      label: i18n.t("Plan Details"),
    },
    !isOnPremApp && {
      key: "billingdetails",
      path: SETTINGS.BILLING_DETAILS,
      label: i18n.t("Billing Details"),
    },
    {
      key: "certificates",
      path: "/settings/certificates",
      label: i18n.t("Certificates"),
    },
    {
      key: "audittrails",
      path: SETTINGS.AUDIT_TRAILS,
      label: i18n.t("Audit Trails"),
    },
    {
      key: "apikeys",
      path: SETTINGS.API_KEYS,
      label: i18n.t("API Keys"),
    },
    {
      key: "macros",
      path: SETTINGS.MACROS,
      label: i18n.t("Macros"),
    },
    {
      key: "resourcelimits",
      path: SETTINGS.RESOURCE_LIMITS,
      label: i18n.t("Resource Limits"),
    },
    {
      key: "platform-updates",
      path: SETTINGS.PLATFORM_UPDATES,
      label: i18n.t("Platform Updates"),
    },
  ].filter(Boolean);

  const routes = useMemo(
    () => (currentContext?.isAdmin ? TENANT_ROUTES : PROJECT_ROUTES),
    [currentContext, TENANT_ROUTES, PROJECT_ROUTES]
  );

  let activeKey = "/";
  const location = history.location.pathname;
  if (location.startsWith("/projects/")) {
    activeKey = location.split("/")[4];
  }
  if (location.startsWith("/admin/")) {
    activeKey = location.split("/")[3];
  }

  const activeLabel = routes.find(
    (navLink) => navLink.key === activeKey
  )?.label;

  function renderNavLinks(navLink) {
    return (
      <StyledNavLink
        isSelected={activeKey === navLink.key}
        key={navLink.key}
        to={navLink.path}
        data-qa-type="settings-menu-item"
        data-qa-key={navLink.key}
      >
        {navLink.label}
        <Icon awesome={faChevronRight} />
      </StyledNavLink>
    );
  }

  const title = currentContext?.isAdmin
    ? i18n.t("Tenant Settings")
    : i18n.t("Project Settings");

  return (
    <>
      <PageHeader
        title={
          <Title isAdmin={currentContext?.isAdmin}>
            {title}{" "}
            <span>{i18n.t("Manage {{activeLabel}}", { activeLabel })}</span>
          </Title>
        }
        tabTitleContent={{
          activeTab: activeLabel,
          pageTitle: title,
        }}
        breadcrumbs={[{ label: title }]}
        withBack={false}
      />
      <MainWrap>
        <SidebarMenu>{routes.map(renderNavLinks)}</SidebarMenu>
        <ContentWrap>
          <Content>{children}</Content>
        </ContentWrap>
      </MainWrap>
    </>
  );
}

export default connect((state) => ({
  currentContext: getCurrentContext(state),
  isOnPremApp: isOnPremApplication(state),
}))(withRouter(Settings));
