import moment from "moment";
import querystring from "query-string";

import ListActions from "modules/list/actions";
import dataFetcher from "modules/dataFetcher";
import createFormActions from "modules/form/actions";
import ModalService from "services/modal";
import api from "services/api";
import history from "services/history";
import store from "services/store";

import { BackupSchema, RestoreStatusSchema } from "utils/schemas";

import { getClusterWorkspaceEntity } from "state/clusterworkspaces/selectors/details";

export const WORKSPACE_BACKUP_LIST_MODULE = "clusterWorkspaceBackupList";
export const WORKSPACE_RESTORE_LIST_MODULE = "clusterWorkspaceRestoreList";
export const WORKSPACE_RESTORE_FORM_MODULE = "clusterWorkspaceRestoreForm";
export const WORKSPACE_BACKUP_FILTERS_MODULE = "clusterWorkspaceBackupFilters";
export const CREATE_WORKSPACE_BACKUP_FORM_MODULE =
  "clusterWorkspaceCreateBackup";
export const SCHEDULE_WORKSPACE_BACKUPS_MODULE =
  "clusterWorkspaceScheduleBackups";
export const RESTORE_FORM_MODULE = "clusterWorkspaceRestoreBackup";

export const workspaceBackupDetailsModal = new ModalService(
  "clusterWorkspaceBackupDetails"
);
export const deleteWorkspaceBackupConfirmation = new ModalService(
  "clusterWorkspaceDeleteBackup"
);
export const createWorkspaceBackupModal = new ModalService(
  "clusterWorkspaceCreateBackup"
);
export const restoreFromBackupModal = new ModalService(
  "clusterWorkspaceRestoreFromBackup"
);

function getQueryFromFilters(data) {
  const { searchTerm } = data;
  return {
    searchTerm: searchTerm || "",
  };
}

export function parseWorkspaceBackupStatuses(workspaceBackupStatuses, spec) {
  return (workspaceBackupStatuses || []).reduce((acc, backup) => {
    const { workspaceBackupConfig, actor, requestUid } = backup || {};
    const { clusterBackupRefs } = workspaceBackupConfig || {};
    if (
      workspaceBackupConfig?.backupState?.state?.toLowerCase() ===
        "scheduled" &&
      !clusterBackupRefs?.length
    ) {
      return acc;
    }
    if (
      !workspaceBackupConfig?.backupState?.state &&
      !clusterBackupRefs?.length
    ) {
      return acc;
    }
    if (
      actor?.actorType === "Schedule" &&
      !clusterBackupRefs?.length &&
      workspaceBackupConfig?.backupState?.state?.toLowerCase() !== "failed"
    ) {
      return acc;
    }

    const durationInHours =
      actor.actorType === "Schedule"
        ? spec?.config?.backupConfig?.durationInHours
        : workspaceBackupConfig?.config?.durationInHours;

    const expiryDate = moment(workspaceBackupConfig?.backupTime)
      .add(durationInHours, "hours")
      .format("MMM DD, YYYY / hh:mm A");

    if (clusterBackupRefs?.length) {
      acc.push({
        expiryDate,
        ...workspaceBackupConfig,
        requestUid: requestUid,
        uid: `${requestUid}_${workspaceBackupConfig?.backupName}`,
      });
      return acc;
    }

    return acc;
  }, []);
}

export const workspaceBackupsListActions = new ListActions({
  initialQuery() {
    return getQueryFromFilters(history.getQuery());
  },
  schema: [BackupSchema],
  async fetchData() {
    const response = await store.dispatch(fetchWorkspaceBackupStatuses());
    const items = parseWorkspaceBackupStatuses(
      response?.status?.workspaceBackupStatuses,
      response?.spec
    );
    return {
      items,
    };
  },
});

export const workspaceRestoresListActions = new ListActions({
  initialQuery() {
    return getQueryFromFilters(history.getQuery());
  },
  schema: [RestoreStatusSchema],
  async fetchData() {
    const workspace = getClusterWorkspaceEntity(store.getState());
    let response;
    try {
      response = await api.get(
        `v1/workspaces/${workspace.metadata.uid}/restore`
      );
    } catch (err) {}

    const items = response?.status?.workspaceRestoreStatuses?.reduce(
      (acc, restore) => {
        if (restore?.workspaceRestoreConfig?.clusterRestoreRefs?.length) {
          restore.workspaceRestoreConfig.clusterRestoreRefs.forEach(
            (clusterRestore) => {
              acc.push({
                ...restore,
                workspaceUid: response?.spec?.workspaceUid,
                ...clusterRestore,
              });
            }
          );
          return acc;
        }

        acc.push(restore);
        return acc;
      },
      []
    );

    return {
      items,
    };
  },
});

export function fetchWorkspaceBackupStatuses() {
  return async (dispatch) => {
    const promise = dispatch(backupStatusesFetcher.fetch());
    let response;

    try {
      response = await promise;
    } catch (error) {}

    return response;
  };
}

export const backupStatusesFetcher = dataFetcher({
  selectors: [
    "clusterWorkspaceBackuplist",
    (state) => getClusterWorkspaceEntity(state)?.metadata?.uid,
  ],
  fetchData([_, workspaceUid]) {
    //TODO: update api
    return api.get(`v1/workspaces/${workspaceUid}/backup`);
  },
});

export const workspaceBackupFetcher = dataFetcher({
  selectors: [
    "clusterWorkspaceBackup",
    (state) => getClusterWorkspaceEntity(state)?.metadata?.uid,
  ],
  schema: BackupSchema,
  async fetchData([_, workspaceUid], { backupUid, backupName }) {
    //TODO: update api
    const response = await api.get(
      `v1/workspaces/${workspaceUid}/backup?backupRequestUid=${backupUid}`
    );
    const backup = response?.status?.workspaceBackupStatuses?.[0];
    const backupStatus = backup?.backupStatusMeta?.find(
      (backup) => backup.backupName === backupName
    );
    return {
      ...backup,
      uid: `${backup.backupRequestUid}_${backupStatus.backupName}`,
      backupStatusMeta: [backupStatus],
      restoreStatusMeta: (backup.restoreStatusMeta || []).filter(
        (restore) => restore.backupName === backupStatus.backupName
      ),
    };
  },
});

export const filterFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    return Promise.resolve({
      searchTerm: query.searchTerm || "",
    });
  },
  submit: (data) => {
    const query = getQueryFromFilters(data);
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);
    store.dispatch(
      workspaceBackupsListActions.batchChangeQuery({
        module: WORKSPACE_BACKUP_LIST_MODULE,
        query,
      })
    );
  },
});

export const workspacesListFetcher = dataFetcher({
  selectors: [
    "clusterWorkspaces",
    (state) => getClusterWorkspaceEntity(state)?.metadata?.uid,
  ],
  async fetchData([_, currentWorkspaceUid], query) {
    const { search } = query || {};
    const filters = {
      sort: "name",
      filter: {
        name: {
          beginsWith: search || "",
        },
        state: "Running",
      },
    };
    const response = await api.post(
      "v1/dashboard/workspaces/metadata",
      filters
    );

    return {
      items: response?.items,
      currentWorkspaceUid,
    };
  },
});
