import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";
import history from "services/history";

import { getClusterWorkspaceEntity } from "state/clusterworkspaces/selectors/details";
import { parseWorkloadsResponse } from "state/clusterworkspaces/services/workloads";

import { getArrayFromQuery } from "utils/parsers";
import { PodSchema } from "utils/schemas";

export const PODS_MODULE = "clusterWorkspacePods";

export const podsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getClusterWorkspaceEntity(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/pod`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "pods"),
    };
  },
  initialQuery: () => {
    const query = history.getQuery();
    const namespaces = getArrayFromQuery(query?.namespaces);
    const clusters = getArrayFromQuery(query?.clusters);
    return { namespaces, clusters };
  },
  schema: [PodSchema],
});
