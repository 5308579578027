import { createSelector } from "reselect";
import fetchers, { MODULES } from "./services";

function findRepositoryByUid(repos, repoUid) {
  return repos.find((repo) => repo.metadata.uid === repoUid);
}

export const getAllReposByPackType = createSelector(
  fetchers.allRepositoriesFetcher.selector,
  (state) => state.forms.layerPack?.data?.packType,
  ({ result: repos = [] }, packType) => {
    if (!packType) return [];

    const kindFilters = packType === "helmChart" ? ["oci", "helm"] : ["pack"];

    return [
      ...(repos || [])
        .filter((repo) => kindFilters.includes(repo.kind))
        .map((repoInfo) => ({
          ...repoInfo,
          metadata: { uid: repoInfo.uid, name: repoInfo.name },
        })),
    ];
  }
);

export const getAvailableRepositories = createSelector(
  getAllReposByPackType,
  (repos) => {
    return (
      repos?.map((repo) => ({
        ...repo,
        label: repo.metadata.name,
        value: repo.metadata.uid,
      })) || []
    );
  }
);

export const arePacksLoading = createSelector(
  fetchers.clusterPacksFetcher.selector,
  (state) => state.forms?.importedPacks?.data?.clusterId,
  (result, clusterId) => result[clusterId]?.isLoading
);

export const getSelectedRepository = createSelector(
  (state) => state.forms[MODULES.PACK_MODULE]?.data?.repository,
  getAvailableRepositories,
  (repoUid, repos) => findRepositoryByUid(repos, repoUid)
);

export const getAllSystemRepositories = createSelector(
  fetchers.allRepositoriesFetcher.selector,
  ({ result: repos = [] }) => {
    return [
      ...(repos || [])
        .filter((repo) => ["oci", "helm"].includes(repo.kind))
        .map((repoInfo) => ({
          ...repoInfo,
          metadata: { uid: repoInfo.uid, name: repoInfo.name },
        })),
    ].map((repo) => ({
      ...repo,
      label: repo.metadata.name,
      value: repo.metadata.uid,
    }));
  }
);

export const getSelectedSystemRepository = createSelector(
  (state) => state.forms[MODULES.SYSTEM_MODULE]?.data?.repository,
  getAllSystemRepositories,
  (repoUid, repos) => findRepositoryByUid(repos, repoUid)
);

export const getSystemSelectedPack = createSelector(
  (state) => fetchers.systemChartNamesFetcher.selector(state)?.result?.items,
  (state) => state.forms[MODULES.SYSTEM_MODULE]?.data.pack,
  (items = [], packName) => {
    return items.find((pack) => pack.spec.name === packName);
  }
);
