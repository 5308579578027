import i18n from "i18next";
import api from "services/api/index";
import { getStoreEntity } from "services/store";

import { CloudAccountSchema } from "utils/schemas";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import notifications from "services/notifications";

import { CLOUD_ACCOUNTS_MODULE } from "state/cloudaccounts/services";

export const deleteConfirm = new ModalService("deleteCloudAccount");

export const cloudAccountsListActions = new ListActions({
  async fetchData({ continue: continueToken, limit } = {}) {
    const data = await api.get(
      `v1/cloudaccounts/summary?limit=${limit}${
        continueToken ? `&continue=${continueToken}` : ""
      }`
    );

    return Promise.resolve(data);
  },
  schema: [CloudAccountSchema],
});

export function fetchAllCloudAccounts() {
  return async (dispatch, getState) => {
    await dispatch(cloudAccountsListActions.nextPage(CLOUD_ACCOUNTS_MODULE));

    const listState = getState().list[CLOUD_ACCOUNTS_MODULE];
    if (listState.nextToken) {
      dispatch(fetchAllCloudAccounts());
    }
  };
}

export function deleteCloudAccount(guid) {
  return (dispatch) => {
    deleteConfirm.open({ guid }).then(async () => {
      const account = getStoreEntity(
        deleteConfirm.data.guid,
        CloudAccountSchema
      );
      const promise = api.delete(
        `v1/cloudaccounts/${account.kind}/${account.metadata.uid}`
      );

      dispatch({
        type: "DELETE_CLOUD_ACCOUNT",
        promise,
        guid,
      });

      try {
        await promise;
        dispatch(cloudAccountsListActions.initialize(CLOUD_ACCOUNTS_MODULE));
        notifications.success({
          message: i18n.t(
            'Account "{{accountName}}" has been deleted successfully',
            { accountName: account.metadata.name }
          ),
        });
      } catch (error) {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying do delete the account"
          ),
          description: error.message,
        });
      }
    });
  };
}
