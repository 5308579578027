import i18next from "i18next";
import moment from "moment";
import { v4 as uuid } from "uuid";

import createFormActions from "modules/form/actions";
import { createRoleBindingsFormFactory } from "modules/rolebindings";

import {
  getRawCluster,
  getCluster,
  isClusterDeleting,
  isClusterDeleted,
} from "state/cluster/selectors/details";

import store, { getStoreEntity } from "services/store";
import api from "services/api";
import ModalService from "services/modal";
import Validator from "services/validator";
import {
  Missing,
  isValidTagSelection,
  areValidKubernetesTags,
  areValidGcpTags,
  isValidNamespaceAllocation,
  isValidRegexNamespace,
  areValidIPTags,
  areValidIPRangesTags,
  ApplyIf,
} from "services/validator/rules";
import notifications from "services/notifications";

import { parseTagsForInput } from "utils/parsers";
import { formatTags } from "utils/presenters";
import { DEFAULT_SCHEDULE_OPTIONS, BEGINNING_OF_TIME } from "utils/constants";
import { round } from "utils/number";

import { scanFormActions } from "./scan";
import { scheduleBackupsFormAction } from "state/backups/actions/schedule";
import { fetchAwsCloudConfigParams } from "./nodes";
import { SCHEDULE_BACKUPS_MODULE } from "state/backups/services";
import { getClusterCloudConfig } from "../selectors/nodes";
import { azureSubscriptionFetcher } from "../services/create";
import { sshKeysFetcher } from "state/sshKeys/services";
import { SSHKeysSchema } from "utils/schemas";
import {
  parseRoleBindingsFormData,
  parseRoleBindingsResponse,
} from "state/cluster/selectors/rolebindings";
import { clusterNamespacesConfigFetcher } from "state/cluster/services/workloads/namespaces";
import { getClusterNamespacesPayload } from "state/cluster/actions/workloads";
import { tencentSshKeyPairsFetcher } from "state/cluster/services";
import { refreshCluster } from "state/cluster/actions/details";
import { getHostClusterConfig } from "state/cluster/selectors/create";

//

const EDIT_CLUSTER_MODULE = "editCluster";
const azureReservedTags = ["microsoft", "azure", "windows"];

export const validator = new Validator();
function AzureRule(validationFn) {
  const cluster = getRawCluster(store.getState());
  const cloudType = cluster?.spec?.cloudType;
  return (value, key, data) => {
    if (cloudType === "azure") {
      return validationFn(value, key, data);
    }
    return false;
  };
}
function createValidator() {
  validator.removeRules();
  validator.addRule(["name"], Missing());
  validator.addRule(
    ["tags"],
    AzureRule(isValidTagSelection({ reservedTags: azureReservedTags }))
  );
  validator.addRule(["tags"], (value) => {
    const cluster = getRawCluster(store.getState());
    if (cluster.spec.cloudType === "gcp") {
      return areValidGcpTags()(value);
    }
    return areValidKubernetesTags({ onlySpectroTags: true })(value);
  });
}

createValidator();
export const editClusterFormActions = createFormActions({
  validator,
  submit,
  init: () => {
    const { metadata } = getCluster(store.getState());
    const { name, annotations, labels } = metadata;
    return Promise.resolve({
      name: name,
      description: annotations?.description || "",
      tags: parseTagsForInput(labels),
    });
  },
});

export const fargatePoliciesFormActions = createFormActions({
  init: () => {
    const cluster = getCluster(store.getState());
    const fargateProfiles = cluster.spec.cloudConfig.spec.fargateProfiles || [];
    const vpcid = cluster.spec.cloudConfig.spec.clusterConfig.vpcId;
    store.dispatch(fetchAwsCloudConfigParams());

    return Promise.resolve({
      vpcid,
      fargateProfiles: fargateProfiles.map((profile) => ({
        ...profile,
        selectors: profile.selectors.map((selector) => ({
          ...selector,
          labels: parseTagsForInput(selector.labels),
        })),
      })),
    });
  },
  submit(data) {
    const cluster = getRawCluster(store.getState());
    return api.put(
      `v1/cloudconfigs/${cluster.spec.cloudType}/${cluster.spec.cloudConfigRef.uid}/fargateProfiles`,
      {
        fargateProfiles: data.fargateProfiles.map((profile) => ({
          ...profile,
          selectors: profile.selectors.map((selector) => ({
            ...selector,
            labels: formatTags(selector.labels),
          })),
        })),
      }
    );
  },
});

export const clusterMachineManagementFormActions = createFormActions({
  submit: async (data) => {
    const { metadata } = getCluster(store.getState());

    const getScheduleValue = () => {
      if (data.osPatchingScheduleOption === "never") {
        return undefined;
      }

      return data.osPatchingScheduleOption === "custom"
        ? data.osPatchingSchedule || "* * * * *"
        : data.osPatchingScheduleOption;
    };

    const promise = api.patch(
      `v1/spectroclusters/${metadata.uid}/clusterConfig/osPatch`,
      {
        osPatchConfig: {
          onDemandPatchAfter: data.onDemandPatchAfter
            ? moment().add(10, "minutes")
            : undefined,
          schedule: getScheduleValue(),
          patchOnBoot: data.patchOnBoot,
        },
      }
    );

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the machine management config"
        ),
        description: err?.message,
      });
      return promise;
    }
  },
  init: () => {
    const { spec } = getCluster(store.getState());
    const { clusterConfig } = spec;

    const osPatchConfig =
      clusterConfig?.machineManagementConfig?.osPatchConfig || {};

    const isCustomSchedule = !DEFAULT_SCHEDULE_OPTIONS.map(
      (option) => option.value
    ).includes(osPatchConfig.schedule);

    const getScheduleOption = () => {
      if (!osPatchConfig.schedule) {
        return "never";
      }

      if (isCustomSchedule) {
        return "custom";
      }

      return osPatchConfig.schedule;
    };

    const isActiveOnDemandPatch =
      osPatchConfig.onDemandPatchAfter !== BEGINNING_OF_TIME &&
      moment().diff(moment(osPatchConfig.onDemandPatchAfter)) < 0;

    return Promise.resolve({
      patchOnBoot: osPatchConfig.patchOnBoot,
      onDemandPatchAfter: isActiveOnDemandPatch,
      persistedOnDemandPatchAfter: isActiveOnDemandPatch,
      timeUntilPatch:
        isActiveOnDemandPatch &&
        moment(osPatchConfig.onDemandPatchAfter).format("HH:mm"),
      osPatchingScheduleOption: getScheduleOption(),
      osPatchingSchedule: isCustomSchedule ? osPatchConfig.schedule : undefined,
    });
  },
});

export const clusterCloudFormActions = createFormActions({
  async init() {
    const cloudConfig = getClusterCloudConfig(store.getState());
    const cluster = getRawCluster(store.getState());
    const region = cloudConfig?.spec?.clusterConfig?.region;
    const cloudAccountUid = cloudConfig?.spec?.cloudAccountRef?.uid;

    if (["azure", "aks"].includes(cluster.spec.cloudType)) {
      store.dispatch(azureSubscriptionFetcher.fetch());
    }

    if (cluster.spec.cloudType === "tke") {
      await store.dispatch(
        tencentSshKeyPairsFetcher.fetch({ region, cloudAccountUid })
      );
    }

    await store.dispatch(sshKeysFetcher.fetch());

    const keys = sshKeysFetcher.selector(store.getState()).result;
    return {
      sshKeys: (cloudConfig?.spec?.clusterConfig?.sshKeys || []).map((key) => {
        const existingKey = keys.find(
          (sshKey) => sshKey.spec.publicKey === key
        );
        if (existingKey) {
          return existingKey.guid;
        }

        return key;
      }),
      ntpServers: cloudConfig.spec?.clusterConfig?.ntpServers || [],
    };
  },
  async submit(data) {
    const cloudConfig = getClusterCloudConfig(store.getState());
    const cloudType = cloudConfig.kind;
    if (!["vsphere", "libvirt"].includes(cloudType)) {
      return Promise.resolve();
    }

    const promise = api.put(
      `v1/cloudconfigs/${cloudType}/${cloudConfig.metadata.uid}/clusterConfig`,
      {
        clusterConfig: {
          ...(cloudConfig.spec.clusterConfig || {}),
          ...data,
          sshKeys: getStoreEntity(data.sshKeys, [SSHKeysSchema])
            .map((key, index) => {
              if (key === null) {
                return data.sshKeys[index];
              }
              return key?.spec?.publicKey;
            })
            .filter(Boolean),
        },
      }
    );
    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong when trying to update the cluster configuration"
        ),
        description: err?.message,
      });
      return promise;
    }
  },
});

function getEditRoleBindingsPayload(data) {
  const clusterRoleBindings = parseRoleBindingsFormData(
    data.clusterRoleBindings,
    "ClusterRoleBinding"
  );
  const roleBindings = parseRoleBindingsFormData(
    data.roleBindings,
    "RoleBinding"
  );
  const [clusterRbacs, rbacs] =
    editRoleBindingsForm.fetchers.clusterRbacsFetcher.selector(store.getState())
      ?.result || [];
  const rbacsMetadata = rbacs?.metadata?.uid
    ? { metadata: { uid: rbacs.metadata.uid } }
    : {};
  const clusterRbacsMetadata = clusterRbacs?.metadata?.uid
    ? { metadata: { uid: clusterRbacs.metadata.uid } }
    : {};

  const payload = {
    rbacs: [
      clusterRoleBindings?.length > 0 && {
        ...clusterRbacsMetadata,
        spec: {
          bindings: clusterRoleBindings,
        },
      },
      roleBindings?.length > 0 && {
        ...rbacsMetadata,
        spec: {
          bindings: roleBindings,
        },
      },
    ].filter(Boolean),
  };

  return payload;
}

const roleBindingsValidator = new Validator();
roleBindingsValidator.addRule(["namespaces"], isValidNamespaceAllocation());
roleBindingsValidator.addRule("namespace", isValidRegexNamespace());

const nestedClusterValidator = new Validator();
nestedClusterValidator.addRule("externalIPs", areValidIPTags());
nestedClusterValidator.addRule(
  "loadBalancerSourceRanges",
  areValidIPRangesTags({
    genericMessage: () => i18next.t("One or more source ranges are invalid"),
  })
);
nestedClusterValidator.addRule(
  "ingressHost",
  ApplyIf((value, key, data) => {
    return data.clusterEndpointType === "Ingress";
  }, Missing())
);

export const nestedClusterFormActions = createFormActions({
  init() {
    const cluster = getCluster(store.getState());
    const { isHostCluster, clusterEndpoint } =
      cluster?.spec?.clusterConfig?.hostClusterConfig || {};
    const loadBalancerConfig = clusterEndpoint?.config?.loadBalancerConfig;
    const ingressConfig = clusterEndpoint?.config?.ingressConfig;

    if (!isHostCluster) {
      return Promise.resolve({
        isHostCluster: false,
        clusterEndpointType: "LoadBalancer",
        externalIPs: [],
        loadBalancerSourceRanges: [],
        externalTrafficPolicy: "cluster",
        ingressHost: "",
      });
    }

    return Promise.resolve({
      isHostCluster,
      clusterEndpointType: clusterEndpoint?.type || "LoadBalancer",
      externalIPs: loadBalancerConfig?.externalIPs || [],
      loadBalancerSourceRanges:
        loadBalancerConfig?.loadBalancerSourceRanges || [],
      externalTrafficPolicy:
        loadBalancerConfig?.externalTrafficPolicy || "cluster",
      ingressHost: ingressConfig?.host?.replace("*.", "") || "",
    });
  },
  validator: nestedClusterValidator,
  submit: async (data) => {
    const clusterUid = getCluster(store.getState()).metadata.uid;
    const hostClusterConfig = getHostClusterConfig(data);
    const promise = api.patch(
      `v1/spectroclusters/${clusterUid}/clusterConfig/hostCluster`,
      {
        hostClusterConfig,
      }
    );

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while updating the host cluster"
        ),
        description: error?.message,
      });
      return Promise.reject();
    }
  },
});

export const editRoleBindingsFormActions = createFormActions({
  validator: roleBindingsValidator,
  async init() {
    const rbacsList = await store.dispatch(
      editRoleBindingsForm.fetchers.clusterRbacsFetcher.fetch()
    );
    const namespaces = await store.dispatch(
      clusterNamespacesConfigFetcher.fetch()
    );
    const clusterRbacs =
      (rbacsList || []).find(
        (rbac) => rbac.spec?.bindings?.[0]?.type === "ClusterRoleBinding"
      )?.spec?.bindings || [];
    const rbacs =
      (rbacsList || []).find(
        (rbac) => rbac.spec?.bindings?.[0]?.type === "RoleBinding"
      )?.spec?.bindings || [];

    const clusterRoleBindings = parseRoleBindingsResponse(clusterRbacs);
    const roleBindings = parseRoleBindingsResponse(rbacs);
    const namespacesFormData = (namespaces || [])
      .map((ns) => {
        const memoryInMb = ns.spec?.resourceAllocation?.memoryMiB;
        const cpuCores = ns.spec?.resourceAllocation?.cpuCores;

        return {
          guid: uuid(),
          uid: ns.metadata?.uid,
          namespaceName: ns.metadata?.name,
          isRegex: ns.spec.isRegex,
          alocMemory: memoryInMb && round(memoryInMb / 1024, 2),
          alocCpu: cpuCores,
        };
      })
      .sort((ns1, ns2) => ns1.isRegex - ns2.isRegex);

    return Promise.resolve({
      clusterRoleBindings,
      roleBindings,
      namespaces: namespacesFormData,
      namespace: "",
    });
  },
  async submit(data) {
    const clusterUid = getCluster(store.getState())?.metadata?.uid;
    const rbacPayload = getEditRoleBindingsPayload(data);
    const namespacesPayload = getClusterNamespacesPayload(data);

    const namespacesPromise = api.put(
      `v1/spectroclusters/${clusterUid}/config/namespaces`,
      namespacesPayload
    );

    try {
      await namespacesPromise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while updating the namespaces"
        ),
        description: err?.message,
      });
      return Promise.reject();
    }

    const rbacPromise = api.put(
      `v1/spectroclusters/${clusterUid}/config/rbacs`,
      rbacPayload
    );

    try {
      await rbacPromise;
    } catch (err) {
      notifications.error({
        message: i18next.t(
          "Something went wrong while updating the role bindings"
        ),
        description: err?.message,
      });
      return Promise.reject();
    }
  },
});

export const editRoleBindingsForm = createRoleBindingsFormFactory({
  formActions: editRoleBindingsFormActions,
  formModuleName: "editRoleBindings",
  getClusterUid: (state) => getCluster(state)?.metadata?.uid,
});

export const clusterEditModal = new ModalService();

export const SETTINGS_MENU_ITEMS_FORMS = {
  basicinfo: {
    formActions: editClusterFormActions,
    module: EDIT_CLUSTER_MODULE,
  },
  machinemanagement: {
    formActions: clusterMachineManagementFormActions,
    module: "machineManagement",
  },
  fargateProfiles: {
    formActions: fargatePoliciesFormActions,
    module: "fargateProfiles",
  },
  scans: {
    formActions: scanFormActions,
    module: "scan-policies",
  },
  schedulebackups: {
    formActions: scheduleBackupsFormAction,
    module: SCHEDULE_BACKUPS_MODULE,
  },
  clusterConfiguration: {
    formActions: clusterCloudFormActions,
    module: "clusterCloudConfig",
  },
  rbac: {
    formActions: editRoleBindingsFormActions,
    module: "editRoleBindings",
  },
  nested: {
    formActions: nestedClusterFormActions,
    module: "nestedClusters",
  },
};

export function openClusterEditModal({
  menuItem = "basicinfo",
  activeTab,
  disabled,
} = {}) {
  return (dispatch) => {
    if (disabled) {
      return;
    }
    clusterEditModal.open({ menuItem, activeTab });
    dispatch(onSettingsInit());
  };
}

export function onSettingsInit() {
  return (dispatch) => {
    const { menuItem } = clusterEditModal.data;
    const { formActions, module } = SETTINGS_MENU_ITEMS_FORMS[menuItem] || {};

    dispatch(formActions.init({ module }));
  };
}

export const importProcedureModal = new ModalService("importProcedureModal");

export function openImportProcedureModal() {
  return (_, getState) => {
    const state = getState();
    const cluster = getRawCluster(state);

    const clusterLabels = cluster?.metadata?.labels;
    const ignoreImport = clusterLabels?.imported === "false";

    if (ignoreImport || isClusterDeleting(state) || isClusterDeleted(state)) {
      return;
    }
    importProcedureModal.open();
  };
}

export async function submit(data) {
  const cluster = getRawCluster(store.getState());

  const promise = api.patch(
    `v1/spectroclusters/${cluster.metadata.uid}/metadata`,
    {
      metadata: {
        labels: formatTags(data.tags),
        annotations: { description: data.description },
      },
    }
  );
  try {
    await promise;
  } catch (err) {
    notifications.error({
      message: i18next.t("Something went wrong while updating the cluster"),
      description: err.message,
    });
  }
  return promise;
}

export const importUpgradeConfirmModal = new ModalService();

export function upgradeClusterImportToFullPermissions() {
  return (dispatch, getState) => {
    const clusterUid = getRawCluster(getState())?.metadata?.uid;

    importUpgradeConfirmModal.open().then(async () => {
      const promise = api.patch(
        `v1/spectroclusters/${clusterUid}/import/upgrade`
      );

      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: error.message,
        });
        return;
      }

      await dispatch(refreshCluster());
      importProcedureModal.open();
    });
  };
}
