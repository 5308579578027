import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";

import { getClusterWorkspaceEntity } from "state/clusterworkspaces/selectors/details";
import { parseWorkloadsResponse } from "state/clusterworkspaces/services/workloads";

import { DeploymentSchema } from "utils/schemas";

export const DEPLOYMENTS_MODULE = "clusterWorkspaceDeployments";

export const deploymentsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getClusterWorkspaceEntity(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/deployment`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "deployments"),
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [DeploymentSchema],
});
