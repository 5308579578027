import store from "services/store";
import api from "services/api";

import Validator from "services/validator";
import { Missing, isKubernetesName, MaxLength } from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  awsPolicesFetcher,
  cloudAccountFetcher,
  cloudAccountFormModal,
  CLOUD_ACCOUNTS_MODULE,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";
import { getAwsCloudAccountPayload } from "state/cloudaccounts/selectors";

const validator = new Validator();
validator.addRule(["name"], Missing());
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

validator.addRule(["accessKey", "secretKey"], (value, key, data) => {
  if (data.credentialType === "secret") {
    return Missing()(value, key, data);
  }
  return false;
});

validator.addRule(["arn", "externalId", "accountId"], (value, key, data) => {
  if (data.credentialType === "sts") {
    return Missing()(value, key, data);
  }
  return false;
});

async function submit(data) {
  const { credentialType, arn, externalId, accountId, policyARNs } = data;
  const commonData = {
    credentialType,
    policyARNs,
  };

  const getCredentials = () => {
    if (credentialType === "sts") {
      return {
        ...commonData,
        sts: {
          arn,
          externalId,
          accountId,
        },
      };
    }

    return {
      ...commonData,
      accessKey: data.accessKey.trim(),
      secretKey: data.secretKey.trim(),
    };
  };

  const payload = {
    metadata: {
      name: data.name,
      annotations: {
        description: data.description,
      },
    },
    spec: getCredentials(),
  };

  return store.dispatch(createAccount("aws", payload));
}

async function init() {
  let data;
  let policyARNs = [];
  const stsData = await api.get("v1/clouds/aws/account/sts");

  if (cloudAccountFormModal.data?.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });

    const payload = {
      secret: {
        accessKey: data?.spec?.accessKey,
        secretKey: data?.spec?.secretKey,
      },
      sts: {
        credentialType: data?.spec?.credentialType,
        sts: {
          arn: data?.spec?.sts?.arn,
          externalId: stsData?.accountId,
          accountId: stsData?.externalId,
        },
      },
    };

    policyARNs = [...(data?.spec?.policyARNs || [])];

    if (policyARNs.length > 0) {
      store.dispatch(
        awsPolicesFetcher.fetch({
          cloudAccountUid: cloudAccountFormModal.data.uid,
          payload,
        })
      );
    }
  }

  return Promise.resolve({
    name: data?.metadata?.name || "",
    accessKey: data?.spec?.accessKey || "",
    secretKey: data?.spec?.secretKey || "",
    description: data?.metadata?.annotations?.description || "",
    credentialType: data?.spec?.credentialType || "secret",
    arn: data?.spec?.sts?.arn || "",
    accountId: stsData?.accountId,
    externalId: stsData?.externalId,
    policyARNs,
    enablePolicies: !!policyARNs.length > 0,
  });
}

export function onIAMPoliciesToggle(value) {
  return (dispatch, getState) => {
    dispatch(
      awsAccountFormActions.onChange({
        name: "enablePolicies",
        value,
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );

    if (value) {
      const payload = getAwsCloudAccountPayload(getState());
      dispatch(
        awsPolicesFetcher.fetch({
          payload,
          cloudAccountUid: cloudAccountFormModal?.data?.uid,
        })
      );
    }
  };
}

export const awsAccountFormActions = createActions({
  submit,
  validator,
  init,
});
