import ModalService from "services/modal";
import api from "services/api";

import dataFetcher from "modules/dataFetcher";
import { ClusterSchema } from "utils/schemas";

export const FORM_MODULE = "clusterWorkspace";
export const WIZARD_MODULE = "clusterWorkspace";

export const cancelCreateClusterWorkspaceModal = new ModalService();

export const clusterWorkspacesFetcher = dataFetcher({
  selectors: ["cluster", "workspaces", "meta"],
  async fetchData() {
    const response = await api.get("v1/dashboard/workspaces");
    return (response?.items || []).map((item) => ({
      label: item.metadata?.name,
      value: item.metadata?.uid,
    }));
  },
});

export const workspacesFilteredClustersFetcher = dataFetcher({
  selectors: ["workspaces", "spectroclusters"],
  schema: [ClusterSchema],
  async fetchData() {
    const response = await api.get(
      "v1/dashboard/spectroclusters/filters/workspace"
    );

    return response?.items || [];
  },
});
