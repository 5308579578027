import ModalService from "services/modal";
import api from "services/api";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";

import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";
import { ClusterProfileSchema, ClusterSchema } from "utils/schemas";

import { mapClusterProfilesQuery } from "state/clusterprofile/actions/listing";

export const clusterDetailsModal = new ModalService();
export const scheduleUpdatesModal = new ModalService();
export const SCHEDULE_UPDATES_MODULE = "scheduleUpdates";
export const MAP_CLUSTERS_LIST = "mapClusters";

export function onClusterClick(cluster) {
  return () => {
    clusterDetailsModal.open({ cluster });
  };
}

export function scheduleUpdates(selectedClusters) {
  return (dispatch) => {
    scheduleUpdatesModal.open().then(() => {
      return dispatch(
        scheduleUpdatesFormActions.submit({ module: SCHEDULE_UPDATES_MODULE })
      );
    });
    dispatch(
      scheduleUpdatesFormActions.init({ module: SCHEDULE_UPDATES_MODULE })
    );
  };
}

export function initMapViewData() {
  return async (dispatch) => {
    dispatch({ type: "MAP_VIEW_LOADING", isLoading: true });
    await dispatch(fetchAllProfiles({ init: true }));
    dispatch(fetchAllClusters({ init: true }));
  };
}

export const profileListActions = new ListActions({
  initialQuery() {
    return {
      search: "",
      cloudTypes: [],
      profileTypes: [],
      sortField: "",
      sortOrder: "",
      limit: 20,
    };
  },
  fetchData(query) {
    const { offset, limit, continue: continueToken, ...rest } = query;
    const payload = mapClusterProfilesQuery(rest);
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    return api.post(
      `v1/dashboard/clusterprofiles?limit=${limit}${continueQueryParam}`,
      payload
    );
  },
  schema: [ClusterProfileSchema],
});

export const mapClustersListActions = new ListActions({
  initialQuery: () => ({
    limit: 20,
  }),
  fetchData(query) {
    const { continue: continueToken, limit } = query;
    const filters = {
      filter: {
        environment: [],
        clusterName: { contains: "" },
        isDeleted: false,
      },
    };

    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    return api.post(
      `v1/dashboard/spectroclusters?limit=${limit}${continueQueryParam}`,
      filters
    );
  },
  schema: [ClusterSchema],
});

export function fetchAllProfiles({ init } = {}) {
  return async (dispatch, getState) => {
    const MODULE = "scheduleUpdatesProfiles";

    if (init) {
      await dispatch(profileListActions.initialize(MODULE));
    } else {
      await dispatch(profileListActions.nextPage(MODULE));
    }

    const newListState = getState().list[MODULE];
    if (newListState.nextToken) {
      dispatch(fetchAllProfiles());
    }
  };
}

export function fetchAllClusters({ init } = {}) {
  return async (dispatch, getState) => {
    if (init) {
      dispatch({ type: "MAP_VIEW_LOADING", isLoading: true });
      await dispatch(mapClustersListActions.initialize(MAP_CLUSTERS_LIST));
    } else {
      await dispatch(mapClustersListActions.nextPage(MAP_CLUSTERS_LIST));
    }

    const newListState = getState().list[MAP_CLUSTERS_LIST];
    if (newListState.nextToken) {
      await dispatch(fetchAllClusters());
    } else {
      dispatch({ type: "MAP_VIEW_LOADING", isLoading: false });
    }
  };
}

const validator = new Validator();

validator.addRule(
  ["clusterProfile", "profileVersion", "scheduleDay", "scheduleHour"],
  Missing()
);

export const scheduleUpdatesFormActions = createFormActions({
  validator,
  init: () => {
    return Promise.resolve({
      clusterProfile: null,
      profileVersion: null,
      scheduleDay: "",
      scheduleHour: "",
    });
  },
  submit() {},
});

export function onFieldChange(name, value) {
  return (dispatch) => {
    dispatch(
      scheduleUpdatesFormActions.onChange({
        name,
        value,
        module: SCHEDULE_UPDATES_MODULE,
      })
    );

    if (name === "clusterProfile") {
      dispatch(
        scheduleUpdatesFormActions.onChange({
          name: "profileVersion",
          value: null,
          module: SCHEDULE_UPDATES_MODULE,
        })
      );
    }
  };
}
