import { DatacenterSchema } from "utils/schemas";
import api from "services/api";

import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import ModalService from "services/modal";
import notifications from "services/notifications";
import i18n from "i18next";

export const OVERLORD_MODULE = "overlord";
export const OVERLORD_ACCOUNT_MODULE = "overlordAccount";
export const OVERLORD_CLOUD_MODULE = "overlordCloud";
export const OVERLORD_MIGRATE_MODULE = "overlordMigrate";

export const overlordConfigureModal = new ModalService("overlordConfigure");
export const deleteOverlordConfirmation = new ModalService("deleteOverlord");
export const setOverlordNodesModal = new ModalService("setOverlordNodesModal");
export const resetOverlordConfirmation = new ModalService("resetOverlord");
export const migrateOverlordModal = new ModalService("migrateOverlord");

function getOverlordUid() {
  return overlordConfigureModal?.data?.uid || setOverlordNodesModal?.data?.uid;
}

// TODO These are the same with /clusters/services
export const datacentersFetcher = dataFetcher({
  selectors: ["datacenters"],
  async fetchData() {
    try {
      const response = await api.get(
        `v1/overlords/vsphere/${getOverlordUid()}/properties/datacenters`
      );
      return response.items;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the datacenters"
        ),
        description: err?.message,
      });
    }
  },
  schema: [DatacenterSchema],
});

export const propertiesFetcher = keyedDataFetcher({
  selectors: [(state) => state.forms[OVERLORD_CLOUD_MODULE].data.datacenter],
  async fetchData([datacenter, computecluster]) {
    try {
      const response = api.get(
        `v1/overlords/vsphere/${getOverlordUid()}/properties/computecluster/resources?datacenter=${datacenter}&computecluster=${computecluster}`
      );
      const data = await response;
      return data.computecluster;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the properties"
        ),
        description: err?.message,
      });
    }
  },
});
