import store from "services/store";
import withPolling from "utils/withPolling";
import { getCluster, isBrownfield } from "state/cluster/selectors/details";
import {
  fetchClusterStatus,
  fetchClusterNotifications,
  fetchLogStatus,
} from "state/cluster/actions/details";
import { fetchClusterProfileNotifications } from "state/clusterprofile/actions/details";
import { getNodeStatus, getNodePools } from "state/cluster/selectors/nodes";
import { fetchClusterNodes } from "state/cluster/actions/nodes";
import { overlordsListActions } from "state/overlord/actions/list";
import { OVERLORD_MODULE } from "state/overlord/services/list";
import {
  eventListActions,
  clusterErrorFetcher,
  lastClusterModificationFetcher,
} from "state/cluster/services";
import { refreshClustersList } from "state/cluster/actions/list/clusters";
import {
  backupsListActions,
  restoresListActions,
  BACKUP_LIST_MODULE,
  RESTORE_LIST_MODULE,
} from "state/backups/services";
import { refreshRegistries } from "state/packregistries/actions/list";

import { fetchNamespacesConsumption as clusterNsConsumption } from "state/cluster/actions/workloads";
import { fetchNamespacesConsumption as workspaceNsConsumption } from "state/workspaces/actions/details";
import { fetchNamespacesConsumption as clusterWorkspaceNsConsumption } from "state/clusterworkspaces/actions/details";

import { MINUTE_IN_MILLISECONDS } from "./date";
import { workspacesWorkloadsModules as workspaceWorkloadsActions } from "state/workspaces/services/workloads";
import { clusterWorkspacesWorkloadsModules as clusterWorkspaceWorkloadsActions } from "state/clusterworkspaces/services/workloads";
import { workloadsModules as clusterWorkloadsActions } from "state/cluster/actions/workloads";
import { edgeMachinesListActions } from "state/cluster/actions/list/edgemachines";
import { EDGE_MACHINES_MODULE } from "state/cluster/services/edgemachines";

import {
  workspaceBackupsListActions,
  workspaceRestoresListActions,
  WORKSPACE_BACKUP_LIST_MODULE,
  WORKSPACE_RESTORE_LIST_MODULE,
} from "state/workspaces/services/backups";
import {
  workspaceBackupsListActions as clusterWorkspaceBackupsListActions,
  workspaceRestoresListActions as clusterWorkspaceRestoresListActions,
  WORKSPACE_BACKUP_LIST_MODULE as CLUSTER_WORKSPACE_BACKUP_LIST_MODULE,
  WORKSPACE_RESTORE_LIST_MODULE as CLUSTER_WORKSPACE_RESTORE_LIST_MODULE,
} from "state/clusterworkspaces/services/backups";
import {
  nestedClustersListActions,
  NESTED_CLUSTERS_LIST_MODULE,
} from "state/cluster/actions/list/nestedclusters";

const getActivatedCluster = async () => {
  let state = store.getState();
  let cluster = getCluster(state);
  await store.dispatch(fetchClusterStatus(cluster.metadata.uid));

  return Promise.reject();
};

export const pollClusterStatus = withPolling(getActivatedCluster, 5000);

const getActiveNodes = async () => {
  const nodePoolsNodes = await store.dispatch(fetchClusterNodes());
  let state = store.getState();
  const isClusterBrownField = isBrownfield(state);
  const clusterNodes = nodePoolsNodes.reduce(
    (accumulator, { items }) => [...accumulator, ...items],
    []
  );

  const activeNodes = clusterNodes.filter((node) => {
    const nodeStatus = getNodeStatus(node);
    return nodeStatus === "running";
  });

  const nodePoolsSize = getNodePools(store.getState()).reduce(
    (accumulator, nodePool) => accumulator + nodePool.size,
    0
  );

  const nodes = getNodePools(store.getState()).reduce(
    (accumulator, nodePool) => accumulator + nodePool.nodes.length,
    0
  );

  let shouldStop = nodes === activeNodes.length && nodes === nodePoolsSize;
  if (isClusterBrownField) {
    shouldStop = nodes && shouldStop;
  }

  if (!shouldStop) {
    throw new Error("Not all nodes are active");
  }

  return Promise.resolve();
};

export const pollNodes = withPolling(getActiveNodes, 5000);

const getClusterEvents = () => {
  const listState = store.getState().list.clusterevents;
  if (listState.nextToken === undefined) {
    store.dispatch(eventListActions.fetchItems("clusterevents"));
  }
  store.dispatch(eventListActions.nextPage("clusterevents"));

  return Promise.reject();
};

export const pollClusterEvents = withPolling(getClusterEvents, 7000);

const getDatacenters = () => {
  store.dispatch(overlordsListActions.refreshItems(OVERLORD_MODULE));

  return Promise.reject();
};

export const pollDatacenters = withPolling(getDatacenters, 5000);
export const pollClusterNotifications = withPolling(() => {
  store.dispatch(fetchClusterNotifications());
  return Promise.reject();
}, 5000);

export const pollClusterProfileNotifications = withPolling(() => {
  store.dispatch(fetchClusterProfileNotifications());
  return Promise.reject();
}, 5000);

export const poolClusterErrors = withPolling(() => {
  store.dispatch(
    clusterErrorFetcher.fetch({
      interval: "5 minute",
      status: ["Error"],
    })
  );

  return Promise.reject();
}, 20000);

export const pollSpectroClusters = withPolling(() => {
  store.dispatch(refreshClustersList());
  return Promise.reject();
}, 20000);

export const pollAppliances = withPolling(() => {
  store.dispatch(edgeMachinesListActions.refreshItems(EDGE_MACHINES_MODULE));
  return Promise.reject();
}, 10000);

export const pollClusterLogStatus = withPolling(
  () => store.dispatch(fetchLogStatus()),
  5000
);

export const pollBackupList = withPolling(async () => {
  store.dispatch(backupsListActions.fetchItems(BACKUP_LIST_MODULE));
  return Promise.reject();
}, 10000);

export const pollRestoreList = withPolling(async () => {
  store.dispatch(restoresListActions.fetchItems(RESTORE_LIST_MODULE));
  return Promise.reject();
}, 10000);

export const pollLastClusterModification = withPolling(async () => {
  store.dispatch(lastClusterModificationFetcher.fetch());
  return Promise.reject();
}, 20000);

export const pollRegistries = withPolling(() => {
  store.dispatch(refreshRegistries());
  return Promise.reject();
});

export const pollNestedClusters = withPolling(() => {
  store.dispatch(
    nestedClustersListActions.refreshItems(NESTED_CLUSTERS_LIST_MODULE)
  );
  return Promise.reject();
}, 20000);

// Workspace Workloads Tasks

export const pollWorkspacesNamespacesConsumption = withPolling(() => {
  store.dispatch(workspaceNsConsumption());
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 5);

export const pollWorkspacesNamespaces = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.namespaces;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesPods = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.pods;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesDeployments = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.deployments;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesDaemonsets = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.daemonsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesStatefulsets = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.statefulsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesJobs = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.jobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesCronjobs = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.cronjobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesBackupList = withPolling(async () => {
  store.dispatch(
    workspaceBackupsListActions.fetchItems(WORKSPACE_BACKUP_LIST_MODULE)
  );
  return Promise.reject();
}, 10000);

export const pollWorkspacesRestoreList = withPolling(async () => {
  store.dispatch(
    workspaceRestoresListActions.fetchItems(WORKSPACE_RESTORE_LIST_MODULE)
  );
  return Promise.reject();
}, 10000);

export const pollClusterWorkspacesBackupList = withPolling(async () => {
  store.dispatch(
    clusterWorkspaceBackupsListActions.fetchItems(
      CLUSTER_WORKSPACE_BACKUP_LIST_MODULE
    )
  );
  return Promise.reject();
}, 10000);

export const pollClusterWorkspacesRestoreList = withPolling(async () => {
  store.dispatch(
    clusterWorkspaceRestoresListActions.fetchItems(
      CLUSTER_WORKSPACE_RESTORE_LIST_MODULE
    )
  );
  return Promise.reject();
}, 10000);

export const pollWorkspacesRoleBindings = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.rolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesClusterRoleBindings = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.clusterrolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const workspaceWorkloadPollersByTab = {
  namespaces: [pollWorkspacesNamespaces, pollWorkspacesNamespacesConsumption],
  pods: [pollWorkspacesPods],
  deployments: [pollWorkspacesDeployments],
  daemonsets: [pollWorkspacesDaemonsets],
  statefulsets: [pollWorkspacesStatefulsets],
  jobs: [pollWorkspacesJobs],
  cronjobs: [pollWorkspacesCronjobs],
  rolebindings: [pollWorkspacesRoleBindings],
  clusterrolebindings: [pollWorkspacesClusterRoleBindings],
};

// Cluster Workloads Tasks

export const pollClusterNamespacesConsumption = withPolling(() => {
  store.dispatch(clusterNsConsumption());
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 5);

export const pollClusterNamespaces = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.namespaces;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterPods = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.pods;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterDeployments = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.deployments;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterDaemonsets = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.daemonsets;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterStatefulsets = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.statefulsets;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterJobs = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.jobs;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterCronjobs = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.cronjobs;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterRoleBindings = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.rolebindings;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterRoles = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.clusterrolebindings;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const clusterWorkloadPollersByTab = {
  namespaces: [pollClusterNamespaces, pollClusterNamespacesConsumption],
  pods: [pollClusterPods],
  deployments: [pollClusterDeployments],
  daemonsets: [pollClusterDaemonsets],
  statefulsets: [pollClusterStatefulsets],
  jobs: [pollClusterJobs],
  cronjobs: [pollClusterCronjobs],
  rolebindings: [pollClusterRoleBindings],
  clusterrolebindings: [pollClusterRoles],
};

// Cluster Workspace Workloads Tasks

export const pollClusterWorkspacesNamespacesConsumption = withPolling(() => {
  store.dispatch(clusterWorkspaceNsConsumption());
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 5);

export const pollClusterWorkspacesNamespaces = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.namespaces;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesPods = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.pods;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesDeployments = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.deployments;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesDaemonsets = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.daemonsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesStatefulsets = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.statefulsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesJobs = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.jobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesCronjobs = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.cronjobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesRoleBindings = withPolling(() => {
  const { listActions, module } = clusterWorkspaceWorkloadsActions.rolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterWorkspacesClusterRoleBindings = withPolling(() => {
  const { listActions, module } =
    clusterWorkspaceWorkloadsActions.clusterrolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const clusterWorkspaceWorkloadPollersByTab = {
  namespaces: [
    pollClusterWorkspacesNamespaces,
    pollClusterWorkspacesNamespacesConsumption,
  ],
  pods: [pollClusterWorkspacesPods],
  deployments: [pollClusterWorkspacesDeployments],
  daemonsets: [pollClusterWorkspacesDaemonsets],
  statefulsets: [pollClusterWorkspacesStatefulsets],
  jobs: [pollClusterWorkspacesJobs],
  cronjobs: [pollClusterWorkspacesCronjobs],
  rolebindings: [pollClusterWorkspacesRoleBindings],
  clusterrolebindings: [pollClusterWorkspacesClusterRoleBindings],
};
