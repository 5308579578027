export const initialState = {
  isLoading: false,
  list: [],
  data: {},
  isMapViewLoading: true,
};

export function FETCH_CLUSTERS_REQUEST(nextState) {
  nextState.isLoading = true;
}

export function FETCH_CLUSTERS_SUCCESS(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTERS_FAILURE(nextState) {
  nextState.isLoading = false;
}

export function FETCH_CLUSTERS_NORMALIZATION(nextState, { result }) {
  nextState.list = result;
}

export function GET_CLUSTERS_TOTAL_CORES(nextState, { data }) {
  nextState.data = { ...data };
}

export function MAP_VIEW_LOADING(nextState, { isLoading }) {
  nextState.isMapViewLoading = isLoading;
}
