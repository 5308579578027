import flags, { getDemoFlags } from "services/flags";

const Flags = (props) => {
  let shouldRender = false;
  if (props.has) {
    shouldRender = flags.has(props.has);
  }

  if (props.is) {
    shouldRender = flags.is(props.is);
  }

  if (!shouldRender) {
    return null;
  }

  return props.children;
};

export function DemoFlags(props) {
  const flags = getDemoFlags();
  let shouldRender = false;
  if (props.has) {
    shouldRender = flags.has(props.has);
  }

  if (props.is) {
    shouldRender = flags.is(props.is);
  }

  if (!shouldRender) {
    return null;
  }

  return props.children;
}

export default Flags;
