import i18next from "i18next";
import ListActions from "modules/list/actions";
import api from "services/api";
import { generatePath } from "react-router";
import notifications from "services/notifications";
import {
  deleteEdgeMachine,
  EDGE_MACHINES_MODULE,
  addEdgeMachinesDrawer,
} from "state/cluster/services/edgemachines";
import { EdgeMachineSchema } from "utils/schemas";
import createFormActions from "modules/form/actions";
import dataFetcher from "modules/dataFetcher";
import store from "services/store";
import { formatTags } from "utils/presenters";
import { parseAppliances } from "utils/parsers";
import history from "services/history";
import { CLUSTERS } from "utils/constants/routes";
import Validator from "services/validator";
import { areValidKubernetesTags } from "services/validator/rules";
import { APPLIANCE_STATES } from "utils/constants";

export const edgeMachinesFetcher = dataFetcher({
  selectors: ["edgeMachines"],
  fetchData: async () => {
    const data = await api.get("v1/edgehosts");
    return { items: parseAppliances(data?.items || []) };
  },
});

// It will need load on scroll, maybe find a way to use a fetcher
export const edgeMachinesListActions = new ListActions({
  schema: [EdgeMachineSchema],
  async fetchData(query) {
    const { continue: continueToken, states, search } = query;
    const data = await api.post(
      `v1/dashboard/appliances/metadata${
        continueToken ? `?continue=${continueToken}` : ""
      }`,
      {
        filter: {
          name: {
            contains: search,
          },
          states:
            states.length === 0
              ? APPLIANCE_STATES.map((state) => state.value)
              : states,
        },
      }
    );

    return {
      ...data,
      items: parseAppliances(data?.items || []),
    };
  },
  initialQuery() {
    return {
      search: "",
      states: APPLIANCE_STATES.filter((state) => state.value !== "in-use").map(
        (state) => state.value
      ),
    };
  },
});

const addMachinesValidator = new Validator();
addMachinesValidator.addRule(
  ["machineIds"],
  areValidKubernetesTags({
    errorMessageText: () =>
      i18next.t("Some IDs don't respect the kubernetes guidelines"),
    tooltipPlacement: "bottomRight",
  })
);

export const addEdgeMachinesFormActions = createFormActions({
  validator: addMachinesValidator,
  init: () =>
    Promise.resolve({
      machineIds: [],
      machinesTags: [],
    }),
  submit: async (data) => {
    const promises = data.machineIds.map((edgeHostUid, index) =>
      api.post("v1/edgehosts", {
        metadata: {
          name: edgeHostUid,
          uid: edgeHostUid,
          labels: formatTags(data.machinesTags[index] || []),
        },
      })
    );

    const result = await Promise.allSettled(promises);

    let hasRejection = false;
    const invalidMachines = [];

    result.forEach(({ status, reason, value }, index) => {
      if (status === "rejected") {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: reason?.message,
        });

        invalidMachines.push(data.machineIds[index]);
        hasRejection = true;
      }

      if (status === "fulfilled") {
        notifications.success({
          message: i18next.t(
            'Machine "{{machineUid}}" has been added successfully',
            { machineUid: value?.uid }
          ),
        });

        const updatedValues = [...data.machineIds];
        updatedValues.splice(index, 1);
        store.dispatch(
          addEdgeMachinesFormActions.onChange({
            module: EDGE_MACHINES_MODULE,
            name: "machineIds",
            value: updatedValues,
          })
        );
      }
    });

    if (hasRejection) {
      store.dispatch(
        addEdgeMachinesFormActions.updateErrors({
          module: EDGE_MACHINES_MODULE,
          errors: [
            {
              result: true,
              field: "machineIds",
              invalidTags: [...invalidMachines],
            },
          ],
        })
      );

      throw new Error();
    }

    store.dispatch(edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE));
  },
});

export function openAddEdgeMachineDrawer() {
  return (dispatch) => {
    addEdgeMachinesDrawer
      .open()
      .then(() =>
        dispatch(
          addEdgeMachinesFormActions.submit({ module: EDGE_MACHINES_MODULE })
        )
      );
  };
}

export function onMachineDelete({ uid, name }) {
  return (dispatch, getState) => {
    const isDetailsPage = getState().location.params.id;

    deleteEdgeMachine.open({ machineName: name }).then(async () => {
      try {
        await api.delete(`v1/edgehosts/${uid}`);
      } catch (e) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to delete the edge machine"
          ),
          description: e?.message,
        });
        return;
      }

      notifications.success({
        message: i18next.t('Machine "{{name}}" has been deleted', { name }),
      });

      dispatch(edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE));

      if (isDetailsPage) {
        history.push(generatePath(CLUSTERS.ROOT, { tab: "appliances" }));
      }
    });
  };
}

export function onMachineIdsChange(value) {
  return (dispatch, getState) => {
    dispatch(
      addEdgeMachinesFormActions.onChange({
        module: EDGE_MACHINES_MODULE,
        name: "machineIds",
        value,
      })
    );

    const errors = getState().forms?.edgeMachines?.errors || [];

    if (errors.length > 0) {
      const updatedErrors = errors.map((error) => {
        if (error.field === "machineIds") {
          error.invalidTags = [...error.invalidTags].filter((tag) =>
            value.includes(tag)
          );
          if (error.invalidTags.length === 0) {
            error.result = false;
          }
        }

        return error;
      });

      dispatch(
        addEdgeMachinesFormActions.updateErrors({
          module: EDGE_MACHINES_MODULE,
          errors: updatedErrors,
        })
      );

      dispatch(
        addEdgeMachinesFormActions.validateField({
          module: EDGE_MACHINES_MODULE,
          name: "machineIds",
        })
      );
    }
  };
}
