import dataFetcher from "modules/dataFetcher";
import api from "services/api";

export const LIST_ACTIONS_MODULE = "clusterWorkspace";
export const FILTERS_FORM_MODULE = "clusterWorkspaceFilters";
export const HOST_CLUSTERS_LIST_MODULE = "hostClusters";
export const HOST_CLUSTER_FILTERS_FORM = "hostClustersFilters";

export const hostClustersFetcher = dataFetcher({
  selectors: ["hostClusters"],
  async fetchData(_, props = {}) {
    //TODO: update API
    const response = await api.get(
      "v1/dashboard/spectroclusters/filters/workspace"
    );

    return {
      items: (response?.items || []).map((item) => ({
        ...item,
        key: item.guid,
        health: item.status.health?.state,
        metrics: item.status.metrics,
        isBrownfield:
          item?.status?.clusterImport?.isBrownfield ||
          !!item?.status?.clusterImport?.state,
      })),
    };
  },
});
