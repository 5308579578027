import i18n from "i18next";
import { v4 as uuid } from "uuid";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

import store from "services/store";
import flags from "services/flags";
import { ClusterProfileSchema } from "utils/schemas";
import {
  BAREMETAL_ENVS,
  MANAGED_TO_PURE_ENVIRONMENT,
  UPDATE_STRATEGIES,
} from "utils/constants";
import { FLAGS } from "utils/constants/flags";
import debounce from "redux-debounce-thunk";

import {
  getSelectedClusterProfile,
  getSelectedCloud,
  getSelectedEnvironmentType,
  isSupervizedEnvironment,
  getClusterRatePayload,
} from "state/cluster/selectors/create";
import api from "services/api";
import historyService from "services/history";
import notifications from "services/notifications";
import ModalService from "services/modal";
import { WizardActions } from "modules/wizard/actions";
import { getCheckpointStep, getCurrentStep } from "modules/wizard/selectors";
import {
  fetchAwsCloudConfigParams,
  fetchAzureNodeParams,
  fetchGoogleCloudNodeParams,
  fetchMaasCloudNodeParams,
  fetchBaremetalParams,
} from "../nodes";
import { getPayload } from "state/cluster/selectors/create";
import {
  regionsByProjectFetcher,
  profileModule,
  onSpotWarningConfirm,
  appliancesKeyedFetcher,
  vsphereAppliancesFetcher,
  datacentersFetcher,
  cloudAccountsFetcher,
  eksKmskeysFetcher,
} from "state/cluster/services/create";
import { fetchBackupLocationsFetcher } from "state/backuplocations/actions";
import { sshKeysFetcher } from "state/sshKeys/services";
import {
  azureAZFetcher,
  azureInstanceTypesFetcher,
  gcpInstanceTypesFetcher,
} from "state/cluster/services/nodes";
import aws from "./flows/aws";
import azure from "./flows/azure";
import gcp from "./flows/gcp";
import maas, { maasCloudForm } from "./flows/maas";
import vsphere from "./flows/vsphere";
import openstack from "./flows/openstack";
import tencent, { tencentCloudForm } from "./flows/tencent";
import { generateDomain } from "./utils";
import clusterFormActions, {
  createNodePoolValidator,
  nodePoolValidator,
} from "state/cluster/actions/create/form";
import { cloudAccountsSummaryFetcher } from "../list/clusters";

export const onCancelClusterModal = new ModalService("cancelModal");

const subnetFields = [
  "controlPlaneSubnetName",
  "controlPlaneSubnetCidr",
  "controlPlaneSubnetSecurity",
  "workerSubnetName",
  "workerSubnetCidr",
  "workerSubnetSecurity",
];

const cloudFields = {
  aws: ["region", "ssh", "vpcid"],
  eks: ["region", "ssh", "vpcid", "controlPlane", "provider"],
  vsphere: ["datacenter", "sshKeys", "ntpServers", "folder"],
  azure: [
    "region",
    "subscriptionId",
    "vnetName",
    "vnetResourceGroup",
    "vnetCidrBlock",
    "resourceGroup",
    "controlPlaneSubnet",
    ...subnetFields,
    "workerSubnet",
    "sshKey",
    "adminGroupObjectIDs",
  ],
  aks: [
    "region",
    "subscriptionId",
    "vnetName",
    "vnetResourceGroup",
    "vnetCidrBlock",
    "resourceGroup",
    "controlPlaneSubnet",
    ...subnetFields,
    "workerSubnet",
    "sshKey",
  ],
  openstack: [
    "domain",
    "region",
    "project",
    "sshKeyName",
    "nodeCidr",
    "dnsNameservers",
    "network",
    "subnet",
  ],
  gcp: ["project", "region", "network"],
  maas: ["domain"],
  libvirt: ["vip"],
  "edge-native": ["ip"],
  tke: ["region", "sshKeys", "vpcid"],
};

export const CLUSTER_CREATION_STEPS = [
  {
    title: () => i18n.t("Basic information"),
    id: "basic-info",
  },
  {
    title: () => i18n.t("Cluster profile"),
    id: "infra-profile",
  },
  {
    title: () => i18n.t("Parameters"),
    id: "parameters",
  },
  {
    title: () => i18n.t("Cluster config"),
    id: "cluster-config",
  },
  {
    title: () => i18n.t("Nodes config"),
    id: "nodes-config",
  },
  {
    title: () => i18n.t("Settings"),
    id: "policies",
  },
  {
    title: () => i18n.t("Review"),
    id: "review",
  },
];

export async function submit(data) {
  const state = store.getState();
  const cloudType = getSelectedCloud(state);

  const payload = getPayload(state);

  let response = null;

  try {
    response = await api.post(`v1/spectroclusters/${cloudType}`, payload);
  } catch (error) {
    notifications.error({
      message: i18n.t("Something went wrong when creating the cluster"),
      description: error.message,
    });
  }

  if (response) {
    historyService.push(`/clusters/${response.uid}/overview`);
    notifications.success({
      message: `Cluster "${data.name}" has been created successfully. Deployment is in progress...`,
    });
  }
}

const GET_DEFAULT_NODE_VALUES = ({ cpu = 4, memory = 8 } = {}) => ({
  size: 1,
  azs: [],
  instanceOption: "onDemand",
  instanceType: null,
  maxPricePercentage: 1,
  guid: uuid(),
  cpu,
  maxNodeSize: 3,
  minNodeSize: 1,
  minCPU: cpu,
  memory,
  minMem: memory,
  disk: 60,
  osType: "Linux",
  isAutoscalerEnabled: false,
  storageAccountType: "",
  updateStrategy: UPDATE_STRATEGIES[0].value,
  flavor: "",
  resourcePool: "",
  edgeHosts: [{ hostUid: "" }],
  gpuVendor: "",
  gpuModel: "",
  taints: [],
  additionalLabels: [],
});

export function onCloudAccountSelect(value) {
  return (dispatch, getState) => {
    const { preselectedProfile, profileType } = getState().forms?.cluster?.data;
    const isAddon = profileType === "add-on";

    const fetcher = isAddon
      ? cloudAccountsSummaryFetcher
      : cloudAccountsFetcher;
    const cloudAccounts = fetcher.selector(getState()).result;

    const selectedCloudAcc = cloudAccounts.find(
      (acc) => acc.metadata.uid === value
    );

    let updates = {
      credential: value,
      hasDisablePropertiesRequest:
        selectedCloudAcc?.spec?.settings?.disablePropertiesRequest,
    };
    if (!preselectedProfile || isAddon) {
      updates = {
        ...updates,
        cloudType: selectedCloudAcc.kind,
        clusterprofile: null,
      };

      dispatch({
        type: "SET_SELECTED_CLOUD_TYPE",
        selectedCloud: selectedCloudAcc.kind,
      });
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates,
      })
    );
  };
}

export function onClusterTypeChange(value) {
  return (dispatch, getState) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "clusterType",
        value,
      })
    );

    const errors = getState().forms.cluster.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.startsWith("credential") ? { ...error, result: null } : error
    );

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: errorRemnants,
      })
    );
  };
}

export function onChangeManagedActiveDirectory(value) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "managedAD",
        value,
      })
    );

    if (!value) {
      dispatch(
        clusterFormActions.validateField({
          name: "adminGroupObjectIDs",
          module: "cluster",
        })
      );
    }
  };
}

export function onChangeStaticPlacement(value) {
  return (dispatch, getState) => {
    const cloudType = getSelectedEnvironmentType(getState());

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "staticPlacement",
        value,
      })
    );

    const effects = {
      aws: aws.onChangeStaticPlacement,
      azure: azure.onChangeStaticPlacement,
      gcp: gcp.onChangeStaticPlacement,
    };

    Object.keys(effects).includes(cloudType) && dispatch(effects[cloudType]());
  };
}

export function onChangeEnableEncryption(value) {
  return (dispatch, getState) => {
    const region = getState().forms.cluster.data.region;

    dispatch(eksKmskeysFetcher.key(region).fetch());

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "enableEncryption",
        value,
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: "provider",
      })
    );
  };
}

function resetClusterConfigForm() {
  return (dispatch, getState) => {
    const selectedCloud = getSelectedEnvironmentType(getState());
    const cloudFieldsToReset = (cloudFields?.[selectedCloud] || []).reduce(
      (acc, fieldName) => {
        acc[fieldName] = "";
        return acc;
      },
      {}
    );
    if (selectedCloud === "vsphere") {
      cloudFieldsToReset.ntpServers = [];
    }

    if (selectedCloud === "openstack") {
      cloudFieldsToReset.dnsNameservers = [];
    }

    if ([...BAREMETAL_ENVS, "maas", "vsphere", "tke"].includes(selectedCloud)) {
      cloudFieldsToReset.sshKeys = [];
    }

    if (["aks", "azure"].includes(selectedCloud)) {
      cloudFieldsToReset.adminGroupObjectIDs = [];
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: cloudFieldsToReset,
      })
    );
  };
}

function resetNodesConfigForm() {
  return (dispatch, getState) => {
    const isSupervized = isSupervizedEnvironment(getState());
    const cloudType = getSelectedCloud(getState());
    const workerPool = {
      poolName: "worker-pool",
      ...GET_DEFAULT_NODE_VALUES(),
      domains: [generateDomain()],
    };

    const masterPool = {
      poolName: "master-pool",
      ...GET_DEFAULT_NODE_VALUES({ cpu: 2 }),
      isMaster: true,
      domains: [generateDomain()],
      allowWorkerCapability: cloudType === "edge-native",
    };

    let nodePools = [masterPool, workerPool];
    if (isSupervized) {
      nodePools = [workerPool];
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch({
      type: "CLUSTER_CREATE_RESET_ESTIMATED_RATE",
    });
  };
}

function initializeProfileStack() {
  return async function thunk(dispatch, getState) {
    await dispatch(fetchClusterProfilePacks());
    const selectedClusterProfile = getSelectedClusterProfile(getState());
    const formData = getState().forms.cluster.data;
    let profiles = [{ ...selectedClusterProfile, type: "infra" }];

    if (formData.profileType === "add-on") {
      const addon = await dispatch(fetchAddonProfilePacks());
      profiles.splice(1, 0, { ...addon, type: "add-on" });
    }

    profileModule.actions.initialize({
      profiles,
      options: {
        allowSystemProfiles:
          formData.clusterType === "edge" &&
          formData.cloudType !== "edge-native",
        isSystemProfileRequired:
          formData.cloudType === "libvirt" ||
          (formData.cloudType === "vsphere" && formData.clusterType === "edge"),
      },
    });

    let resolvedValuesPromise = api
      .get(
        `v1/clusterprofiles/${selectedClusterProfile.metadata.uid}/packs/resolvedValues`
      )
      .then((res) =>
        profileModule.actions.updateResolvedValues([
          {
            resolved: res.resolved,
            uid: selectedClusterProfile.metadata.uid,
          },
        ])
      );
    dispatch({
      type: "FETCH_CLUSTER_PROFILE_RESOLVED_VALUES",
      promise: resolvedValuesPromise,
      schema: ClusterProfileSchema,
    });

    await resolvedValuesPromise;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "initializedProfileStack",
        value: true,
      })
    );
  };
}

export function clusterProfileSelectionEffect() {
  return async function thunk(dispatch, getState) {
    const selectedCloud = getSelectedEnvironmentType(getState());

    const steps = getState().wizard.cluster.steps;
    const { preselectedProfile, clusterType, profileType } =
      getState().forms?.cluster?.data;

    let updatedSteps = CLUSTER_CREATION_STEPS.map((step) => {
      const stateStep = steps.find((stateStep) => stateStep.id === step.id);

      if (stateStep) {
        return { ...stateStep };
      }

      return { ...step };
    });

    if (["edge"].includes(selectedCloud)) {
      updatedSteps = updatedSteps.filter(
        (step) => step.id !== "cluster-config"
      );
    }

    if (preselectedProfile && profileType !== "add-on") {
      updatedSteps = updatedSteps.filter((step) => step.id !== "infra-profile");
    }
    if (clusterType !== "edge") {
      dispatch(selectCredential());
    }

    dispatch(wizardActions.updateSteps("cluster", updatedSteps));
    dispatch(resetClusterConfigForm());
    dispatch(resetNodesConfigForm());
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "initializedProfileStack",
        value: false,
      })
    );
  };
}

export function updatePolicyMenuSelection(newKey) {
  return function thunk(dispatch) {
    dispatch({
      type: "UPDATE_POLICY_MENU_SELECTION",
      newKey,
    });
  };
}

export const wizardActions = new WizardActions({
  formActions: clusterFormActions,
  fieldsToValidate() {
    const { preselectedProfile, clusterType, cloudType, profileType } =
      store.getState().forms?.cluster?.data;

    const getFields = () => {
      const selectedCloud = getSelectedCloud(store.getState());

      if (clusterType === "edge") {
        if (cloudType === "vsphere") {
          return [
            ["name", "cloudType"],
            ["layers"],
            [...(cloudFields[selectedCloud] || []), "appliance", "vip"],
            ["nodePools"],
          ];
        }
        if (["libvirt", "edge-native"].includes(cloudType)) {
          return [
            ["name", "cloudType"],
            ["layers"],
            cloudFields[selectedCloud],
            ["nodePools"],
          ];
        }

        return [["name", "cloudType"], ["layers"], ["nodePools"]];
      }

      return [
        ["name", "credential", "clusterType"],
        ["layers"],
        cloudFields[selectedCloud],
        ["nodePools"],
      ];
    };

    const fields = getFields();

    if (profileType === "add-on" || !preselectedProfile) {
      fields.splice(1, 0, ["clusterprofile"]);
    }

    return fields.reduce(
      (acc, field, index) => ({
        ...acc,
        [index]: field,
      }),
      {}
    );
  },
  getDescription({ id }) {
    const formData = store.getState().forms.cluster.data;

    if (id === "basic-info") {
      return formData.name;
    }

    if (id === "infra-profile") {
      return getSelectedClusterProfile(store.getState())?.metadata.name;
    }

    if (
      ["parameters", "cluster-config", "nodes-config", "policies"].includes(id)
    ) {
      return i18n.t("Completed");
    }
  },
  steps: CLUSTER_CREATION_STEPS,
  async onStepChange({ id }) {
    const {
      preselectedProfile,
      clusterType,
      profileType,
      initializedProfileStack,
      cloudType,
    } = store.getState().forms?.cluster?.data;

    if (id === "basic-info") {
      if (clusterType !== "edge") {
        profileType === "add-on"
          ? store.dispatch(cloudAccountsSummaryFetcher.fetch())
          : store.dispatch(cloudAccountsFetcher.fetch());
      }
    }

    if (id === "parameters") {
      if (preselectedProfile) {
        store.dispatch(clusterProfileSelectionEffect());
      }

      if (!initializedProfileStack) {
        store.dispatch(initializeProfileStack());
      }
    }

    if (id === "cluster-config") {
      store.dispatch(sshKeysFetcher.fetch());
      if (cloudType === "vsphere" && clusterType === "edge") {
        store.dispatch(vsphereAppliancesFetcher.fetch());
      }
      if (cloudType === "tke") {
        store.dispatch(tencentCloudForm.effects.fetchClusterConfigParams());
      }
    }

    if (id === "nodes-config") {
      const cloudType = getSelectedEnvironmentType(store.getState());
      const policyMenuSelection =
        cloudType === "tencent" ? "scan-policies" : "manage-machines";

      createNodePoolValidator();

      const effects = {
        aws: () => fetchAwsCloudConfigParams({ type: "create" }),
        azure: fetchAzureNodeParams,
        gcp: fetchGoogleCloudNodeParams,
        maas: () => fetchMaasCloudNodeParams(maasCloudForm),
        edge: fetchBaremetalParams,
        libvirt: fetchBaremetalParams,
        "edge-native": fetchBaremetalParams,
        tencent: tencentCloudForm.effects.fetchNodesConfigParams,
      };

      Object.keys(effects).includes(cloudType) &&
        store.dispatch(effects[cloudType]());

      store.dispatch(updatePolicyMenuSelection(policyMenuSelection));
    }

    if (id === "policies" && flags.has(FLAGS.BACKUP)) {
      await store.dispatch(fetchBackupLocationsFetcher.fetch());
      store.dispatch(setDefaultLocation());
    }
  },
});

function setDefaultLocation() {
  return (dispatch, getState) => {
    const state = getState();
    const currentLocation = state.forms.cluster?.data?.location;
    const locations =
      fetchBackupLocationsFetcher.selector(store.getState())?.result?.items ||
      [];
    const location =
      locations.find((l) => l.spec?.isDefault)?.metadata?.uid || null;

    if (currentLocation) {
      return;
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "location",
        value: location,
      })
    );
  };
}

function parseProfilePacks(packs = [], selectedClusterProfile) {
  const packMapping = packs.reduce((accumulator, item) => {
    accumulator[item.metadata.name] = item;
    return accumulator;
  }, {});

  return {
    ...selectedClusterProfile,
    spec: {
      ...selectedClusterProfile.spec,
      published: {
        ...selectedClusterProfile.spec.published,
        packs: selectedClusterProfile.spec.published.packs.map((pack) => {
          return {
            ...pack,
            ...packMapping[pack.name],
          };
        }),
      },
    },
  };
}

export function fetchAddonProfilePacks() {
  return async function thunk(dispatch) {
    const [, profileUid] = store
      .getState()
      .router.location?.pathname?.split("create/");
    const addonProfile = await api.get(`v1/clusterprofiles/${profileUid}`);

    const promise = api.get(
      `v1/clusterprofiles/${addonProfile.metadata.uid}/packs?includePackMeta=schema,presets`
    );

    try {
      dispatch({
        type: "FETCH_CLUSTER_PROFILE_PACKS",
        promise,
        schema: ClusterProfileSchema,
      });

      const result = await promise;

      return parseProfilePacks(result.items, addonProfile);
    } catch (e) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to fetch the addon profile"
        ),
        description: e.message,
      });
    }
  };
}

export function fetchClusterProfilePacks() {
  return function thunk(dispatch, getState) {
    const selectedClusterProfile = getSelectedClusterProfile(getState());
    const selectedCloud = getSelectedCloud(getState());

    const promise = api
      .get(
        `v1/clusterprofiles/${selectedClusterProfile.metadata.uid}/packs?includePackMeta=schema,presets`
      )
      .then((res) => {
        return parseProfilePacks(res.items, selectedClusterProfile);
      });

    dispatch({
      type: "FETCH_CLUSTER_PROFILE_PACKS",
      promise,
      schema: ClusterProfileSchema,
    });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "cloudType",
        value: selectedCloud,
      })
    );

    return promise;
  };
}

export function resetNextStepFields() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = getCurrentStep("cluster")(state);
    const isNextStepCompleted =
      !!state.wizard.cluster.steps?.[currentStep + 1]?.description;

    if (!isNextStepCompleted) {
      return;
    }

    if (currentStep === 3) {
      dispatch(resetNodesConfigForm());
    }

    dispatch(resetForwardSteps());
  };
}

export function selectCredential() {
  return async (dispatch, getState) => {
    const selectedCloud = getSelectedEnvironmentType(getState());

    dispatch(resetNextStepFields());

    const effects = {
      aws: aws.selectCredentialEffect,
      azure: azure.selectCredentialEffect,
      gcp: gcp.selectCredentialEffect,
      maas: maas.selectCredentialEffect,
      vsphere: vsphere.selectCredentialEffect,
      openstack: openstack.selectCredentialEffect,
      tencent: tencent.selectCredentialEffect,
    };

    Object.keys(effects).includes(selectedCloud) &&
      dispatch(effects[selectedCloud]());
  };
}

export function onAppliancesChange(value) {
  return (dispatch, getState) => {
    dispatch(resetNextStepFields());
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "appliance",
        value,
      })
    );
    dispatch(datacentersFetcher.fetch());
  };
}

export function selectRegion(region) {
  return (dispatch, getState) => {
    const selectedCloud = getState().cluster.create.selectedCloud;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "region",
        value: region,
      })
    );
    dispatch(resetNextStepFields());

    const effects = {
      aws: aws.selectRegionEffect,
      azure: azure.selectRegionEffect,
      gcp: gcp.selectRegionEffect,
      tencent: tencent.selectRegionEffect,
    };

    dispatch(effects[selectedCloud](region));
  };
}

function resetForwardSteps() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = getCurrentStep("cluster")(state);

    state.wizard.cluster.steps.forEach((_, index) => {
      if (index >= currentStep) {
        dispatch({
          type: "WIZARD__SET_STEP_DESCRIPTION",
          step: index,
          description: "",
          module: "cluster",
        });
      }
    });
  };
}

export function onClusterProfileChange(name, value) {
  return async (dispatch, getState) => {
    const checkpoint = getCheckpointStep("cluster")(getState());
    const currentStep = getCurrentStep("cluster")(getState());

    dispatch(clusterFormActions.onChange({ name, value, module: "cluster" }));

    if (currentStep < checkpoint) {
      dispatch(resetForwardSteps());
    }

    dispatch(clusterProfileSelectionEffect());
  };
}

export function setClusterSelectedLayer(selectedLayer) {
  return {
    type: "SET_CLUSTER_SELECTED_LAYER",
    selectedLayer: selectedLayer,
  };
}

export function backToClusterProfileSelection() {
  return {
    type: "FORM_ON_CHANGE",
    name: "clusterprofile",
    value: null,
    module: "cluster",
  };
}

export function addNodePool() {
  return (dispatch, getState) => {
    const state = getState();
    const formData = state.forms.cluster.data;

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools: [
            ...formData.nodePools,
            {
              poolName: `worker-pool-${formData.nodePoolsCount}`,
              ...GET_DEFAULT_NODE_VALUES(),
              domains: [generateDomain()],
            },
          ],
          nodePoolsCount: formData.nodePoolsCount + 1,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        name: "nodePools",
        module: "cluster",
      })
    );

    dispatch(
      appliancesKeyedFetcher.key(`${formData.nodePools.length}`).fetch()
    );
  };
}

export function removeNodePool(index) {
  return (dispatch, getState) => {
    const state = getState();
    const formNodePools = state.forms.cluster.data?.nodePools;

    const newNodePools = [...formNodePools];
    newNodePools.splice(index, 1);

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: newNodePools,
      })
    );

    const errors = getState().forms.cluster.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.startsWith(`nodePools.${index}`) || error?.showInWizardFooter
        ? { ...error, result: null }
        : error
    );

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: errorRemnants,
      })
    );

    newNodePools.forEach((_, poolIndex) =>
      dispatch(appliancesKeyedFetcher.key(`${poolIndex}`).fetch())
    );
  };
}

export function copyFromMasterPool(index) {
  return (dispatch, getState) => {
    const state = getState();
    const clusterFormData = state.forms.cluster.data;
    const formNodePools = clusterFormData?.nodePools || [];
    const nodePools = [...formNodePools];
    const nodePool = nodePools[index];
    const masterPool = nodePools.find((nodePool) => nodePool.isMaster);
    const getPaths = (keys) => keys.map((key) => `nodePools.${index}.${key}`);
    const cloudType = getSelectedEnvironmentType(getState());
    const cloudFieldsToKeep = {
      maas: ["minCPU", "minMem"],
    };

    const cloudFieldsToOmit = {
      azure: () => {
        const instanceTypes =
          azureInstanceTypesFetcher.selector(getState())?.result
            ?.instanceTypes || [];
        const azs = azureAZFetcher.selector(getState())?.result?.zoneList || [];
        const selectedMasterInstanceType = instanceTypes.find(
          (instanceType) => instanceType.type === masterPool.instanceType
        );

        return azs.length > 1 &&
          (selectedMasterInstanceType?.nonSupportedZones || []).length ===
            azs.length
          ? ["instanceType"]
          : [];
      },
      edge: () => ["edgeHosts"],
      libvirt: () => ["gpuSupport", "numGPUs", "gpuVendor", "gpuModel"],
      aws: () => {
        if (!clusterFormData?.staticPlacement) {
          return [];
        }

        const azsSubnets = (masterPool?.azs || []).map((az) => `subnet_${az}`);

        return ["azs", ...azsSubnets];
      },
    };

    const selectedCloudFieldsToOmit = cloudFieldsToOmit?.[cloudType];

    const generalFieldsToOmit = [
      "isMaster",
      "guid",
      "poolName",
      "size",
      "instanceOption",
      "updateStrategy",
      "allowWorkerCapability",
      "minCPU",
      "minMem",
      "taints",
      "withTaints",
      ...((selectedCloudFieldsToOmit && selectedCloudFieldsToOmit()) || []),
    ].filter(
      (field) => !(cloudFieldsToKeep?.[cloudType] || []).includes(field)
    );

    const fieldsToCopy = omit(masterPool, generalFieldsToOmit) || {};

    nodePools[index] = {
      ...nodePool,
      ...cloneDeep(fieldsToCopy),
    };

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: getPaths(Object.keys(fieldsToCopy)),
      })
    );
    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${index}.domains`,
      })
    );
  };
}

export function onModalClose() {
  const formData = store.getState().forms.cluster.data;

  if (formData.name === "") {
    historyService.push("/clusters/overview");
    return;
  }

  onCancelClusterModal.open().then(() => {
    historyService.push("/clusters/overview");
  });
}

export function hasCloudAccounts(cloudAccounts) {
  return function checker(cloudType) {
    let environment = cloudType;
    if (BAREMETAL_ENVS.includes(cloudType)) {
      return true;
    }

    if (MANAGED_TO_PURE_ENVIRONMENT[cloudType]) {
      environment = MANAGED_TO_PURE_ENVIRONMENT[cloudType];
    }

    return cloudAccounts?.find((cloud) => cloud.kind === environment);
  };
}

export function onCloudSelectorChange(cloudType) {
  return (dispatch, getState) => {
    const state = getState();
    const cloudAccounts = cloudAccountsFetcher.selector(state)?.result;
    const hasAccount = hasCloudAccounts(cloudAccounts)(cloudType);

    if (hasAccount) {
      dispatch(
        clusterFormActions.onChange({
          name: "cloudType",
          module: "cluster",
          value: cloudType,
        })
      );
    } else {
      historyService.push("/settings/cloudaccounts");
    }
  };
}

export function selectProject(project) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          project,
          region: "",
          network: "",
        },
      })
    );
    dispatch(regionsByProjectFetcher.fetch());
    dispatch(resetNextStepFields());
  };
}

function parseResultsErrors(results = []) {
  return results.reduce((acc, result) => {
    if (result.errors?.length) {
      const errors = result.errors.map((error) => ({
        ...error,
        displayName: result.displayName,
      }));
      return [...acc, ...errors];
    }
    return acc;
  }, []);
}

export function validateCluster() {
  return async (dispatch, getState) => {
    const state = getState();
    const payload = getPayload(state);
    const selectedCloud = getSelectedCloud(state);
    const errors = await dispatch(
      clusterFormActions.validateForm({ module: "cluster" })
    );
    let response = null;

    if (errors && errors.length) return;

    const promise = api.post(
      `v1/spectroclusters/${selectedCloud}/validate`,
      payload
    );

    dispatch({
      type: "VALIDATE_CLUSTER",
      promise,
    });

    try {
      response = await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to validate the cluster"
        ),
        description: error.message,
      });
      return;
    }

    const machinePools = response?.machinePools?.results || [];
    const packs = response?.packs?.results || [];
    const validationSuccess =
      response && isEmpty(machinePools) && isEmpty(packs);

    if (validationSuccess) {
      dispatch(wizardActions.nextStep("cluster"));
      return;
    }

    const machinePoolsErrors = parseResultsErrors(machinePools);
    const packsErrors = parseResultsErrors(packs);

    machinePoolsErrors.forEach((error) => {
      notifications.error({
        message: error.displayName,
        description: error.message,
        duration: 0,
      });
    });
    packsErrors.forEach((error) => {
      notifications.error({
        message: error.displayName,
        description: error.message,
        duration: 0,
      });
    });
  };
}

export function onNodesConfigNextStep() {
  return (dispatch, getState) => {
    const state = getState();
    const clusterFormData = state.forms.cluster?.data;
    const isSupervized = isSupervizedEnvironment(state);

    const workerPools = clusterFormData.nodePools.filter(
      (nodePool) => !nodePool.isMaster
    );

    const masterPool = clusterFormData.nodePools.find(
      (nodePool) => nodePool.isMaster
    );

    if (
      !masterPool?.allowWorkerCapability &&
      workerPools.length > 0 &&
      workerPools.every((pool) => pool.instanceOption === "onSpot") &&
      !isSupervized
    ) {
      onSpotWarningConfirm.open().then(
        () => dispatch(onSpotWarningAllow()),
        () => dispatch(onSpotWarningIgnore())
      );
      return;
    }

    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onSpotWarningAllow() {
  return (dispatch, getState) => {
    let nodePools = [...getState().forms.cluster.data.nodePools];
    const masterPoolIndex = nodePools.findIndex(
      (nodePool) => nodePool.isMaster
    );
    const masterPool = nodePools[masterPoolIndex];

    nodePools.splice(masterPoolIndex, 1, {
      ...masterPool,
      allowWorkerCapability: true,
    });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onSpotWarningIgnore() {
  return (dispatch) => {
    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onClusterFormSubmit() {
  return async (dispatch) => {
    try {
      await dispatch(clusterFormActions.submit({ module: "cluster" }));
    } catch (err) {
      if ((err || []).find((error) => error.field === "layers")) {
        notifications.warn({
          message: i18n.t(
            "There are pack validation errors. Please fix them in order to be able to deploy"
          ),
        });
      }
    }
  };
}

export function onInstanceTypeChange(value, poolIndex) {
  return (dispatch, getState) => {
    const state = getState();
    const cloudType = getSelectedCloud(state);
    const resultTypes = {
      aws: state.cluster.details.cloudConfigParams,
      azure: azureInstanceTypesFetcher.selector(state).result,
      gcp: gcpInstanceTypesFetcher.selector(state).result,
      default: { instanceTypes: [] },
    };

    const result = resultTypes[cloudType] || resultTypes.default;

    const nodePools = [...state.forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      instanceType: value,
      instancePrice:
        result?.instanceTypes.find(
          (instanceType) => instanceType.type === value
        )?.price || 0,
      azs: [],
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}.instanceType`,
      })
    );
  };
}

export function onAksSetPoolAsSystem(value, index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];

    nodePools[index].isSystemNodePool = value;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: nodePools.map(
          (_, index) => `nodePools.${index}.isSystemNodePool`
        ),
      })
    );
  };
}

export function addNewTaint(index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[index].taints.push({ key: "", value: "", effect: null });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );
  };
}

export function setTaintsVisibilityChange(index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[index].withTaints = !nodePools[index].withTaints;

    if (nodePools[index].withTaints) {
      nodePools[index].taints.push({ key: "", value: "", effect: null });
    } else {
      nodePools[index].taints = [];
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    const errors = getState().forms.cluster.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.includes(`taints`) ? { ...error, result: null } : error
    );

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: errorRemnants,
      })
    );
  };
}

export function removeTaint({ poolIndex, taintIndex }) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[poolIndex].taints.splice(taintIndex, 1);

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    const errors = getState().forms.cluster.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.includes(`taints`) ? { ...error, result: null } : error
    );

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: errorRemnants,
      })
    );
  };
}

export function addNewVirtualDevice(index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[index].edgeHosts.push({ hostUid: "" });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );
  };
}

export const debouncedNodesFormChange = debounce(onNodesConfigFormChange, 2000);

function onNodesConfigFormChange(data) {
  return async (dispatch) => {
    let errors = [];
    const validations = nodePoolValidator.run(data.nodePools);

    for await (const error of validations) {
      if (error.result) {
        errors.push(error);
      }
    }

    if (!errors?.length && !BAREMETAL_ENVS.includes(data.cloudType)) {
      dispatch(fetchClusterRate());
    }
  };
}

function fetchClusterRate() {
  return async (dispatch, getState) => {
    const state = getState();
    const payload = getClusterRatePayload(state);
    const cloudType = getSelectedCloud(state);

    if (!cloudType) {
      return;
    }

    const promise = api.post(
      `v1/spectroclusters/${cloudType}/rate?periodType=hourly`,
      payload
    );

    dispatch({
      promise,
      type: "FETCH_CREATE_CLUSTER_RATE",
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: err.message,
      });
      return;
    }

    return promise;
  };
}

export function initClusterWizard() {
  return async function thunk(dispatch, getState) {
    dispatch(wizardActions.initialize("cluster"));
    await dispatch(clusterFormActions.init({ module: "cluster" }));
    const { clusterType, profileType } = getState().forms.cluster?.data;
    if (clusterType !== "edge") {
      dispatch(cloudAccountsFetcher.fetch());
      profileType === "add-on"
        ? store.dispatch(cloudAccountsSummaryFetcher.fetch())
        : store.dispatch(cloudAccountsFetcher.fetch());
    }
  };
}
