import i18next from "i18next";
import { generatePath } from "react-router";
import _ from "lodash";

import { WizardActions } from "modules/wizard/actions";
import store from "services/store";
import historyService from "services/history";
import Validator from "services/validator";
import createFormActions from "modules/form/actions";
import { Missing, ApplyIf, isKubernetesName } from "services/validator/rules";
import flags from "services/flags";
import api from "services/api";

import { v4 as uuid } from "uuid";
import { WORKSPACES } from "utils/constants/routes";
import { FLAGS } from "utils/constants/flags";

import {
  WORKSPACES_FORM_MODULE,
  cancelCreateWorkspaceModal,
  clusterRoleBindingsModal,
  namespaceRoleBindingsModal,
  workspacesClustersMetadataFetcher,
} from "state/workspaces/services/create";
import workspaceFormActions, { createWorkspaceRoleBindingsForm } from "./form";
import { getSelectedClustersList } from "state/workspaces/selectors/create";
import { fetchBackupLocationsFetcher } from "state/backuplocations/actions";
import { fetchClustersByUids } from "state/workspaces/actions/edit";
import { isValidRegex } from "utils/strings";
import { ClusterSchema } from "utils/schemas";

export const clusterRoleBindingsValidator = new Validator();
const clusterRoleBindingsSubjectValidator = new Validator();
export const nsRoleBindingsValidator = new Validator();
const nsRoleBindingsSubjectValidator = new Validator();

clusterRoleBindingsValidator.addRule(["roleName"], Missing());
clusterRoleBindingsValidator.addRule(
  "subjects",
  clusterRoleBindingsSubjectValidator
);
clusterRoleBindingsSubjectValidator.addRule(["name"], Missing());
clusterRoleBindingsSubjectValidator.addRule(
  "name",
  ApplyIf(
    (value, key, data) => data.type === "ServiceAccount",
    isKubernetesName()
  )
);
clusterRoleBindingsSubjectValidator.addRule(
  "namespace",
  ApplyIf((value, key, data) => data.type === "ServiceAccount", Missing())
);
clusterRoleBindingsSubjectValidator.addRule(
  "namespace",
  ApplyIf(
    (value, key, data) => data.type === "ServiceAccount",
    isKubernetesName()
  )
);

nsRoleBindingsValidator.addRule(["namespace", "roleName"], Missing());
nsRoleBindingsValidator.addRule("subjects", nsRoleBindingsSubjectValidator);
nsRoleBindingsSubjectValidator.addRule(["name"], Missing());
nsRoleBindingsSubjectValidator.addRule(
  "name",
  ApplyIf(
    (value, key, data) => data.type === "ServiceAccount",
    isKubernetesName()
  )
);
nsRoleBindingsSubjectValidator.addRule(
  "namespace",
  ApplyIf((value, key, data) => data.type === "ServiceAccount", Missing())
);
nsRoleBindingsSubjectValidator.addRule(
  "namespace",
  ApplyIf(
    (value, key, data) => data.type === "ServiceAccount",
    isKubernetesName()
  )
);

export const wizardActions = new WizardActions({
  formActions: workspaceFormActions,
  fieldsToValidate() {
    return {
      0: ["name"],
      1: ["clusters"],
      2: ["namespaces"],
      3: ["containerImages", "location", "backupedClusters"],
    };
  },
  getDescription({ id }) {
    const formData = store.getState().forms[WORKSPACES_FORM_MODULE]?.data;

    if (id === "basic-info") {
      return formData.name;
    }

    if (["clusters", "namespaces", "policies"].includes(id)) {
      return i18next.t("Completed");
    }
  },
  steps: [
    {
      title: () => i18next.t("Basic Information"),
      id: "basic-info",
    },
    {
      title: () => i18next.t("Clusters"),
      id: "clusters",
    },
    {
      title: () => i18next.t("Namespaces"),
      id: "namespaces",
    },
    {
      title: () => i18next.t("Settings"),
      id: "policies",
    },
    {
      title: () => i18next.t("Review"),
      id: "review",
    },
  ],
  async onStepChange({ id }) {
    if (id === "clusters") {
      store.dispatch(workspacesClustersMetadataFetcher.fetch());
    }

    if (id === "namespaces") {
      const state = store.getState();
      const { namespaces } = state.forms[WORKSPACES_FORM_MODULE]?.data;
      const selectedClusters = getSelectedClustersList(state);
      const parents = namespaces.filter((ns) => !ns.isChild);

      const namespacesList = parents.reduce((acc, parent) => {
        const children = selectedClusters.map((cluster) => {
          const existingClusterData = parent.children.find(
            (ns) => cluster.metadata.uid === ns.clusterUid
          );
          return {
            guid: uuid(),
            alocMemory: existingClusterData?.alocMemory || "",
            alocCpu: existingClusterData?.alocCpu || "",
            isChild: true,
            parentGuid: parent.guid,
            clusterName: cluster.metadata.name,
            clusterUid: cluster.metadata.uid,
          };
        });
        return [...acc, { ...parent, children }];
      }, []);

      store.dispatch(
        workspaceFormActions.batchChange({
          module: WORKSPACES_FORM_MODULE,
          updates: {
            namespace: "",
            namespaces: namespacesList,
          },
        })
      );
    }

    if (id === "policies" && flags.has(FLAGS.BACKUP)) {
      await store.dispatch(fetchBackupLocationsFetcher.fetch());
    }
  },
});

export function onWizardModalClose() {
  const formData = store.getState().forms[WORKSPACES_FORM_MODULE]?.data || {};
  const returnPath = generatePath(WORKSPACES.ROOT, { tab: "overview" });

  if (!formData.name) {
    historyService.push(returnPath);
    return;
  }

  cancelCreateWorkspaceModal.open().then(() => {
    historyService.push(returnPath);
  });
}

export function onClusterChange(value) {
  return function thunk(dispatch) {
    const formClusters =
      store.getState().forms[WORKSPACES_FORM_MODULE]?.data?.clusters || [];
    const clusters =
      workspacesClustersMetadataFetcher.selector(store.getState())?.result ||
      [];
    const addedClusters = _.difference(value, formClusters);

    fetchClustersByUids(addedClusters);

    const filteredGuids = clusters
      .filter((cluster) => value.includes(cluster.metadata.uid))
      .map((cluster) => cluster?.guid);

    dispatch(
      workspaceFormActions.batchChange({
        module: WORKSPACES_FORM_MODULE,
        updates: {
          selectedClustersGuids: filteredGuids,
          clusters: value,
        },
      })
    );
  };
}

export function onClusterRemove(clusterUid) {
  return (dispatch) => {
    const clusterUids =
      store.getState().forms[WORKSPACES_FORM_MODULE]?.data?.clusters || [];
    const value = clusterUids.filter((uid) => uid !== clusterUid);
    const backupedClusters =
      store.getState().forms[WORKSPACES_FORM_MODULE]?.data?.backupedClusters ||
      [];
    const filteredBackupedClusters = backupedClusters.filter(
      (uid) => uid !== clusterUid
    );
    const clusters =
      workspacesClustersMetadataFetcher.selector(store.getState())?.result ||
      [];

    const filteredGuids = clusters
      .filter((cluster) => value.includes(cluster.metadata.uid))
      .map((cluster) => cluster?.guid);

    dispatch(
      workspaceFormActions.batchChange({
        module: WORKSPACES_FORM_MODULE,
        updates: {
          selectedClustersGuids: filteredGuids,
          clusters: value,
        },
      })
    );

    dispatch(
      workspaceFormActions.onChange({
        module: WORKSPACES_FORM_MODULE,
        name: "backupedClusters",
        value: filteredBackupedClusters,
      })
    );
  };
}

export function addNamescapeToList() {
  return async (dispatch, getState) => {
    const errors = await dispatch(
      workspaceFormActions.validateField({
        name: "namespace",
        module: WORKSPACES_FORM_MODULE,
      })
    );

    if (errors?.find((error) => error.field === "namespace")) {
      return;
    }

    const { namespace, namespaces } = getState().forms.workspace.data;
    const selectedClusters = getSelectedClustersList(getState());

    const guid = uuid();

    const children = selectedClusters.reduce((acc, cluster) => {
      return [
        ...acc,
        {
          alocMemory: "",
          alocCpu: "",
          clusterName: cluster.metadata.name,
          clusterUid: cluster.metadata.uid,
        },
      ];
    }, []);

    dispatch(
      workspaceFormActions.batchChange({
        module: WORKSPACES_FORM_MODULE,
        updates: {
          namespace: "",
          namespaces: [
            ...namespaces,
            {
              guid,
              namespaceName: namespace,
              isRegex: isValidRegex(namespace),
              alocMemory: "",
              alocCpu: "",
              children: isValidRegex(namespace) ? [] : children,
            },
          ],
        },
      })
    );
    dispatch(
      workspaceFormActions.validateField({
        module: WORKSPACES_FORM_MODULE,
        name: "namespaces",
      })
    );
  };
}

export function getNewSubjectObject(data) {
  return {
    type: data?.type || "User",
    name: data?.name || "",
    namespace: data?.namespace || "",
  };
}

export const clusterRoleBindingsFormActions = createFormActions({
  init: () => {
    const selectedIndex = clusterRoleBindingsModal.data?.index;
    let data;
    if (selectedIndex >= 0) {
      data =
        store.getState().forms[WORKSPACES_FORM_MODULE]?.data
          ?.clusterRoleBindings?.[selectedIndex];
    }

    return Promise.resolve({
      subjects: data?.subjects || [getNewSubjectObject()],
      roleName: data?.roleName || "",
    });
  },
  validator: clusterRoleBindingsValidator,
  submit: (data) => {
    const index = clusterRoleBindingsModal.data?.index;

    store.dispatch(
      createWorkspaceRoleBindingsForm.actions.onChangeRoleBindings({
        index,
        name: "clusterRoleBindings",
        data,
      })
    );
  },
});

export const namespaceRoleBindingsFormActions = createFormActions({
  init: () => {
    const selectedIndex = namespaceRoleBindingsModal.data?.index;
    let data;
    if (selectedIndex >= 0) {
      data =
        store.getState().forms[WORKSPACES_FORM_MODULE]?.data
          ?.namespaceRoleBindings?.[selectedIndex];
    }

    return Promise.resolve({
      namespace: data?.namespace || null,
      subjects: data?.subjects || [getNewSubjectObject()],
      roleType: data?.roleType || "Role",
      roleName: data?.roleName || "",
    });
  },
  validator: nsRoleBindingsValidator,
  submit: (data) => {
    const index = namespaceRoleBindingsModal.data?.index;

    store.dispatch(
      createWorkspaceRoleBindingsForm.actions.onChangeRoleBindings({
        index,
        name: "namespaceRoleBindings",
        data,
      })
    );
  },
});

export function deleteNamespace(guid) {
  return (dispatch, getState) => {
    const {
      namespaces,
      namespaceRoleBindings,
      containerImages,
      backupedNamespaces,
    } = getState().forms.workspace.data;
    const errors = getState().forms[WORKSPACES_FORM_MODULE]?.errors;
    const updatedErrors = errors.map((error) => {
      if (error.field.includes("namespaces")) {
        return { ...error, result: false };
      }
      return error;
    });

    const namespaceName = namespaces.find(
      (ns) => ns.guid === guid
    )?.namespaceName;
    store.dispatch(
      workspaceFormActions.onChange({
        module: WORKSPACES_FORM_MODULE,
        name: "namespaceRoleBindings",
        value: namespaceRoleBindings.filter(
          (roleBinding) => roleBinding.namespace !== namespaceName
        ),
      })
    );

    store.dispatch(
      workspaceFormActions.onChange({
        module: WORKSPACES_FORM_MODULE,
        name: "backupedNamespaces",
        value: backupedNamespaces.filter(
          (namespace) => namespace !== namespaceName
        ),
      })
    );

    store.dispatch(
      workspaceFormActions.onChange({
        module: WORKSPACES_FORM_MODULE,
        name: "containerImages",
        value: containerImages.filter(
          (image) => image.namespace !== namespaceName
        ),
      })
    );

    dispatch(
      workspaceFormActions.onChange({
        module: WORKSPACES_FORM_MODULE,
        name: "namespaces",
        value: namespaces.filter((ns) => ns.guid !== guid),
      })
    );
    dispatch(
      workspaceFormActions.updateErrors({
        module: WORKSPACES_FORM_MODULE,
        errors: updatedErrors,
      })
    );
  };
}

export function updateSettingsMenuSelection(newKey) {
  return function thunk(dispatch) {
    dispatch({
      type: "UPDATE_SETTINGS_MENU_SELECTION",
      newKey,
    });
  };
}
