import React from "react";

import createNamespace from "services/language";
import Route from "components/common/History/Route";
import { WORKSPACES } from "utils/constants/routes";

const Index = React.lazy(() => import("./index"));
const Create = React.lazy(() => import("./create"));
const Details = React.lazy(() => import("./details"));
const CreateClusterWorkspace = React.lazy(() =>
  import("./clusterworkspaces/create")
);
const ClusterWorkspaceDetails = React.lazy(() =>
  import("./clusterworkspaces/details")
);
const CreateNestedCluster = React.lazy(() =>
  import("components/common/NestedClusters/create")
);

const namespace = "workspaces";

export default createNamespace({
  namespace,
  Component: function Main() {
    return (
      <>
        <Route exact path={WORKSPACES.ROOT} component={Index} />
        <Route exact path={WORKSPACES.CREATE_WORKSPACE} component={Create} />
        <Route
          exact
          path={WORKSPACES.CREATE_CLUSTER_WORKSPACE}
          component={CreateClusterWorkspace}
        />
        <Route
          exact
          path={WORKSPACES.CREATE_NESTED_CLUSTER}
          component={CreateNestedCluster}
        />
        <Route
          exact
          path={WORKSPACES.WORKSPACE_DETAILS}
          component={(props) => {
            if (["overview", "usagecost"].includes(props.match?.params?.id)) {
              return null;
            }
            return <Details {...props} />;
          }}
        />
        <Route exact path={WORKSPACES.TAB_DETAILS} component={Details} />
        <Route
          exact
          path={WORKSPACES.CLUSTER_WORKSPACE_DETAILS}
          component={ClusterWorkspaceDetails}
        />
      </>
    );
  },
});
