import React from "react";
import styled from "styled-components";
import { Select as AntSelect, Tooltip } from "antd";

import { blue, orange } from "utils/constants/colors";
import { MaxTagPlaceholder } from "components/ui/Fields";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
//

const Select = styled(AntSelect)`
  .ant-select-arrow.ant-select-arrow-loading {
    color: ${blue};
  }

  .ant-select-selection-item > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-selection-item .ant-select-selection-item-remove {
    min-width: 10px;
  }
`;

const LabelOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .anticon.anticon-info-circle {
    margin-left: 11px;
  }
`;

// TODO: needs some improvement in case the value doesn't reflect the title and
// the title is a React component
function DEFAULT_FILTER_OPTION(searchValue, option) {
  const labelProps = option?.label?.props;
  const title =
    (typeof labelProps?.title === "string" && labelProps?.title) ||
    (typeof labelProps?.children === "string" && labelProps?.children) ||
    option?.value;

  if (!title) {
    return false;
  }

  if (typeof title === "object") {
    return option.value.toLowerCase()?.includes(searchValue.toLowerCase());
  }

  return title.toLowerCase().includes(searchValue.toLowerCase());
}

export default function SelectWithOptions(
  {
    options = [],
    children,
    filterOption = DEFAULT_FILTER_OPTION,
    showSearch = true,
    showTooltip = false,
    allowClear = false,
    value,
    mode,
    ...rest
  },
  ref
) {
  function renderOption(option, index) {
    if (option.isGroup) {
      return (
        <Select.OptGroup label={option.label} key={index}>
          {option.options.map(renderOption)}
        </Select.OptGroup>
      );
    }
    return (
      <Select.Option
        data-qa={`${rest["data-qa"]}-option`}
        data-qa-index={index}
        data-qa-value={option.value}
        key={option.value}
        value={option.value}
        disabled={option.disabled}
        label={<span>{option.label}</span>}
      >
        <LabelOption>
          {showTooltip ? (
            <Tooltip title={option.label} placement="right">
              <span>{option.label}</span>
            </Tooltip>
          ) : (
            <span>{option.label}</span>
          )}
          {option.description && (
            <Tooltip title={option.description} placement="right">
              {option.descriptionType === "warning" ? (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{ color: orange }}
                />
              ) : (
                <InfoCircleOutlined />
              )}
            </Tooltip>
          )}
        </LabelOption>
      </Select.Option>
    );
  }

  return (
    <Select
      ref={ref}
      {...(mode === "tags" && { maxTagCount: "responsive" })}
      maxTagPlaceholder={(values) => (
        <MaxTagPlaceholder omittedValues={values} />
      )}
      {...rest}
      optionLabelProp="label"
      showSearch={showSearch}
      filterOption={showSearch && filterOption}
      allowClear={allowClear && !!value}
      value={value}
      mode={mode}
    >
      {children ? children : (options || []).map(renderOption)}
    </Select>
  );
}
