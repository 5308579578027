import querystring from "query-string";

import { ClusterWorkspaceSchema } from "utils/schemas";
import ListActions from "modules/list/actions";
import createFormActions from "modules/form/actions";

import api from "services/api";
import history from "services/history";
import store from "services/store";

import { workspacesFilteredClustersFetcher } from "state/clusterworkspaces/services/create";

export const clusterWorkspaceListActions = new ListActions({
  schema: [ClusterWorkspaceSchema],
  fetchData() {
    //TODO update API
    return api.get(`v1/dashboard/workspaces`);
  },
});

function getQueryFormData(query) {
  const clusters =
    query?.clusters && !Array.isArray(query.clusters)
      ? [query.clusters]
      : query.clusters;

  return {
    clusters,
    workspace: query?.workspace || "",
  };
}

export const filtersFormActions = createFormActions({
  init: () => {
    const query = history.getQuery();
    const data = getQueryFormData(query);
    store.dispatch(workspacesFilteredClustersFetcher.fetch());

    return Promise.resolve({
      workspace: data?.workspace || "",
      clusters: data?.clusters || [],
    });
  },
  submit: (data) => {
    const queryString = querystring.stringify(data);
    history.replace(`?${queryString}`);
  },
});
