import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";

import store from "services/store";

import { getCluster } from "state/cluster/selectors/details";
import { getClusterByUid } from "state/cluster/actions/details";

import { WorkloadNestedClusterSchema } from "utils/schemas";

export const NESTED_CLUSTERS_MODULE = "workloads/nestedclusters";

export const nestedClusters = dataFetcher({
  selectors: ["clusters", "nestedclusters"],
  async fetchData(_) {
    let cluster = getCluster(store.getState());
    const clusterUid = store.getState().location.params.id;

    if (!cluster) {
      await store.dispatch(getClusterByUid(clusterUid));
      cluster = getCluster(store.getState());
    }

    return {
      items: cluster?.status?.nested?.nestedClusters || [],
    };
  },
});

export const nestedClustersListingActions = new ListActions({
  dataFetcher: nestedClusters,
  schema: [WorkloadNestedClusterSchema],
});
