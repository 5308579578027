import { createSelector } from "reselect";
import querystring from "query-string";

import { getEntity } from "utils/entities";
import { ClusterSchema } from "utils/schemas";
import { CLUSTERS } from "utils/constants/routes";
import { generatePath } from "react-router";
import { NESTED_CLUSTERS_LIST_MODULE } from "state/cluster/actions/list/nestedclusters";

export const getClusters = getEntity(
  (state) =>
    Object.keys(state.list.cluster?.items || {})
      .map((key) => state.list.cluster?.items[key])
      .flat(),
  [ClusterSchema]
);

export const isInitialClusterListEmpty = createSelector(
  (state) => state.list.cluster?.count,
  (state) => state.list.cluster?.isLoading,
  (state) => state.list.cluster?.isInitializing,
  (count, isLoading, isInitializing) => {
    return !isLoading && !isInitializing && !count;
  }
);

export const getClustersLink = createSelector(
  (state) => state.list.cluster?.query,
  (state) => state.auth.currentProjectId,
  (data, context) => {
    let rootPath = generatePath(CLUSTERS.ROOT, {
      tab: "overview",
    });
    if (context === "ADMIN_VIEW") {
      rootPath = `/admin${rootPath}`;
    }
    if (!data) {
      return rootPath;
    }
    const { deleted, sortField, sortOrder } = data;
    const query = querystring.stringify({
      search: "",
      deleted,
      sortField,
      sortOrder,
    });

    return `${rootPath}?${query}`;
  }
);

export const isInitialNestedClustersListEmpty = createSelector(
  (state) => state.list[NESTED_CLUSTERS_LIST_MODULE]?.count,
  (state) => state.list[NESTED_CLUSTERS_LIST_MODULE]?.isLoading,
  (state) => state.list[NESTED_CLUSTERS_LIST_MODULE]?.isInitializing,
  (count, isLoading, isInitializing) => {
    return !isLoading && !isInitializing && !count;
  }
);
