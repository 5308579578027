import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";
import { WorkloadNamespaceSchema } from "utils/schemas";

export const NAMESPACES_MODULE = "clusterWorkspaceNamespaces";

export const namespacesFetcher = dataFetcher({
  selectors: ["workload", "namespaces"],
  async fetchData(_, { namespaces = [], clusters = [] } = {}) {
    const params = store.getState().location.params;

    const data = await api.post(
      `v1/dashboard/workspaces/${params.id}/spectroclusters/workloads/namespace`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: data.clusters.reduce((accumulator, cluster) => {
        cluster.namespaces.forEach((ns) => {
          const exstingNsIndex = accumulator.findIndex(
            (currentNs) =>
              currentNs.metadata.entity.name === ns.metadata.entity.name
          );

          if (exstingNsIndex !== -1) {
            const existingNs = accumulator[exstingNsIndex];
            accumulator.splice(exstingNsIndex, 1, {
              ...existingNs,
              spec: {
                ...(existingNs.spec || {}),
                clusters: [...existingNs.spec.clusters, cluster],
              },
            });
          } else {
            accumulator.push({
              ...ns,
              spec: {
                ...(ns.spec || {}),
                clusters: [cluster],
              },
            });
          }
        });

        return accumulator.sort((ns1, ns2) =>
          ns1.metadata.entity.name.localeCompare(ns2.metadata.entity.name)
        );
      }, []),
    };
  },
});

export const namespacesListingActions = new ListActions({
  dataFetcher: namespacesFetcher,
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [WorkloadNamespaceSchema],
});
