import ListActions from "modules/list/actions";
import api from "services/api";
import store from "services/store";

import { getClusterWorkspaceEntity } from "state/clusterworkspaces/selectors/details";
import { parseWorkloadsResponse } from "state/clusterworkspaces/services/workloads";

import { JobSchema } from "utils/schemas";

export const JOBS_MODULE = "clusterWorkspaceJobs";

export const jobsListingActions = new ListActions({
  async fetchData({ namespaces = [], clusters = [] } = {}) {
    const uid = getClusterWorkspaceEntity(store.getState())?.metadata?.uid;
    const data = await api.post(
      `v1/dashboard/workspaces/${uid}/spectroclusters/workloads/job`,
      {
        filter: {
          namespaces,
          clusters,
        },
      }
    );

    return {
      items: parseWorkloadsResponse(data, "jobs"),
    };
  },
  initialQuery: () => ({
    namespaces: [],
    clusters: [],
  }),
  schema: [JobSchema],
});
